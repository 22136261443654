import {
  Container,
} from './styles';

import Header from '../../components/Header';
import MyAccountForm from '../../components/MyAccountForm';

import useEditMyAccount from './useEditMyAccount';

export default function EditMyAccount() {
  const { handleSubmit, meFormRef } = useEditMyAccount();

  return (
    <Container>
      <Header />

      <div className="main">
        <MyAccountForm edit onSubmit={handleSubmit} ref={meFormRef} />
      </div>
    </Container>
  );
};
