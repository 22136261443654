import { useImperativeHandle, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useErrors from '../../hooks/useErrors';

export default function useProductForm(onSubmit, ref) {
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [value, setValue] = useState(0);
  const [cost, setCost] = useState(0);
  const [quantity, setQuantity] = useState(0);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    setError,
    removeError,
    getErrorMessageByFieldName,
    errors,
  } = useErrors();

  const isFormValid = (name && value && errors.length === 0);

  useImperativeHandle(ref, () => ({
    setFieldsValues: (product) => {
      setName(product.name ?? '');
      setValue(product.value ?? '');
      setCost(product.cost ?? '');
      setQuantity(product.quantity ?? '');
    },

    resetFields: () => {
      setName('');
      setValue('');
      setCost('');
      setQuantity('');
    },
  }), []);

  function handleName(event) {
    setName(event.target.value);

    if (!event.target.value) {
      setError({ field: 'name', message: 'Nome é obrigatório' });
    } else {
      removeError('name');
    };
  };

  function handleValue(event) {
    setValue(event.target.value);

    if (!event.target.value) {
      setError({ field: 'value', message: 'Valor é obrigatório' });
    } else {
      removeError('value');
    };
  };

  function handleCost(event) {
    setCost(event.target.value);
  };

  function handleQuantity(event) {
    setQuantity(event.target.value);
  };

  function handleBack() {
    navigate(-1, { replace: true });
  };

  async function handleSubmit(event) {
    event.preventDefault();

    setIsSubmitting(true);

    await onSubmit({
      name,
      value,
      cost,
      quantity: Number(quantity),
    });

    navigate('/produtos', { replace: false });

    setIsSubmitting(false);
  }

  return {
    name,
    handleName,
    value,
    handleValue,
    cost,
    handleCost,
    quantity,
    handleQuantity,
    handleBack,
    getErrorMessageByFieldName,
    errors,
    isFormValid,
    isSubmitting,
    handleSubmit,
  };
};
