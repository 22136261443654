import { StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    padding: '64px',
    margin: 0,
    width: '100%',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  orderInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  orderNumber: {
    fontSize: 16,
    marginBottom: '4px',
    color: '#FFB500',
  },
  date: {
    fontSize: 10,
    color: '#FFB500',
  },
  clientInformations: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '16px',
  },
  h1: {
    color: '#FFB500',
    fontSize: 16,
    marginTop: '16px',
    marginBottom: '4px',
  },
  dataClient: {
    fontSize: 10,
    marginBottom: '2px',
    color: '#5c5c5c',

  },
  headerTable: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingLeft: '32px',
    paddingRight: '32px',
    marginBottom: '4px',
    borderRadius: '7px',
    backgroundColor: '#FFB500',
    color: '#ffffff',
    fontSize: 12,
  },
  productInformations: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: '8px 20px',
    width: '100%',
    marginBottom: '4px',
    color: '#FFB500',
    fontSize: 12,
  },
  totalOrderValue: {
    position: 'absolute',
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
  },
  titleTotalOrderValue: {
    fontSize: 16,
    color: '#FFB500',
  },
  totalOrderValuePrice: {
    marginBottom: '16px',
    fontSize: 18,
  },
  footer: {
    fontSize: 8,
    color: '#FFB500',
  },
  pageNumber: {
    marginTop: '16px',
    fontSize: 8,
    color: 'grey',
  },
});

export default styles;
