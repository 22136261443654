import styled, { css } from 'styled-components';

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 16px;
  margin-bottom: 16px;

  height: 52px;

  border: none;
  border-radius: 12px;

  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);

  font-size: 16px;
  font-weight: bold;

  color: #ffffff;
  background: ${({ theme }) => theme.colors.main.success};

  transition: 0.2s ease-in;

  &:hover {
    background: ${({ theme }) => theme.colors.greenLight};

    scale: 1.07;
  }

  &:active {
    background: ${({ theme }) => theme.colors.main.success};
  }

  &[disabled] {
    cursor: default !important;

    background: #ccc !important;
  }

  ${({ theme, danger }) => danger && css`
    background: ${theme.colors.danger.main};

    &:hover {
      background: ${theme.colors.danger.light};
    }

    &:active {
      background: ${theme.colors.danger.dark};
    }
  `}

  @media screen and (max-width: 767px) {
    background: ${({ theme }) => theme.colors.main.success};

    ${({ theme, dangerMobile }) => dangerMobile && css`
    background: ${theme.colors.danger.main};

    &:hover {
      background: ${theme.colors.danger.light};
    }

    &:active {
      background: ${theme.colors.danger.dark};
    }
  `}
  }
`;
