import ben from '../../assets/images/icons/ben.svg';
import clock from '../../assets/images/icons/clock.svg';

import {
  Container,
} from './styles';

import useRequets from './useRequests';

import Header from '../../components/Header';
import ItensList from '../../components/ItensList';

export default function Requests() {
  const {
    searchTerm,
    handleChangeSearchTerm,
    handleCloseDeleteModal,
    handleConfirmDeleteRequest,
    handleDeleteRequest,
    isDeleteModalVisible,
    isLoadingDelete,
    requestBeingDeleted,
  } = useRequets();

  return (
    <Container>
      <Header />

      <div className="main">
        <ItensList
          searchTerm={searchTerm}
          handleChangeSearchTerm={handleChangeSearchTerm}
          visible={isDeleteModalVisible}
          title={`Tem certeza que deseja remover o cadastro ”${requestBeingDeleted?.name}”?`}
          onCancel={handleCloseDeleteModal}
          onConfirm={handleConfirmDeleteRequest}
          openModal={handleDeleteRequest}
          isLoading={isLoadingDelete}
          routeApi="/requests"
          iconOne={clock}
          iconTwo={ben}
          pagination
          buttonLabel="Novo pedido"
          link="/novo-pedido"
          placeholder="Pesquise aqui"
          linkToEdit="/histórico-de-pedidos"
          danger
          confirmLabel="Deletar"
          modalChildren="Esta ação não poderá ser desfeita!"
          request
          withoutbutton
        />
      </div>
    </Container>
  );
};
