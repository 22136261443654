import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import { Link } from 'react-router-dom';

import {
  Form,
} from './styles';

import useForgotPasswordForm from './useForgotPasswordForm';

import Input from '../Input';
import Button from '../Button';

const ForgotPasswordForm = forwardRef(({ buttonLabel, onSubmit }, ref) => {
  const {
    email,
    handleEmail,
    handleSubmit,
    isFormValid,
    getErrorMessageByFieldName,
    isSubmitting,
  } = useForgotPasswordForm(onSubmit, ref);

  return (
    <Form onSubmit={handleSubmit}>
      <div>
        <span>Informe seu E-mail</span>

        <p>Insira seu email abaixo e enviaramos o passo a passo para recuperar sua senha!</p>

        <Input
          error={getErrorMessageByFieldName('email')}
          placeholder="Informe seu E-mail"
          type="email"
          value={email}
          onChange={handleEmail}
        />

        <p id="error">{getErrorMessageByFieldName('email')}</p>
      </div>

      <div>
        <Button
          type="submit"
          disabled={!isFormValid}
          isLoading={isSubmitting}
        >
          {buttonLabel}
        </Button>
      </div>

      <Link to="/cadastrar">Não possue uma conta?</Link>
    </Form>
  );
});

export default ForgotPasswordForm;

ForgotPasswordForm.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
