import {
  Container,
} from './styles';

import { useForgotPassword } from './useForgotPassword';

import Info from '../../components/Info';
import ForgotPasswordForm from '../../components/ForgotPasswordForm';

export default function ForgotPassword() {
  const {
    handleSubmit,
    forgotPasswordRef,
  } = useForgotPassword();

  return (
    <Container>
      <Info />

      <ForgotPasswordForm
        ref={forgotPasswordRef}
        buttonLabel="Confirmar"
        onSubmit={handleSubmit}
      />
    </Container>
  );
};
