import styled, { css } from 'styled-components';

export const Form = styled.form`
  .main {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }

  @media screen and (max-width: 1240px) {
    .main {
      flex-direction: column;

      .actions {
        margin-top: -16px;

        width: 100%;

        padding: 16px;

        div {
          input {
            margin-bottom: 8px;
          }

          p {
            margin-bottom: 8px;

            font-size: 14px;

            color: ${({ theme }) => theme.colors.danger.dark};
          }
        }

        button {
          width: 100%;
        }
      }
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  margin-top: 10%;

  & + & {
    margin-left: 32px;
  }

  img {
    ${({ discountId }) => discountId && css`
      margin-top: -244px;
    `}

    margin-bottom: 20px;
  }

  .body {
    display: flex;
    align-items: start;
    justify-content: start;
    flex-direction: column;

    max-width: 280px;
    width: 100%;

    #title-section {
      display: flex;
      flex-direction: column;
      align-items: start;

      #title {
        margin-bottom: -16px;

        font-size: 45px;

        color: ${({ theme }) => theme.colors.main.alert};
      }

      #title2 {
        font-size: 45px;

        color: ${({ theme }) => theme.colors.main.alert};
      }
    }

    #sub-title {
      text-align: center;

      margin-bottom: 16px;

      font-size: 19.5px;
    }

    section {
      display: flex;
      align-items: center;
      flex-direction: column;

      margin-bottom: 16px;

      width: 100%;

      h1 {
        font-size: 40px;
        font-weight: 100;

        width: 100%;
      }

      #price {
        font-size: 40px;
        font-weight: bold;
      }
    }

    p {
      margin-bottom: 32px;

      font-size: 15.5px;
      word-wrap: break-word;
      width: 100%;

      #red {
        color: ${({ theme }) => theme.colors.danger.dark};
      }
    }

    button {
      padding: 16px;

      width: 100%;
      height: 72px;

      border-radius: 12px;
      border: 0;

      font-size: 16px;

      color: ${({ theme }) => theme.colors.main.light};
      background: ${({ theme }) => theme.colors.main.greenBlack};
    }
  }

  @media screen and (max-width: 1240px) {
    margin-top: 40%;

    & + & {
      margin-left: 0;
    }

    img {
      margin-bottom: 0;

      width: 140px;
    }

    .body {
      padding: 16px;

      width: 100%;

      margin-top: -64px;

      max-width: none;
      width: 100%;

      button {
        height: auto;
      }

      p {
        margin-bottom: 0;
      }

      #title-section {
        #title {
          font-size: 34px;
        }

        #title2 {
          font-size: 34px;
        }
      }

      section {
        h1 {
          font-size: 24px;
        }

        #price {
          font-size: 30px;
        }
      }
    }
  }
`;

export const Payment = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;

  margin-left: 32px;

  width: 592px;

  p {
    margin-top: 8px;

    font-size: 14px;

    color: ${({ theme }) => theme.colors.danger.dark};
  }
`;
