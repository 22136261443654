import styled from 'styled-components';

export const ContainerMobile = styled.div`
  display: none;

  @media screen and (max-width: 1240px) {
    display: flex;
    flex-direction: column;

    .header-container {
      display: flex;
      justify-content: space-between;

      padding: 16px;
      width: 100%;

      background-color: ${({ theme }) => theme.colors.gray[900]};

      a {
        img {
          width: 150px;
        }
      }

      div {
        cursor: pointer;
      }
    }

    #links {
      position: absolute;
      z-index: 99;
      top: 66px;

      width: 100%;

      display: flex;
      align-items: center;
      flex-direction: column;

      .add-function {
        color: ${({ theme }) => theme.colors.main.black};
        background-color: ${({ theme }) => theme.colors.main.alertBold};

        transition: ease-in-out 0.2s;

        &:hover {
          color: ${({ theme }) => theme.colors.main.light};
          background-color: ${({ theme }) => theme.colors.main.black};
        }
      }

      a {
        padding: 16px;

        width: 100%;

        font-size: 16px;

        cursor: pointer;
        color: ${({ theme }) => theme.colors.gray[500]};
        background-color: ${({ theme }) => theme.colors.gray[800]};

        transition: ease-in-out 0.2s;

        &:hover {
          color: ${({ theme }) => theme.colors.main.alert};
          background-color: ${({ theme }) => theme.colors.gray.bold};
        }
      }
    }
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: start;

  width: 25%;
  height: 100%;

  position: fixed;
  z-index: 1;

  color: ${({ theme }) => theme.colors.main.light};
  background-color: ${({ theme }) => theme.colors.gray[900]};

  @media screen and (max-width: 1240px) {
    display: none;
  }
`;

export const ItemsArea = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;

  width: 100%;

  #header {
    padding: 0 15%;
    margin-top: 15%;

    width: 100%;
    word-wrap: break-word;

    img {
      margin-left: -12px;

      width: 70%;
    }

    p {
      margin-top: 16px;

      font-size: 18px;

      color: ${({ theme }) => theme.colors.main.alertBold};
    }
  }

  #links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-top: 32px;

    width: 100%;
    .add-function {
      color: ${({ theme }) => theme.colors.main.black};
      background-color: ${({ theme }) => theme.colors.main.alertBold};

      transition: ease-in-out 0.2s;

      &:hover {
        color: ${({ theme }) => theme.colors.main.light};
        background-color: ${({ theme }) => theme.colors.main.black};
      }
    }

    a {
      padding: 16px;
      padding-left: 15%;

      width: 100%;

      font-size: 18px;

      cursor: pointer;
      color: ${({ theme }) => theme.colors.gray[500]};
      background-color: ${({ theme }) => theme.colors.gray[800]};

      transition: ease-in-out 0.2s;

      &:hover {
        color: ${({ theme }) => theme.colors.main.alert};
        background-color: ${({ theme }) => theme.colors.gray.bold};
      }
    }
  }

  @media screen and (max-width: 767px) {
    position: absolute;

    margin: 0;
    padding: 0;

    #links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-top: 32px;

    width: 100%;
    .add-function {
      color: ${({ theme }) => theme.colors.main.black};
      background-color: ${({ theme }) => theme.colors.main.alertBold};

      transition: ease-in-out 0.2s;

      &:hover {
        color: ${({ theme }) => theme.colors.main.light};
        background-color: ${({ theme }) => theme.colors.main.black};
      }
    }

    a {
      padding: 16px;
      padding-left: 15%;

      width: 100%;

      font-size: 18px;

      cursor: pointer;
      color: ${({ theme }) => theme.colors.gray[500]};
      background-color: ${({ theme }) => theme.colors.gray[800]};

      transition: ease-in-out 0.2s;

      &:hover {
        color: ${({ theme }) => theme.colors.main.alert};
        background-color: ${({ theme }) => theme.colors.gray.bold};
      }
    }
  }
  }
`;
