import {
  Container,
} from './styles';

import useProducts from './useProducts';

import Header from '../../components/Header';

import ItensList from '../../components/ItensList';

export default function Products() {
  const {
    searchTerm,
    handleChangeSearchTerm,
    isDeleteModalVisible,
    productBeingDeleted,
    handleDeleteProduct,
    handleCloseDeleteModal,
    handleConfirmDeleteProduct,
    isLoadingDelete,
  } = useProducts();

  return (
    <Container>
      <Header />

      <div className="main">
        <ItensList
          searchTerm={searchTerm}
          handleChangeSearchTerm={handleChangeSearchTerm}
          buttonLabel="Novo produto"
          link="/novo-produto?products=true"
          placeholder="Pesquise aqui"
          productsInfo
          routeApi="/all-products"
          danger
          visible={isDeleteModalVisible}
          title={`Tem certeza que deseja remover o cadastro ”${productBeingDeleted?.name}”?`}
          confirmLabel="Deletar"
          onCancel={handleCloseDeleteModal}
          onConfirm={handleConfirmDeleteProduct}
          openModal={handleDeleteProduct}
          modalChildren="Esta ação não poderá ser desfeita!"
          isLoading={isLoadingDelete}
        />
      </div>
    </Container>
  );
};
