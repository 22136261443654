import { useImperativeHandle, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import useErrors from '../../hooks/useErrors';
import useQuery from '../../hooks/useQuery';

import showPassword from '../../assets/images/icons/show-password.svg';
import hidePassword from '../../assets/images/icons/hide-password.svg';

export default function useResetPasswordForm(onSubmit, ref) {
  const [searchParams] = useSearchParams();

  const { token } = Object.fromEntries([...searchParams]);
  const query = useQuery();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [inputOne, setInputOne] = useState('');
  const [inputTwo, setInputTwo] = useState('');
  const [inputThree, setInputThree] = useState('');
  const [inputFour, setInputFour] = useState('');
  const [inputFive, setInputFive] = useState('');
  const [inputSix, setInputSix] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [type, setTypeInput] = useState('password');
  const [icon, setIcon] = useState(hidePassword);

  const inputs = document.querySelectorAll('input');

  inputs?.forEach((input, index) => {
    input.addEventListener('input', (e) => {
      const inputLength = e.target.value.length;
      // eslint-disable-next-line radix
      const maxLength = parseInt(e.target.getAttribute('maxlength'));

      if (inputLength === maxLength) {
        if (index < inputs.length - 1) {
          inputs[index + 1].focus();
        }
      }
    });
  });

  const {
    setError,
    removeError,
    getErrorMessageByFieldName,
    errors,
  } = useErrors();

  const inputsValid = inputOne && inputTwo
  && inputThree && inputFour && inputFive && inputSix;

  const passValid = password.length >= 6;
  const confirmPassValid = confirmPassword.length >= 6;

  const isFormValid = (inputsValid && passValid && confirmPassValid && errors.length === 0);

  useImperativeHandle(ref, () => ({
    setFieldsValues: (adm) => {
      setPassword(adm.email ?? '');
      setConfirmPassword(adm.email ?? '');
    },

    resetFields: () => {
      setPassword('');
      setConfirmPassword('');
    },
  }), []);

  function handleToggle() {
    if (type === 'password') {
      setIcon(showPassword);
      setTypeInput('text');
    } else {
      setIcon(hidePassword);
      setTypeInput('password');
    };
  };

  function handlePassword(event) {
    setPassword(event.target.value);

    if (!event.target.value) {
      setError({ field: 'password', message: 'Esse campo é obrigatório' });
    } else {
      removeError('password');
    };
  };

  function handleConfirmPassword(event) {
    setConfirmPassword(event.target.value);

    if (!event.target.value) {
      setError({ field: 'confirm-password', message: 'Esse campo é obrigatório' });
    } else {
      removeError('confirm-password');
    };
  };

  function handleInputOne(event) {
    setInputOne(event.target.value);
  }

  function handleInputTwo(event) {
    setInputTwo(event.target.value);
  }

  function handleInputThree(event) {
    setInputThree(event.target.value);
  }

  function handleInputFour(event) {
    setInputFour(event.target.value);
  }

  function handleInputFive(event) {
    setInputFive(event.target.value);
  }

  function handleInputSix(event) {
    setInputSix(event.target.value);
  }

  async function handleSubmit(event) {
    try {
      event.preventDefault();

      setIsSubmitting(true);

      if (password !== confirmPassword) {
        return !isFormValid;
      };

      const securityToken = `${inputOne}${inputTwo}${inputThree}${inputFour}${inputFive}${inputSix}`;

      await onSubmit({
        token,
        password,
        securityToken,
      });

      setIsSubmitting(false);
    } catch (error) {
      console.log({ error });
    };
  };

  return {
    password,
    handlePassword,
    confirmPassword,
    handleConfirmPassword,
    inputOne,
    handleInputOne,
    inputTwo,
    handleInputTwo,
    inputThree,
    handleInputThree,
    inputFour,
    handleInputFour,
    inputFive,
    handleInputFive,
    inputSix,
    handleInputSix,
    handleSubmit,
    isSubmitting,
    getErrorMessageByFieldName,
    errors,
    isFormValid,
    handleToggle,
    type,
    icon,
    query,
  };
};
