import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import {
  ButtonContainer,
  Form,
  Container,
} from './styles';

import Button from '../Button';
import FormGroup from '../FormGroup';

import useMyAccountForm from './useMyAccountForm';

const MyAccountForm = forwardRef(({ edit, onSubmit }, ref) => {
  const {
    type,
    handleType,
    name,
    handleName,
    email,
    handleEmail,
    password,
    handlePassword,
    handleSubmit,
    handleBack,
    isFormValid,
    isSubmitting,
    getErrorMessageByFieldName,
  } = useMyAccountForm(edit, onSubmit, ref);

  return (
    <Form onSubmit={handleSubmit}>
      <ButtonContainer>
        <section>
          <Button
            danger
            onClick={handleBack}
          >
            Cancelar
          </Button>

          <Button
            type="submit"
            isLoading={isSubmitting}
            disabled={!isFormValid}
          >
            Confirmar
          </Button>
        </section>
      </ButtonContainer>

      <Container>
        <div>
          <div>
            <section id="left">
              <div>

                <FormGroup error={getErrorMessageByFieldName('type')}>
                  <span>CPF*</span>
                  <input
                    placeholder="Digite seu CPF"
                    type="text"
                    value={type}
                    onChange={handleType}
                    maxLength={14}
                  />
                </FormGroup>

              </div>
            </section>

            <section>
              <div>
                <FormGroup error={getErrorMessageByFieldName('name')}>
                  <span>Nome*</span>
                  <input
                    placeholder="Digite seu Nome"
                    type="text"
                    value={name}
                    onChange={handleName}
                  />
                </FormGroup>
              </div>
            </section>
          </div>

          <div>
            <section>
              <div>
                <span>E-mail</span>
                <input
                  id="email"
                  placeholder="Digite seu E-mail"
                  type="text"
                  value={email}
                  onChange={handleEmail}
                />
              </div>
            </section>
          </div>
        </div>

        <div>
          <section id="adress-more">
            <span>Senha</span>
            <input
              id="adress"
              placeholder="Digite sua senha"
              type="text"
              value={password}
              onChange={handlePassword}
            />
          </section>
        </div>
      </Container>

    </Form>
  );
});

export default MyAccountForm;

MyAccountForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  edit: PropTypes.bool,
};

MyAccountForm.defaultProps = {
  edit: false,
};
