import styled from 'styled-components';

export const Form = styled.form`
  max-width: 408px;
  width: 100%;

  margin-top: 16px;

  .input-wrapper-code {
    display: flex;

    margin-bottom: 16px;

    input {
      text-align: center;

      & + input {
        margin-left: 4px;
      }
    }

    input[type=number]::-webkit-inner-spin-button,
      input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
  }

  .password {
    .input-wrapper {
      position: relative;

      div {
        position: relative;

        img {
          position: absolute;
          right: 12px;
          top: 50%;

          transform: translateY(-50%);

          cursor: pointer;
        }
      }
    }
  }

  .inputs {
    margin-bottom: 16px;
  }

  div {
    span {
      color: ${({ theme }) => theme.colors.main.alertBold};
    }

    input {
      margin-bottom: 4px;
    }

    p {
      color: ${({ theme }) => theme.colors.danger.dark};
    }


    button {
      padding: 16px;
      margin-bottom: 8px;

      width: 100%;

      border-radius: 14px;
      border: none;

      font-size: 18px;

      color: ${({ theme }) => theme.colors.main.light};
      background: ${({ theme }) => theme.colors.main.success};
    }
  }
`;
