import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import iconCheck from '../../assets/images/icons/icon-check.svg';
import iconX from '../../assets/images/icons/icon-x.svg';

import {
  Form,
  SearchContainer,
  Container,
  InfoClient,
  MiddleArea,
  ProductsList,
  Footer,
  ButtonContainer,
} from './styles';

import handleDate from '../../utils/handleDate';

import useRequestForm from './useRequestForm';

import Button from '../Button';
import Input from '../Input';

// import Pagination from '../ItensList/components/Pagination';

const RequestForm = forwardRef(({ onSubmit, edit }, ref) => {
  const {
    searchTerm,
    handleChangeSearchTerm,
    products,
    filteredItens,
    quantity,
    handleQuantity,
    value,
    handleValue,
    cost,
    handleCost,
    more,
    handleMore,
    handleBack,
    handleSelectClient,
    handleRemoveSelectClient,
    clientSelected,
    visibleClientSelected,
    productSelectedId,
    productCalledSelected,
    handleSelectProduct,
    handleSubmit,
    currentItens,
    // pages,
    // handlePage,
    handleDeleteProductCalled,
    isFormValid,
    autoResize,
    handleProductSubmit,
  } = useRequestForm(onSubmit, edit, ref);

  const isVisibleTrue = edit || visibleClientSelected;

  return (
    <Form onSubmit={handleSubmit}>
      <SearchContainer>
        <Input
          placeholder="Busque seu cliente"
          value={searchTerm}
          onChange={handleChangeSearchTerm}
          disabled={edit}
        />

        {window.innerWidth > 1240 && (
          <>
            <Button danger onClick={handleBack}>
              Cancelar
            </Button>

            <Button
              type="submit"
              disabled={!isFormValid}
            >
              Confirmar
            </Button>
          </>
        )}
      </SearchContainer>

      <Container>
        {edit ? (
          <InfoClient>
            <div>
              <section id="header">
                <span>{clientSelected?.name}</span>
              </section>

              <div className="middle">
                <section>
                  <p>{clientSelected?.type}</p>
                  <p>{clientSelected?.phone}</p>
                  <p>{clientSelected?.email}</p>
                  <p>{clientSelected?.address}</p>
                </section>
              </div>
            </div>

          </InfoClient>
        ) : (
          <>
            {filteredItens.length === 0 || searchTerm === '' ? (
              <>
                {visibleClientSelected ? (
                  <></>
                ) : (
                  <InfoClient>
                    <span id="searchEmpty">Selecione um cliente</span>
                  </InfoClient>
                )}
              </>
            ) : (
              <>
                {filteredItens.map((item) => (
                  <InfoClient>
                    <span id="icon" onClick={() => handleSelectClient(item)}>
                      <img src={iconCheck} alt="Selecionar" width={26} />
                    </span>

                    <section id="header">
                      <span>{item?.name}</span>
                    </section>

                    <div className="middle">
                      <section>
                        <p>{item?.type}</p>
                        <p>{item?.phone}</p>
                        <p>{item?.email}</p>
                        <p>{item?.address}</p>
                      </section>
                    </div>
                  </InfoClient>
                ))}
              </>
            )}
          </>
        )}

        {visibleClientSelected && (
          <InfoClient>
            <div>
              <span id="icon" onClick={handleRemoveSelectClient}>
                <img src={iconX} alt="Remover" width={24} />
              </span>

              <section id="header">
                <span>{clientSelected?.name}</span>
              </section>

              <div className="middle">
                <section>
                  <p>{clientSelected?.type}</p>
                  <p>{clientSelected?.phone}</p>
                  <p>{clientSelected?.email}</p>
                  <p>{clientSelected?.address}</p>
                </section>
              </div>
            </div>

          </InfoClient>
        )}

        {isVisibleTrue && (
          <>
            <MiddleArea>
              <section id="products">
                {/* <div>
                  <div>
                    <span>Selecione um produto</span>

                    <button
                      type="button"
                      onClick={handleConfirmRequest}
                    >Selecionar produto
                    </button>
                  </div>

                  <div id="quantity-div">
                    <span>Unidade</span>

                    <input type="text" id="quantity" value={quantity} onChange={handleQuantity} />
                  </div>
                </div> */}

                <div className="selects">
                  <select
                    name="products"
                    id="products"
                    value={productSelectedId}
                    onChange={handleSelectProduct}
                  >
                    <option>Selecione um produto</option>
                    {products.map((item) => (
                      <>
                        <option key={item.id} value={item.id}>{item?.name}</option>
                      </>
                    ))}

                  </select>

                  <input type="text" id="quantity" value={quantity} onChange={handleQuantity} />
                </div>

                <button type="button" onClick={handleProductSubmit}>Adicionar</button>
              </section>

              {window.innerWidth > 1240 && (
                <>
                  <section>
                    <span>Valor</span>

                    <p id="light"><input type="text" value={new Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(Number(value))} onChange={handleValue} /></p>
                  </section>

                  <section id="cost">
                    <span>Custo</span>

                    <p id="light"><input type="text" value={new Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(Number(cost))} onChange={handleCost} /></p>
                  </section>
                </>
              )}

              {window.innerWidth < 1240 && (
                <div id="cost-value">
                  <section>
                    <span>Valor</span>

                    <p id="light"><input type="text" value={new Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(Number(value))} onChange={handleValue} /></p>
                  </section>

                  <section id="cost">
                    <span>Custo</span>

                    <p id="light"><input type="text" value={new Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(Number(cost))} onChange={handleCost} /></p>
                  </section>
                </div>
              )}
            </MiddleArea>

            {productCalledSelected.length !== 0 ? (
              <ProductsList>
                <span>Produtos listados</span>

                <table>
                  <thead>
                    <tr>
                      <th scope="col">Item</th>
                      <th scope="col">Quant</th>
                      <th scope="col">Valor</th>
                      <th scope="col">Custo</th>
                      <th scope="col">Realizado em</th>
                    </tr>
                  </thead>

                  <tbody>
                    {currentItens.map((item) => (
                      <>
                        <tr onClick={() => handleDeleteProductCalled(item)}>
                          <td>{item?.name}</td>
                          <td>{item?.quantity} uni</td>
                          <td>
                            {new Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(item?.value)}
                          </td>
                          <td>
                            {new Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(item?.cost)}
                          </td>
                          <td>{handleDate(item?.created_at)}</td>
                        </tr>
                      </>
                    ))}
                  </tbody>

                </table>

                {/* <div>
                  <Pagination pages={pages} handlePage={handlePage} />
                </div> */}
              </ProductsList>
            ) : (
              <></>
            )}

          </>
        )}

      </Container>

      <Footer>
        <div>
          <span>Mais informações</span>

          <textarea
            id="more-informations"
            placeholder="Digite mais informações sobre o pedido aqui."
            value={more}
            onChange={handleMore}
            onInput={autoResize}
          />
        </div>
      </Footer>

      {window.innerWidth < 1240 && (
        <ButtonContainer>
          <section>
            <Button dangerMobile onClick={handleBack}>
              Cancelar
            </Button>
          </section>

          <section>
            <Button
              type="submit"
              disabled={!isFormValid}
            >
              Confirmar
            </Button>
          </section>
        </ButtonContainer>
      )}
    </Form>
  );
});

export default RequestForm;

RequestForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  edit: PropTypes.bool,
};

RequestForm.defaultProps = {
  edit: false,
};
