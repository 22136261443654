import {
  Container,
} from './styles';

import { useResetPassword } from './useResetPassword';

import Info from '../../components/Info';
import ResetPasswordForm from '../../components/ResetPasswordForm';

export default function ResetPassword() {
  const {
    handleSubmit,
    forgotPasswordRef,
  } = useResetPassword();

  return (
    <Container>

      <Info />

      <ResetPasswordForm
        ref={forgotPasswordRef}
        buttonLabel="Confirmar"
        onSubmit={handleSubmit}
      />

    </Container>
  );
};
