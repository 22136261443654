import {
  Container,
} from './styles';

import useSingUp from './useSignUp';

import Info from '../../components/Info';
import SignUpForm from '../../components/SignUpForm';

export default function SignUp() {
  const {
    signUpFormRef,
    handleSubmit,
    isMovalVisible,
  } = useSingUp();

  return (
    <Container>
      <Info />

      <SignUpForm
        ref={signUpFormRef}
        buttonLabel="Confirmar"
        onSubmit={handleSubmit}
        visibleModal={isMovalVisible}
      />
    </Container>
  );
};
