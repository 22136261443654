import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { api } from '../../services/apiClient';

export default function useRequets() {
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState('');

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [requestBeingDeleted, setRequestBeingDeleted] = useState(null);

  function handleChangeSearchTerm(event) {
    setSearchTerm(event.target.value);
  };

  function handleDeleteRequest(record) {
    setRequestBeingDeleted(record);

    setIsDeleteModalVisible(true);
  };

  function handleCloseDeleteModal() {
    setIsDeleteModalVisible(false);
  };

  // eslint-disable-next-line consistent-return
  async function handleConfirmDeleteRequest() {
    try {
      setIsLoadingDelete(true);

      await api.delete(`/clients/${requestBeingDeleted.id}`);

      navigate(0);

      handleCloseDeleteModal();
    } catch {
      return null;
    } finally {
      setIsLoadingDelete(false);
    };
  }

  return {
    searchTerm,
    handleChangeSearchTerm,
    isDeleteModalVisible,
    requestBeingDeleted,
    handleDeleteRequest,
    handleCloseDeleteModal,
    handleConfirmDeleteRequest,
    isLoadingDelete,
  };
};
