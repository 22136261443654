import { Container } from './styles';

export default function HeaderTable() {
  return (
    <Container>
      <h1>Pedidos</h1>

      <div id="categories" />
    </Container>
  );
};
