/* eslint-disable consistent-return */
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../../contexts/AuthProvider/useAuth';

export function useForgotPassword() {
  const auth = useAuth();

  const navigate = useNavigate();

  const forgotPasswordRef = useRef(null);

  async function handleSubmit(user) {
    try {
      const { token } = await auth.sendEmail({ email: user.email });

      navigate('/');

      return {
        token,
      };
    } catch (error) {
      console.log(error);
    }
  };

  return {
    handleSubmit,
    forgotPasswordRef,
  };
};
