import { useAuth } from '../../contexts/AuthProvider/useAuth';

import toast from '../../utils/toast';

export default function useNewCollaborator() {
  const auth = useAuth();

  async function handleSubmit(client) {
    try {
      await auth.createCollaborator({
        type: client.type,
        name: client.name,
        office: client.office,
        phone: client.phone,
        email: client.email,
        address: client.address,
        password: client.password,
      });

      toast({
        type: 'success',
        text: 'Colaborador cadastrado com sucesso!',
      });
    } catch (error) {
      toast({
        type: 'danger',
        text: 'Ocorreu um erro ao cadastrar o contato!',
      });
    };
  };

  return {
    handleSubmit,
  };
};
