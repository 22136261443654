import { api } from '../../services/apiClient';

export function Authorization(token = null) {
  // eslint-disable-next-line no-multi-assign
  const tokenUser = api.defaults.headers.common.Authoriztion = `Bearer ${token}`;

  return {
    tokenUser,
  };
}

export function setUserLocalStorage({ token = null, user = null }) {
  localStorage.setItem('@Auth:token', JSON.stringify(token));
  localStorage.setItem('@Auth:user', JSON.stringify(user));
};

export function getUserLocalStorage() {
  const authenticated = localStorage.getItem('@Auth:user');

  if (!authenticated) {
    return null;
  };

  const user = JSON.parse(authenticated);

  return user ?? null;
}

export async function LoginRequest(email, password) {
  try {
    const request = await api.post('/login', { email, password });

    return request.data;
  } catch (error) {
    throw error;
  };
};

export async function LoginCollaborator(email, password, navigation) {
  try {
    const request = await api.post('/collaborator/login', {
      email,
      password,
    });

    navigation('/dashboard');

    return request.data;
  } catch {
    return null;
  }
};

export async function RegisterRequest(name, type, email, password) {
  try {
    const request = await api.post('/register', {
      name, type, email, password,
    });

    return request.data;
  } catch (error) {
    throw error;
  }
};

export async function ForgotPassword(email) {
  try {
    const request = await api.post('/forgot-password', {
      email,
    });

    return request.data;
  } catch {
    return null;
  }
};

export async function ResetPassword({
  token, email, password, securityToken,
}) {
  try {
    const request = await api.post(`/reset-password?token=${token}`, {
      email,
      password,
      securityToken,
    });

    return request.data;
  } catch (error) {
    throw error;
  }
};

export async function ConfirmAccount(token) {
  try {
    const request = await api.put(`/confirm-account?token=${token}`);

    return request.data;
  } catch {
    return null;
  }
};

export async function CreateProduct({
  name,
  value,
  cost,
  quantity,
}) {
  try {
    const request = await api.post('/create-product', {
      name,
      value,
      cost,
      quantity,
    });

    return request.data;
  } catch {
    return null;
  }
};

export async function CreateClient({
  type,
  name,
  phone,
  email,
  address,
  more,
}) {
  try {
    const response = await api.post('/create-client', {
      name,
      type,
      phone,
      email,
      address,
      more,
    });

    return response.data;
  } catch {
    return null;
  }
};

export async function UpdateClient({
  id,
  type,
  name,
  phone,
  email,
  address,
  more,
}) {
  try {
    const response = await api.put(`/client/${id}`, {
      name,
      type,
      phone,
      email,
      address,
      more,
    });

    return response.data;
  } catch {
    return null;
  }
};

export async function CreateCollaborator({
  type,
  name,
  office,
  phone,
  email,
  address,
  password,
}) {
  try {
    const response = await api.post('/adm/create-collaborator', {
      type,
      name,
      office,
      phone,
      email,
      address,
      password,
    });

    return response.data;
  } catch {
    return null;
  }
};

export async function CreateRequest({
  calledId,
  clientId,
  value,
  cost,
  more,
}) {
  try {
    const response = await api.post('/request', {
      called_id: calledId,
      client_id: clientId,
      value,
      cost,
      more,
    });

    return response.data;
  } catch {
    return null;
  }
};

export async function UpdateRequest({
  id,
  value,
  cost,
  more,
}) {
  try {
    const response = await api.put(`/request/${id}`, {
      value,
      cost,
      more,
    });

    return response.data;
  } catch {
    return null;
  }
}
