import PropTypes from 'prop-types';

import {
  Container,
  Overlay,
  Footer,
} from './styles';

import ReactPortal from '../ReactPortal';
import Button from '../Button';

import useAnimatedUnmounted from '../../hooks/useAnimatedUnmounted';

export default function Modal({
  danger,
  alert,
  visible,
  isLoading,
  title,
  children,
  cancelLabel,
  confirmLabel,
  onCancel,
  onConfirm,
  footer,
  width,
}) {
  const { shouldRender, animatedElementRef } = useAnimatedUnmounted(visible);

  function closeModal(event) {
    const modal = document.getElementById('modal');

    if (event.target === modal) {
      onCancel();
    }
  }

  if (!shouldRender) {
    return null;
  };

  return (
    <ReactPortal containerId="modal-root">
      <Overlay
        id="modal"
        isLeaving={!visible}
        ref={animatedElementRef}
        onClick={closeModal}
      >
        <Container
          danger={danger}
          isLeaving={!visible}
          width={width}
          alert={alert}
        >
          <h1>{title}</h1>

          <div className="modal-body">
            {children}
          </div>

          {footer ? (
            <Footer>
              <button
                type="button"
                className="cancel-button"
                onClick={onCancel}
                disabled={isLoading}
              >
                {cancelLabel}
              </button>

              <Button
                type="button"
                danger={danger}
                onClick={onConfirm}
                isLoading={isLoading}
              >
                {confirmLabel}
              </Button>
            </Footer>
          ) : (
            <></>
          )}
        </Container>
      </Overlay>
      ,
    </ReactPortal>
  );
};

Modal.propTypes = {
  danger: PropTypes.bool,
  alert: PropTypes.bool,
  visible: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  cancelLabel: PropTypes.string,
  confirmLabel: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  footer: PropTypes.bool,
  width: PropTypes.number,
};

Modal.defaultProps = {
  danger: false,
  alert: false,
  isLoading: false,
  cancelLabel: 'Cancelar',
  confirmLabel: 'Confirmar',
  footer: true,
  width: 450,
};
