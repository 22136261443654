import {
  Container,
} from './styles';

import useEditRecord from './useEditRecord';

import Header from '../../components/Header';
import RecordForm from '../../components/RecordForm';

export default function EditRecord() {
  const { clientFormRef, handleSubmit } = useEditRecord();

  return (
    <Container>

      <Header />

      <div className="main">
        <RecordForm
          onSubmit={handleSubmit}
          ref={clientFormRef}
          data
        />
      </div>
    </Container>
  );
};
