import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;

  position: fixed;
  z-index: 1;

  margin-bottom: 64px;

  height: 62px;

  color: ${({ theme }) => theme.colors.main.light};
  background-color: ${({ theme }) => theme.colors.main.black};

  @media screen and (max-width: 1240px) {
    padding: 16px;
  }
`;

export const ItemsArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  max-width: 1216px;
  width: 100%;

  #menu {
    display: flex;

    section {
      display: flex;

      img {
        margin-left: 32px;

        cursor: pointer;

        transition: 0.3s;

        &:hover {
          transform: scale(1.03);
        }
      }

      a {
        display: flex;

        font-size: 16px;

        color: ${({ theme }) => theme.colors.main.light};

        transition: ease-in-out 0.2s;

        &:hover {
          color: ${({ theme }) => theme.colors.main.alert};
        }
      }

      #link {
        margin-left: 32px;
      }

      #insta {
        margin-right: 9px;
      }
    }
  }

  @media screen and (max-width: 1240px) {
    #menu {
      section {
        img {
          margin-left: 0;
        }

        #link {
          margin-left: 0;
        }

        #insta {
          margin-right: 12px;
        }
      }

    }

  }
`;
