import styled from 'styled-components';

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;

  width: 100%;
  height: 100%;

  .buttons {
    #button-update {
      padding: 8px 16px;

      border-radius: 12px;

      color: ${({ theme }) => theme.colors.main.black};

      transition: ease-in-out 0.3s;

      cursor: pointer;

      &:hover {
        scale: 1.1;
      }
    }

    #button {
      padding: 8px 16px;

      border-radius: 12px;

      color: ${({ theme }) => theme.colors.danger.main};

      transition: ease-in-out 0.3s;

      cursor: pointer;

      &:hover {
        scale: 1.1;
      }

      & {
        margin-left: 8px;
      }
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;

  width: 100%;

  padding: 27px 43px;
  margin-bottom: 16px;

  border-radius: 12px;

  background: ${({ theme }) => theme.colors.main.light};
`;

export const InfoWrapper = styled.div`
  width: 100%;

  span {
    font-size: 20px;

    color: ${({ theme }) => theme.colors.main.alert};
  }

  section {
    img {
      margin-left: 8px;

      cursor: pointer;
    }
  }

  #header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;

    #tag {
      padding: 6px 14px;

      border-radius: 12px;

      font-size: 14px;

      color: ${({ theme }) => theme.colors.main.light};
      background-color: ${({ theme }) => theme.colors.main.alert};
    }
  }

  .middle {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    margin-top: 8px;

    section {
      p {
        font-size: 14px;
      }
    }
  }
`;
