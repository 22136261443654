import { useEffect, useState } from 'react';

import { api } from '../../../../services/apiClient';

export default function useUserData() {
  const [informations, setInformations] = useState();

  const [isLoading, setIsLoading] = useState(false);

  async function handleCreatePortal() {
    const response = await api.post('/create-portal');

    const { sessionId } = response.data;

    window.location.href = sessionId;
  }

  async function loadItens() {
    const storageUser = localStorage.getItem('@Auth:user');

    const user = JSON.parse(storageUser);

    setIsLoading(true);

    if (!user.typeUser) {
      const { data } = await api.get('/collaborator/detail');

      setInformations(data);

      setIsLoading(false);
    }

    if (user.typeUser === 'ADM-MASTER') {
      const { data } = await api.get('/me');

      setInformations(data);

      setIsLoading(false);
    };
  };

  useEffect(() => {
    loadItens();
  }, []);

  return {
    informations,
    isLoading,
    handleCreatePortal,
  };
};
