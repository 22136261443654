import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import {
  Form,
  ButtonContainer,
  Container,
} from './styles';

import useRecordForm from './useRecordForm';

import FormGroup from '../FormGroup';

import Button from '../Button';

const RecordForm = forwardRef(({ onSubmit, data }, ref) => {
  const {
    type,
    handleType,
    name,
    handleName,
    phone,
    handlePhone,
    email,
    handleEmail,
    address,
    handleAddress,
    more,
    handleMore,
    handleSubmit,
    typeSelected,
    handleCpfSelected,
    handleCnpjSelected,
    isSubmitting,
    getErrorMessageByFieldName,
    isFormValid,
    handleBack,
    autoResize,
  } = useRecordForm(onSubmit, data, ref);

  return (
    <Form onSubmit={handleSubmit}>
      <ButtonContainer>
        <section>
          <button
            id="cpf"
            type="button"
            onClick={handleCpfSelected}
            style={{
              background: typeSelected === 'cpf' && '#FFB400',
            }}
          >
            Pessoa Física
          </button>

          <button
            id="cnpj"
            type="button"
            onClick={handleCnpjSelected}
            style={{
              background: typeSelected === 'cnpj' && '#FFB400',
            }}
          >
            Pessoa Jurídica
          </button>
        </section>

        {window.innerWidth > 1240 && (
          <section>
            <Button
              danger
              onClick={handleBack}
            >
              Cancelar
            </Button>

            <Button
              type="submit"
              isLoading={isSubmitting}
              disabled={!isFormValid}
            >
              Confirmar
            </Button>
          </section>
        )}

      </ButtonContainer>

      <Container>
        <div>
          <div>
            <section id="left">
              <div>
                {typeSelected === 'cpf' ? (
                  <FormGroup error={getErrorMessageByFieldName('type')}>
                    <span id="cpf">CPF*</span>
                    <input
                      placeholder="Digite seu CPF"
                      type="text"
                      value={type}
                      onChange={handleType}
                      maxLength={14}
                    />
                  </FormGroup>
                ) : (
                  <>
                    <span>CNPJ*</span>
                    <input
                      placeholder="Digite seu CNPJ"
                      type="text"
                      value={type}
                      onChange={handleType}
                      maxLength={18}
                    />
                  </>
                )}
              </div>
            </section>

            <section id="name">
              <div>
                <FormGroup error={getErrorMessageByFieldName('name')}>
                  <span id="name-input">Nome*</span>
                  <input
                    placeholder="Digite seu Nome"
                    type="text"
                    value={name}
                    onChange={handleName}
                  />
                </FormGroup>
              </div>
            </section>
          </div>

          <div>
            <section id="left">
              <div>
                <span>Telefone</span>
                <input
                  placeholder="Digite seu Telefone"
                  type="text"
                  value={phone}
                  onChange={handlePhone}
                  maxLength={15}
                />
              </div>

            </section>

            <section>
              <div>
                <span>E-mail</span>
                <input
                  placeholder="Digite seu E-mail"
                  type="text"
                  value={email}
                  onChange={handleEmail}
                />
              </div>
            </section>
          </div>
        </div>

        <div>
          <section id="adress-more">
            <span>Endereço</span>
            <input
              id="adress"
              placeholder="Digite seu Endereço"
              type="text"
              value={address}
              onChange={handleAddress}
            />
          </section>

          <section id="adress-more">
            <span>Mais informações</span>
            <textarea
              id="more"
              placeholder="Digite mais informações sobre o cliente"
              type="text"
              value={more}
              onChange={handleMore}
              onInput={autoResize}
            />
          </section>
        </div>
      </Container>

      {window.innerWidth < 1240 && (
        <ButtonContainer>
          <section id="mobile">
            <Button
              dangerMobile
              onClick={handleBack}
            >
              Cancelar
            </Button>
          </section>

          <section id="mobile">
            <Button
              type="submit"
              isLoading={isSubmitting}
              disabled={!isFormValid}
            >
              Confirmar
            </Button>
          </section>
        </ButtonContainer>
      )}
    </Form>
  );
});

export default RecordForm;

RecordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  data: PropTypes.bool,
};

RecordForm.defaultProps = {
  data: false,
};
