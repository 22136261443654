import styled from 'styled-components';

export const Container = styled.div`
  margin-top: -32px;

  #close-icon {
    width: 100%;

    display: flex;
    justify-content: end;

    img {
      cursor: pointer;
    }
  }

  h1 {
    margin-bottom: 16px;

    color: ${({ theme }) => theme.colors.main.alert};
  }

  table{
    width: 100%;
    margin-bottom : .5em;
    border-collapse: collapse;
    table-layout: fixed;
    text-align: center;

    thead {
      color: ${({ theme }) => theme.colors.main.light};
      background: ${({ theme }) => theme.colors.main.alert};

    }

    td, th {
      padding: .7em;
      margin: 0;
      text-align: center;
    }

    & > div {
      margin-left: 864px;

      width: 100%;
    }
  }

  footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
