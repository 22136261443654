import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import { Link } from 'react-router-dom';

import {
  Form,
} from './styles';

import useSignInForm from './useSingInForm';

import Input from '../Input';
import Button from '../Button';

const SignInForm = forwardRef(({
  buttonLabel, onSubmit, collaborator,
}, ref) => {
  const {
    email,
    handleEmail,
    password,
    handlePassword,
    isSubmitting,
    handleSubmit,
    getErrorMessageByFieldName,
    isFormValid,
    type,
    icon,
    handleToggle,
  } = useSignInForm(onSubmit, ref);

  return (
    <Form onSubmit={handleSubmit}>
      <div className="inputs">
        <span>E-mail</span>

        <Input
          error={getErrorMessageByFieldName('email')}
          placeholder="E-mail"
          name="email"
          value={email}
          onChange={(event) => handleEmail(event)}
          disabled={isSubmitting}
        />

        <p>{getErrorMessageByFieldName('email')}</p>
      </div>

      <div className="inputs password">
        <span>Senha</span>

        <div className="input-wrapper">
          <div>
            <Input
              error={getErrorMessageByFieldName('password')}
              placeholder="Senha"
              type={type}
              name="password"
              value={password}
              onChange={handlePassword}
              disabled={isSubmitting}
              style={{
                boxShadow: 'none',
              }}
            />

            <img
              onClick={handleToggle}
              src={icon}
              alt="Mostrar senha"
              width={20}
            />
          </div>

          <p>{getErrorMessageByFieldName('password')}</p>
        </div>
      </div>

      <div>
        <Button
          type="submit"
          disabled={!isFormValid}
          isLoading={isSubmitting}
        >
          {buttonLabel}
        </Button>
      </div>

      {!collaborator && (
        <section id="links">
          <Link to="/esqueceu-a-senha">Esqueci minha senha!</Link>

          <Link to="/cadastrar">Criar conta!</Link>
        </section>
      )}
    </Form>
  );
});

SignInForm.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  collaborator: PropTypes.bool,
};

SignInForm.defaultProps = {
  collaborator: false,
};

export default SignInForm;
