import {
  Container,
} from './styles';

import useSingIn from './useSignIn';

import Info from '../../components/Info';
import SignInForm from '../../components/SignInForm';

export default function SignIn() {
  const {
    signInFormRef,
    handleSubmit,
  } = useSingIn();

  return (
    <Container>
      <Info />

      <SignInForm
        ref={signInFormRef}
        buttonLabel="Entrar"
        onSubmit={handleSubmit}
      />

    </Container>
  );
};
