import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin-left: 25%;
  padding: 64px;
  padding-bottom: 0;

  ${({ isColaborator }) => isColaborator && css`
    .main {
      #month-report {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;

        width: 100%;
        height: 180px;


        border-radius: 20px;

        #date {
          width: 100%;

          margin-bottom: 24px;

          border-radius: 20px;

          background: ${({ theme }) => theme.colors.main.light};

          h1 {
            color: ${({ theme }) => theme.colors.main.light};
          }

          p {
            color: ${({ theme }) => theme.colors.main.alert};
          }

          .months {
            display: flex;
            justify-content: space-between;

            padding: 24px;

            img {
              cursor: pointer;

              width: 24px;
              height: 24px;
            }

            h1 {
              color: ${({ theme }) => theme.colors.gray[900]};
            }
          }
        }

        #report {
          display: flex;
          align-items: center;
          justify-content: center;

          width: 100%;

          height: 78px;

          padding: 24px;

          border-radius: 20px;

          cursor: pointer;

          color: ${({ theme }) => theme.colors.main.light};
          background-color: ${({ theme }) => theme.colors.main.alert};

          #report-div {
            display: flex;
            align-items: center;

            img {
              margin-right: 8px;

              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  `}

  @media (min-width: 2560px) {
    .main {
      #header {
        margin-top: 5rem;
      }
    }
  }

  .main {
    #header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;

      section {
        display: flex;

        padding: 32px;

        border-radius: 20px;

        height: ${({ isColaborator }) => (isColaborator ? '272px' : '100%')};

        background: ${({ theme }) => theme.colors.main.light};
      }

      #graffic {
        display: flex;
        align-items: center;

        padding: 0;
        margin-bottom: 24px;

        width: 100%;
        height: 127px;

        border-radius: 14px;
      }

      #data {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        width: 100%;
        height: 60px;

        font-size: 22px;

        border-radius: 14px;

        background: ${({ theme }) => theme.colors.gray[900]};

        h1 {
          margin-bottom: 16px;
        }

        span {
          font-size: 18px;

          color:  ${({ theme }) => theme.colors.main.light};
        }

        p {
          margin-left: 4px;

          color:  ${({ theme }) => theme.colors.main.light};
          font-size: 14px;
        }

        & > div {
          display: flex;
          align-items: center;
        }
      }
    }

    #buttons-add-cost-and-revenue {
      display: flex;
      align-items: center;
      justify-content: space-between;

      margin-top: 24px;

      width: 100%;

      #right {
        margin-left: 24px;
      }

      section {
        width: 100%;

        button {
          width: 100%;
          height: 59px;

          border-radius: 14px;
          border: none;

          font-size: 18px;

          color: ${({ theme }) => theme.colors.main.light};
          background: ${({ theme }) => theme.colors.main.green};
        }

        #right-button {
          background: ${({ theme }) => theme.colors.danger.main};
        }
      }
    }

    #middle {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;

      margin-top: 24px;

      width: 100%;

      section {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;

        padding: 32px;

        width: 100%;
        height: 120px;

        border-radius: 14px;

        background: ${({ theme }) => theme.colors.main.light};

        & + section {
          margin-left: 24px;
        }

        h1 {
          font-size: 18px;

          color: ${({ theme }) => theme.colors.main.alert};
        }

        p {
          font-size: 15px;
        }

        #invoicing {
          color: ${({ theme }) => theme.colors.main.blue};
        }

        #receive {
          color: ${({ theme }) => theme.colors.main.success};
        }

        #expenses {
          color: ${({ theme }) => theme.colors.danger.main};
        }
      }
    }

    #footer {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;

      width: 100%;

      #block {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;

        width: 100%;
        height: 120px;

        border-radius: 14px;

        background: ${({ theme }) => theme.colors.main.light};

        & + section {
          margin-left: 24px;
        }

        h1 {
          margin-left: 32px;

          font-size: 18px;

          color: ${({ theme }) => theme.colors.main.alert};
        }

        p {
          margin-left: 32px;

          font-size: 15px;

        }
      }

      #month-report {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;

        width: 100%;
        height: 180px;

        border-radius: 14px;

        #date {
          width: 100%;

          margin-bottom: 16px;

          border-radius: 14px;

          background: ${({ theme }) => theme.colors.main.light};

          h1 {
            font-size: 18px;

            color: ${({ theme }) => theme.colors.gray[900]};
          }

          p {
            color: ${({ theme }) => theme.colors.main.alert};
          }

          .months {
            display: flex;
            justify-content: space-between;

            padding: 11.5px;

            img {
              cursor: pointer;

              width: 24px;
              height: 24px;
            }
          }
        }

        #report {
          display: flex;
          align-items: center;
          justify-content: center;

          width: 100%;

          padding: 11.5px;

          border-radius: 14px;

          font-size: 12px;

          cursor: pointer;

          color: ${({ theme }) => theme.colors.main.light};
          background-color: ${({ theme }) => theme.colors.main.alert};

          #report-div {
            display: flex;
            align-items: center;

            img {
              margin-right: 8px;

              width: 14px;
              height: 14px;
            }
          }
        }
      }
    }

    #report-area {
      display: flex;
      flex-direction: column;

      margin-bottom: 20px;
      padding: 24px;

      width: 100%;
      height: 100%;

      border-radius: 14px;

      font-size: 14px;

      background: ${({ theme }) => theme.colors.main.light};

      h1 {
        margin-bottom: 8px;

        font-size: 18px;

        color: ${({ theme }) => theme.colors.main.alert};
      }

      span {
        font-size: 16px;
      }

      #itens {
        margin-bottom: 8px;

        font-size: 18px;

        cursor: pointer;

        transition: 0.2s ease-in;

        &:hover {
          scale: 1.009;
        }
       }
    }

    #slides {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;

      margin-top: 24px;

      width: 100%;

      border-radius: 12px;

      background-color: ${({ theme }) => theme.colors.main.light};

      img {
        width: 100%;

        border-radius: 12px;
      }
    }

    ${({ isCenter }) => isCenter && css`
      margin-top: 240px;
    `}
  }

  @media screen and (max-width: 1240px) {
    padding: 16px;
    margin-left: 0;

    .main {
      #header {
        section {
          padding: 18px;
        }

        #graffic {
          margin-bottom: 8px;
        }

        #data {
          font-size: 16px;

          span {
            font-size: 12px;
          }

          p {
            font-size: 10px;
          }

          & > div {
            flex-direction: column;
          }
        }
      }

      #buttons-add-cost-and-revenue {
        margin-top: 8px;

        section {
          button {
            height: auto;

            padding: 8px;

            font-size: 14px;
          }
        }
      }

      #middle {
        flex-direction: column;

        margin-top: 8px;

        section {
          padding: 16px;

          height: 50px;

          & + section {
            margin-left: 0;

            margin-top: 8px;
          }

          h1 {
            font-size: 14px;
          }

          p {
            font-size: 12px;
          }
        }
      }

      #footer {
        flex-direction: column;

        margin-top: 8px;

        #block {
          height: 50px;

          & + section {
            margin-left: 0;
            margin-top: 8px;
          }

          h1 {
            margin-left: 16px;

            font-size: 14px;
          }

          p {
            margin-left: 16px;
          }
        }

        #month-report {
          height: auto;

          margin-bottom: 8px;

          #date {
            margin-bottom: 8px;

            h1 {
              font-size: 14px;
            }

            .months {
              padding: 8px 16px;

              img {
                width: 12px;
                height: auto;
              }
            }
          }

          #report {
            padding: 8px 16px;

            #report-div {
              img {
                width: 12px;
                height: auto;
              }

              h1 {
                font-size: 14px;
              }
            }
          }
        }
      }

      #report-area {
        padding: 16px;

        h1 {
          font-size: 14px;
        }

        span {
          font-size: 12px;
        }
      }
    }
  }
`;

export const Main = styled.div``;
