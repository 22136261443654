import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';

/* eslint-disable jsx-a11y/control-has-associated-label */
import {
  Container,
} from './styles';

export default function SearchForm({
  onChange,
  value,
  onKeyDown,
}) {
  return (
    <Container>
      <InputMask
        type="text"
        placeholder="Busque seu pedido #0001"
        onChange={onChange}
        value={value}
        onKeyDown={onKeyDown}
      />
    </Container>
  );
};

SearchForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  onKeyDown: PropTypes.func.isRequired,
};
