import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';
import { useAuth } from '../../contexts/AuthProvider/useAuth';

import { api } from '../../services/apiClient';

export function useConfirmAccount() {
  const auth = useAuth();
  const navigate = useNavigate();

  async function handleSubmit(user) {
    try {
      await auth.confirmAccount({ token: user.token });
    } catch (error) {
      console.log(error);
    }
  };

  async function loadData() {
    const response = await api.get('/me');

    console.log(response.data);

    const { status } = response?.data?.user;

    if (status) {
      navigate('/dashboard', { replace: false });
      toast.warning('Sua conta ja foi confirmada!');
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return {
    handleSubmit,
  };
};
