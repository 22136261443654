/* eslint-disable no-param-reassign */
/* eslint-disable no-constant-condition */
/* eslint-disable no-mixed-operators */
import { useImperativeHandle, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { formatPhone } from '../../utils/isPhoneValid';
import isTypeValid from '../../utils/isTypeValid';

import useErrors from '../../hooks/useErrors';

export default function useRecordForm(onSubmit, data, ref) {
  const { id } = useParams();
  const navigate = useNavigate();

  const [type, setType] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [more, setMore] = useState('');

  const [typeSelected, setTypeSelected] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    setError,
    removeError,
    getErrorMessageByFieldName,
    errors,
  } = useErrors();

  const isFormValid = (type && name && errors.length === 0);

  useImperativeHandle(ref, () => ({
    setFieldsValues: (client) => {
      setType(isTypeValid(client.type ?? ''));
      setName(client.name ?? '');
      setPhone(formatPhone(client.phone ?? ''));
      setEmail(client.email ?? '');
      setAddress(client.address ?? '');
      setMore(client.more ?? '');
      setTypeSelected(client.type.length === 14 ? 'cpf' : 'cnpj');
    },

    resetFields: () => {
      setType('');
      setName('');
      setPhone('');
      setEmail('');
      setAddress('');
      setMore('');
    },
  }), []);

  function handleType(event) {
    setType(isTypeValid(event.target.value));

    if (!event.target.value) {
      setError({ field: 'type', message: 'Este campo é obrigatório' });
    } else {
      removeError('type');
    };
  };

  function handleName(event) {
    setName(event.target.value);

    if (!event.target.value) {
      setError({ field: 'name', message: 'Nome é obrigatório' });
    } else {
      removeError('name');
    };
  };

  function handlePhone(event) {
    setPhone(formatPhone(event.target.value));
  };

  function handleEmail(event) {
    setEmail(event.target.value);
  };

  function handleAddress(event) {
    setAddress(event.target.value);
  };

  function handleMore(event) {
    setMore(event.target.value);
  };

  function handleCpfSelected() {
    setTypeSelected('cpf');

    setType('');
  };

  function handleCnpjSelected() {
    setTypeSelected('cnpj');

    setType('');
  };

  function handleBack() {
    navigate(-1, { replace: true });
  };

  function autoResize() {
    const textarea = document.getElementById('more');
    textarea.style.height = 'auto'; // Define a altura como automática para ajustar o conteúdo
    textarea.style.height = `${textarea.scrollHeight}px`; // Ajusta a altura conforme necessário
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsSubmitting(true);

    if (!data) {
      await onSubmit({
        type,
        name,
        phone,
        email,
        address,
        more,
      });
    } else {
      await onSubmit({
        id,
        type,
        name,
        phone,
        email,
        address,
        more,
      });
    };

    navigate('/cadastros', { replace: false });

    setIsSubmitting(false);
  };

  return {
    type,
    handleType,
    name,
    handleName,
    phone,
    handlePhone,
    email,
    handleEmail,
    address,
    handleAddress,
    more,
    handleMore,
    handleSubmit,
    typeSelected,
    handleCpfSelected,
    handleCnpjSelected,
    isSubmitting,
    getErrorMessageByFieldName,
    isFormValid,
    handleBack,
    autoResize,
  };
};
