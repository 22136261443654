import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';
import { api } from '../../services/apiClient';

export default function useRecords() {
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState('');

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [recordBeingDeleted, setRecordBeingDeleted] = useState(null);

  function handleChangeSearchTerm(event) {
    setSearchTerm(event.target.value);
  };

  function handleDeleteRecord(record) {
    setRecordBeingDeleted(record);

    setIsDeleteModalVisible(true);
  };

  function handleCloseDeleteModal() {
    setIsDeleteModalVisible(false);
  };

  // eslint-disable-next-line consistent-return
  async function handleConfirmDeleteContact() {
    try {
      setIsLoadingDelete(true);

      await api.delete(`/clients/${recordBeingDeleted.id}`);

      navigate(0);

      toast.success('Cadastro deletado com sucesso!');

      handleCloseDeleteModal();
    } catch {
      return null;
    } finally {
      setIsLoadingDelete(false);
    };
  }

  return {
    searchTerm,
    handleChangeSearchTerm,
    isDeleteModalVisible,
    recordBeingDeleted,
    handleDeleteRecord,
    handleCloseDeleteModal,
    handleConfirmDeleteContact,
    isLoadingDelete,
  };
};
