import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { api } from '../../services/apiClient';

import useSafeAsyncAction from '../../hooks/useSafeAsyncAction';
import toast from '../../utils/toast';

export default function useEditMyAccount() {
  const navigate = useNavigate();

  const meFormRef = useRef();

  const safeAsyncAction = useSafeAsyncAction();

  const storageUser = localStorage.getItem('@Auth:user');

  const user = JSON.parse(storageUser);

  useEffect(() => {
    async function loadMe() {
      try {
        if (!user.typeUser) {
          const response = await api.get('/collaborator/detail');

          safeAsyncAction(() => {
            meFormRef.current.setFieldsValues(response.data);
          });
        }

        if (user.typeUser === 'ADM-MASTER') {
          const response = await api.get('/me');

          safeAsyncAction(() => {
            meFormRef.current.setFieldsValues(response.data);
          });
        };
      } catch {
        safeAsyncAction(() => {
          navigate(-1, { replace: true });
        });
      }
    };

    loadMe();
  }, [navigate, safeAsyncAction]);

  async function handleSubmit(me) {
    try {
      if (!user.typeUser) {
        await api.put(`/collaborator/${me.id}`, {
          name: me.name,
          email: me.email,
          type: me.type,
          password: me.password,
        });
      };

      await api.put('/edit-account', {
        name: me.name,
        email: me.email,
        type: me.type,
        password: me.password,
      });

      navigate(-1, { replace: true });

      toast({
        type: 'success',
        text: 'Conta editada com sucesso!',
      });
    } catch {
      toast({
        type: 'danger',
        text: 'Ocorreu um erro ao editar a conta!',
      });
    }
  };

  return {
    handleSubmit,
    meFormRef,
  };
};
