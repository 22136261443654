import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;

  margin-top: 32px;
  margin-bottom: 32px;

  max-width: 1216px;
  width: 100%;

  button {
    display: flex;
      align-items: center;
      justify-content: center;

      padding: 0 16px;
      margin-bottom: 16px;

      height: 52px;

      border: none;
      border-radius: 12px;

      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);

      font-size: 16px;
      font-weight: bold;

      color: #ffffff;
      background: ${({ theme }) => theme.colors.main.success};

      transition: background 0.2s ease-in;

      &:hover {
        background: ${({ theme }) => theme.colors.greenLight};
      }

      &:active {
        background: ${({ theme }) => theme.colors.main.success};
      }

      &[disabled] {
        cursor: default !important;

        background: #ccc !important;
      }

      ${({ theme, danger }) => danger && css`
        background: ${theme.colors.danger.main};

        &:hover {
          background: ${theme.colors.danger.light};
        }

        &:active {
          background: ${theme.colors.danger.dark};
        }
      `}

    margin-left: 16px;

    height: 24px;


    background: ${({ theme }) => theme.colors.main.light};
  }

  section {
    display: flex;

    button {
      height: 24px;

      background: ${({ theme }) => theme.colors.gray[200]};
    }
  }
`;
