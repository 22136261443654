/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';

import iconX from '../../assets/images/icons/icon-x.svg';

import {
  Container,
} from './styles';

import useHistoricModal from './useHistoricModal';

import Pagination from '../ItensList/components/Pagination';

export default function HistoricModal({ handleClose }) {
  const {
    pages,
    handlePage,
    currentItems,
    currentPage,
    setCurrentPage,
  } = useHistoricModal();

  return (
    <Container>
      <section id="close-icon">
        <img
          src={iconX}
          alt=""
          width={24}
          id="close"
          onClick={handleClose}
        />
      </section>

      <h1>Histórico de usuários</h1>

      <div className="table">
        <table>
          <thead>
            <tr>
              <th scope="col">Usuário</th>
              <th scope="col">Data</th>
              <th scope="col">Login</th>
            </tr>
          </thead>

          <tbody>
            {currentItems.map((item, index) => (
              <>
                <tr key={item.id}>
                  <td data-label="name">{item?.username}</td>
                  <td data-label="date">{item?.date}</td>
                  <td data-label="hour" id="login">{item?.hours}</td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
      </div>

      <footer>
        <Pagination
          pages={pages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          handlePage={handlePage}
        />
      </footer>
    </Container>
  );
};

HistoricModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
};
