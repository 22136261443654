import PropTypes from 'prop-types';

import {
  Container,
  Overlay,
} from './styles';

import ReactPortal from '../ReactPortal';

import useAnimatedUnmounted from '../../hooks/useAnimatedUnmounted';

export default function InfoModal({
  visible,
  children,
}) {
  const { shouldRender, animatedElementRef } = useAnimatedUnmounted(visible);

  if (!shouldRender) {
    return null;
  };

  return (
    <ReactPortal containerId="info-modal-root">
      <Overlay
        id="modal"
        isLeaving={!visible}
        ref={animatedElementRef}
      >
        <Container
          isLeaving={!visible}
        >
          <div className="modal-body">
            {children}
          </div>
        </Container>
      </Overlay>
    </ReactPortal>
  );
};

InfoModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};
