import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;

  width: 100%;
`;

export const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;

  width: 100%;

  input {
    margin: 0;

    width: 100%;

    border-radius: 12px;
  }

  button {
    margin-left: 16px;

    padding: 0 32px;

    border-radius: 12px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;

  width: 100%;

  @media screen and (max-width: 1240px) {
    width: 100%;
    justify-content: space-between;


    section {
      width: 100%;

      & + section {
        margin-left: 16px;
      }

      button {
        width: 100%;
        margin-left: 0;

        margin-bottom: 16px;

        height: 48px;
      }
    }
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  margin-top: 16px;
`;

export const InfoClient = styled.div`
  display: flex;
  flex-direction: column;

  padding: 40px 32px;
  margin-bottom: 8px;

  width: 100%;

  overflow-wrap: break-word;

  border-radius: 12px;

  background: ${({ theme }) => theme.colors.main.light};

  span {
    font-size: 24px;

    color: ${({ theme }) => theme.colors.main.alert};
  }

  section {
    margin-top: 16px;

    img {
      margin-left: 8px;

      cursor: pointer;
    }
  }

  #icon {
    display: flex;
    justify-content: flex-end;

    width: 100%;

    img {
      cursor: pointer;
    }
  }

  #searchEmpty {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 1240px) {
    padding: 24px 19px;
    margin-bottom: 0;

    span {
      font-size: 16px;

      img {
        width: 20px;
      }
    }

    section {
      margin-top: 0;
    }

    .middle {
      section {
        p {
          font-size: 12px;
        }
      }
    }
  }
`;

export const MiddleArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-top: 8px;

  width: 100%;

  #products {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;

    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      #quantity-div {
        margin-left: 16px;
        width: 100px;
      }

      div {
        display: flex;
        flex-direction: column;

        width: 100%;

        #quantity {
          margin-top: 16px;

          text-align: center;

          border: 1px solid;

          width: 100px;
          height: 48px;

          border-color: ${({ theme }) => theme.colors.main.alert};
        }

        button {
          margin-top: 16px;

          padding: 16px;
          width: 100%;

          border: none;
          border-radius: 12px;

          color: ${({ theme }) => theme.colors.main.light};
          background: ${({ theme }) => theme.colors.main.alert};
        }
      }
    }

    button {
      padding: 4px;
      margin-top: 16px;

      width: 100%;

      border: none;
      border-radius: 12px;
    }
  }

  section {
    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: 40px 32px 40px 32px;

    width: 55%;

    border-radius: 12px;

    background: ${({ theme }) => theme.colors.main.light};

    & + section {
      margin-left: 16px;
    }

    span {
      margin-bottom: 8px;

      font-size: 24px;

      color: ${({ theme }) => theme.colors.main.alert};
    }

    input {
      width: 100%;
      height: 32px;

      border-radius: 12px;
      border: none;
      border: 2px solid #fff;

      outline: none;

      font-size: 16px;

      background: #fff;

      appearance: none;

      &::placeholder {
        color: ${({ theme }) => theme.colors.gray[200]};
      }
    }

    .selects {
      display: flex;
      flex-direction: row;

      margin-bottom: 8px;

      & > select + select {
        margin-left: 8px;
      }

      select {
        width: 100%;

        border: none;

        outline: none;

        cursor: pointer;
      }

      #quantity {
        padding: 8px;

        text-align: center;

        margin-left: 16px;

        border: 1px solid;

        width: 50px;
      }
    }
  }

  #cost {
    color: ${({ theme }) => theme.colors.danger.dark};

    input {
      color: ${({ theme }) => theme.colors.danger.dark};
    }
  }

  @media screen and (max-width: 1240px) {
    flex-direction: column;

    width: 100%;

    section {
      padding: 24px 19px;

      span {
        margin-bottom: 0;
      }

      & + section {
        margin-left: 8px;
      }
    }

    #products {
      div {
        div {
          span {
            font-size: 16px;
          }

          #quantity {
            height: 29px;
          }

          button {
            padding: 6px;
          }
        }
      }
    }

    #cost-value {
      display: flex;

      margin-top: 8px;

      section {
        span {
          font-size: 16px;
        }

        p {
          input {
            font-size: 12px;
          }
        }
      }
    }
  }
`;

export const ProductsList = styled.div`
  display: flex;
  flex-direction: column;

  padding: 40px 64px 40px 64px;
  margin-top: 16px;

  width: 100%;

  border-radius: 12px;

  background: ${({ theme }) => theme.colors.main.light};

  span {
    margin-bottom: 16px;

    font-size: 24px;

    color: ${({ theme }) => theme.colors.main.alert};
  }

  table{
    width: 100%;
    margin-bottom : .5em;
    border-collapse: collapse;
    table-layout: fixed;
    text-align: center;

    thead {
      color: ${({ theme }) => theme.colors.main.light};
      background: ${({ theme }) => theme.colors.main.alert};
    }

    td, th {
      padding: .7em;
      margin: 0;
      text-align: center;
    }

    tbody {
      tr {
        cursor: pointer;
      }
    }

    & > div {
      margin-left: 864px;

      width: 100%;
    }
  }

  @media screen and (max-width: 1240px) {
    padding: 24px 19px;
    margin-top: 8px;

    span {
      margin-bottom: 8px;

      font-size: 16px;
    }

    table {
      thead {
        font-size: 10px;
      }

      tbody {
        font-size: 8px;
      }
    }
  }
`;

export const Footer = styled.div`
  padding: 40px 64px 40px 64px;
  margin-top: 16px;
  margin-bottom: 16px;

  border-radius: 12px;

  width: 100%;

  background: ${({ theme }) => theme.colors.main.light};

  div {
    display: flex;
    flex-direction: column;

    height: auto;

    span {
      font-size: 24px;

      color: ${({ theme }) => theme.colors.main.alert};
    }

    textarea {
      margin-top: 8px;

      width: 100%;
      height: 100%;
      resize: none; /* Impede o redimensionamento do textarea pelo usuário */
      border: none; /* Remove a borda padrão do textarea */
      outline: none; /* Remove o contorno de foco */
      padding: 0; /* Remove o espaçamento interno padrão */
      font-family: inherit; /* Usa a fonte da div pai */
      line-height: inherit; /* Usa a altura de linha da div pai */
      overflow-y: auto; /* Adiciona uma barra de rolagem vertical se necessário */

      font-size: 16px;

      &::placeholder {
        color: ${({ theme }) => theme.colors.gray[200]};
      }
    }
  }

  @media screen and (max-width: 1240px) {
    padding: 24px 19px;
    margin-top: 8px;

    div {
      span {
        font-size: 16px;
      }

      textarea {
        &::placeholder {
          font-size: 12px;
        }
      }
    }
  }
`;
