import PropTypes from 'prop-types';

import {
  Container,
} from './styles';

export default function ClientInformations({
  name,
  type,
  phone,
  email,
  address,
}) {
  return (
    <Container>
      <h1>{name}</h1>

      <section>
        <p>{type}</p>
        <p>{phone}</p>
        <p>{email}</p>
        <p>{address}</p>
      </section>
    </Container>
  );
};

ClientInformations.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
  address: PropTypes.string,
};

ClientInformations.defaultProps = {
  name: '',
  type: '',
  phone: '',
  email: '',
  address: '',
};
