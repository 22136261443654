import styled from 'styled-components';

export const Form = styled.form`
  max-width: 408px;
  width: 100%;


  .inputs {
    margin-bottom: 16px;

    .input-wrapper {
      position: relative;

      div {
        position: relative;

        img {
          position: absolute;
          right: 12px;
          top: 50%;

          transform: translateY(-50%);

          cursor: pointer;
        }
      }
    }
  }

  div {
    span {

      color: ${({ theme }) => theme.colors.main.alertBold};

      img {
        cursor: pointer;
      }
    }

    input {
      margin-bottom: 4px;
    }

    p {
      color: ${({ theme }) => theme.colors.danger.dark};
    }

    button {
      padding: 16px;
      margin-bottom: 8px;

      width: 100%;

      border-radius: 14px;
      border: none;

      font-size: 18px;

      color: ${({ theme }) => theme.colors.main.light};
      background: ${({ theme }) => theme.colors.main.success};
    }
  }

  a {
    text-decoration: none;

    color: ${({ theme }) => theme.colors.gray[200]};
  }
`;
