/* eslint-disable eqeqeq */
/* eslint-disable quote-props */
import { Link } from 'react-router-dom';
import editPen from '../../../../assets/images/icons/edit-pen.svg';

import {
  Body,
  Container,
  InfoWrapper,
} from './styles';

import useUserData from './useUserData';

import Loader from '../../../../components/Loader';

export default function UserData() {
  const {
    informations: data,
    isLoading,
    handleCreatePortal,
  } = useUserData();

  const tag = {
    'ADM-MASTER': '#2C73C2',
    'ADM': '#FFB400',
    'Colaborador': '#373737',
  };

  return (
    <Body>
      <Loader isLoading={isLoading} />

      <Container>
        <InfoWrapper id="primary">
          <section id="header">
            <span>{data?.user?.name || data?.name.toUpperCase()}</span>
            <div>
              <span
                id="tag"
                style={{
                  background: tag[data?.typeUser || data?.office],
                }}
              >{data?.typeUser === 'ADM-MASTER' && 'CEO' || data?.office}
              </span>
            </div>
          </section>

          <div className="middle">
            <section>
              <p>{data?.user?.type || data?.type}</p>
              <p>{data?.user?.email || data?.email}</p>
            </section>

            {data?.typeUser === 'ADM-MASTER' && (
              <section>
                <Link to={`/minha-conta/${data?.user?.id || data?.id}?myaccount=true`}>
                  <img src={editPen} alt="" width={16} />
                </Link>
              </section>
            )}

            {data?.office === 'ADM' && (
              <section>
                <Link to={`/minha-conta/${data?.user?.id || data?.id}?myaccount=true`}>
                  <img src={editPen} alt="" width={16} />
                </Link>
              </section>
            )}
          </div>

        </InfoWrapper>
      </Container>

      {data?.typeUser === 'ADM-MASTER' && (
        <div className="buttons">
          <span
            id="button-update"
          >
            Atualizar plano de assinatura
          </span>
          <span
            id="button"
            onClick={handleCreatePortal}
          >
            Cancelar plano de assinatura
          </span>
        </div>
      )}
    </Body>
  );
};
