export default function handleDate(date) {
  const dateFomat = new Date(date);

  const year = dateFomat.getFullYear();
  const day = dateFomat.getDate();
  const month = dateFomat.getMonth() + 1;

  const dateDay = day < 10 ? `0${day}` : day;
  const dateMonth = month < 10 ? `0${month}` : month;

  return `${dateDay}/${dateMonth}/${year}`;
};
