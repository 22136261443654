import styled, { css } from 'styled-components';

export const Form = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;

  z-index: 0;

  width: 100%;

  input {
    width: 100%;

    border-radius: 12px;
  }

  ${({ twoButtons }) => twoButtons && css`
    input {
      width: 864px;
    }
  `}

  @media screen and (max-width: 767px) {
    input {
      font-size: 12px;
    }
  }
`;

export const ButtonContainer = styled.div`
  margin-bottom: 8px;

  width: 100%;

  #search {
    display: flex;
    align-items: center;
    justify-content: center;

    input {
      margin: 0;
      margin-right: 16px;

      height: 40px;
    }
  }

  ${({ withoutbutton }) => withoutbutton && css`
    #search {
      input {
        margin-right: 0;
      }
    }
  `}

  button {
    width: 160px;
    height: 40px;

    margin: 0;

    border-radius: 12px;

    background: ${({ theme, twoButtons }) => (twoButtons ? '' : theme.colors.main.alert)};
  }

  ${({ twoButtons }) => twoButtons && css`
    display: flex;

    button {
      margin-left: 8px;
    }
  `}

  @media screen and (max-width: 767px) {
    margin-bottom: 0;

    button {
      display: inline-block;

      max-width: 130px;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      box-sizing: border-box;
    }
  }
`;
