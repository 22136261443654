/* eslint-disable react/no-unknown-property */
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import {
  ButtonContainer,
  Form,
} from './styles';

import Input from '../../../Input';
import Button from '../../../Button';

export default function SearchForm({
  buttonLabel,
  link,
  twoButtons,
  placeholder,
  value,
  onChange,
  withoutbutton,
}) {
  return (
    <Form twoButtons={twoButtons}>
      <ButtonContainer twoButtons={twoButtons} withoutbutton={withoutbutton}>
        { twoButtons ? (
          <>
            <Button danger>
              Cancelar
            </Button>

            <Button>
              Confirmar
            </Button>
          </>
        ) : (
          <div id="search">
            <Input
              placeholder={placeholder}
              value={value}
              onChange={onChange}
            />

            {withoutbutton ? (
              <></>
            ) : (
              <Link to={link}>
                <Button>
                  {buttonLabel}
                </Button>
              </Link>
            )}
          </div>
        )}
      </ButtonContainer>
    </Form>
  );
}

SearchForm.propTypes = {
  buttonLabel: PropTypes.string,
  link: PropTypes.string,
  twoButtons: PropTypes.bool,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  withoutbutton: PropTypes.bool,
};

SearchForm.defaultProps = {
  buttonLabel: '',
  link: '',
  twoButtons: false,
  withoutbutton: false,
};
