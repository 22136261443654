import {
  Container,
} from './styles';

import useNewRequest from './useNewRequest';

import Header from '../../components/Header';
import RequestForm from '../../components/RequestForm';

export default function NewRequest() {
  const { handleSubmit, requestFormRef } = useNewRequest();

  return (
    <Container>
      <Header />

      <div className="main">
        <RequestForm onSubmit={handleSubmit} ref={requestFormRef} />
      </div>
    </Container>
  );
};
