/* eslint-disable consistent-return */
import {
  useEffect, useImperativeHandle, useMemo, useState,
} from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';

import { api } from '../../services/apiClient';

import useErrors from '../../hooks/useErrors';

export default function useRequestForm(
  onSubmit,
  edit,
  ref,
) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { chamada: id, cliente: clientIdParams } = Object.fromEntries([...searchParams]);

  const [clients, setClients] = useState([]);
  const [clientSelected, setClientSelected] = useState({});
  const [visibleClientSelected, setVisibleClientSelected] = useState(false);

  const [products, setProducts] = useState([]);
  const [productSelectedId, setProductSelectedId] = useState({});
  const [productSelected, setProductSelected] = useState({});

  const [selectProductModalVisible, setSelectProductModalVisible] = useState(false);
  const [isLoadingProcuctSelected, setIsLoadingProcuctSelected] = useState(false);
  const [requestBeingFinished, setRequestBeingFinished] = useState(null);

  const [productCalledSelected, setProductCalledSelected] = useState([]);

  const [searchTerm, setSearchTerm] = useState('');

  const [quantity, setQuantity] = useState('0');
  const [value, setValue] = useState(0);
  const [cost, setCost] = useState(0);
  const [more, setMore] = useState('');

  const [currentPage, setCurrentPage] = useState(0);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    errors,
  } = useErrors();

  const isFormValid = (clientSelected.id && errors.length === 0);

  const pages = Math.ceil(productCalledSelected.length / 5);
  const startIndex = currentPage * 5;
  const endIndex = startIndex + 5;
  const currentItens = productCalledSelected.slice(startIndex, endIndex);

  const filteredItens = useMemo(() => clients.filter((searchItens) => (
    searchTerm === '' ? [] : searchItens?.name?.toLowerCase().includes(searchTerm?.toLowerCase())
    || searchItens?.user?.name?.toLowerCase()
  )), [clients, searchTerm]);

  useImperativeHandle(ref, () => ({
    setFieldsValues: async (request) => {
      const response = await api.get(`/client/${clientIdParams}`);

      setClientSelected(response.data);
      setValue(request.value ?? 0);
      setCost(request.cost ?? 0);
      setMore(request.request_more ?? '');
    },

    resetFields: () => {
      setValue(0);
      setCost(0);
      setMore('');
    },
  }), []);

  function handleConfirmRequest(request) {
    setRequestBeingFinished(request);

    setSelectProductModalVisible(true);
  };

  function handleCloseProductModal() {
    setSelectProductModalVisible(false);
  }

  function handleChangeSearchTerm(event) {
    setSearchTerm(event.target.value);
  };

  function handleQuantity(event) {
    setQuantity(event.target.value);
  };

  function handleValue(event) {
    setValue(event.target.value);
  };

  function handleCost(event) {
    setCost(event.target.value);
  };

  function handleMore(event) {
    setMore(event.target.value);
  };

  function handlePage(event) {
    setCurrentPage(Number(event.target.value));
  };

  async function handleSelectClient(clientId) {
    setClientSelected(clientId);
    setVisibleClientSelected(true);
    setSearchTerm('');
  };

  async function handleRemoveSelectClient() {
    setVisibleClientSelected(false);
  };

  async function handleSelectProduct(event) {
    setProductSelectedId(event.target.value);

    const response = await api.get(`/product/${event.target.value}`);

    setProductSelected(response.data);
  };

  async function handleBack() {
    if (!edit) {
      await api.delete(`/called/${id}`);
    };

    if (!edit) {
      toast.warn('Pedido cancelado!');
    }

    navigate(-1, { replace: true });
  };

  async function loadClients() {
    const response = await api.get('/clients');

    setClients(response.data);
  };

  async function loadProducts() {
    const response = await api.get('/all-products');

    setProducts(response.data);
  };

  async function loadProductsSelects() {
    const response = await api.get(`/product-called/${id}`);

    const totalValueProducts = response.data?.map((item) => {
      const total = item.quantity * item.value;

      if (item.quantity === 0) {
        return Number(item.value);
      };

      return total;
    });

    const totalCostProducts = response.data?.map((item) => {
      const total = item.quantity * item.cost;

      if (item.quantity === 0) {
        return Number(item.cost);
      };

      return total;
    });

    setProductCalledSelected(response.data);

    let sumValue = 0;
    for (let i = 0; i < totalValueProducts.length; i++) {
      sumValue += totalValueProducts[i];
    }

    let sumCost = 0;
    for (let i = 0; i < totalCostProducts.length; i++) {
      sumCost += totalCostProducts[i];
    }

    setValue(sumValue);
    setCost(sumCost);
  };

  async function handleProductSubmit() {
    try {
      setIsLoadingProcuctSelected(true);

      await api.post('/create-product-called', {
        called_id: id,
        product_id: productSelected.id,
        quantity,
      });

      if (quantity <= 0) {
        toast.error('Não é possivel adicionar um produto com quatidade 0!');

        return null;
      };

      setProductSelectedId('');
      setQuantity('0');

      loadProductsSelects();

      toast.success('Produto adicionado com sucesso.');

      setIsLoadingProcuctSelected(false);
    } catch {
      if (quantity > productSelected.quantity) {
        toast.error(`Quantidade indisponivel no estoque. Você possiu apenas ${productSelected.quantity} unidades.`);
      } else {
        toast.error('Erro ao adicionar produto.');
      };
    }
  }

  async function handleDeleteProductCalled(productCalled) {
    // Recupera informações do produto
    const productResponse = await api.get(`/product/${productCalled.product_id}`);
    const product = productResponse.data;

    // Adiciona quantidade escolhida com a quantidade restante do produto
    const updatedQuantity = product.quantity + productCalled.quantity;

    // Atualiza quantidade do produto no servidor
    await api.put(`/products/${productCalled.product_id}`, { quantity: updatedQuantity });

    // Remove productCalled
    await api.delete(`/product-called/${productCalled.id}`);

    // Recarrega selects
    loadProductsSelects();

    // Exibe mensagem de sucesso
    toast.success('Produto removido com sucesso.');
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setIsSubmitting(true);

    await onSubmit({
      client_id: clientSelected.id,
      called_id: id,
      value,
      cost,
      more,
    });

    setIsSubmitting(false);

    navigate('/clientes-ativos', { replace: false });
  };

  function autoResize() {
    const textarea = document.getElementById('more-informations');
    textarea.style.height = 'auto'; // Define a altura como automática para ajustar o conteúdo
    textarea.style.height = `${textarea.scrollHeight}px`; // Ajusta a altura conforme necessário
  }

  useEffect(() => {
    loadClients();

    loadProducts();

    loadProductsSelects();
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      // eslint-disable-next-line no-param-reassign
      event.returnValue = '';

      // eslint-disable-next-line no-alert
      return window.alert('TESTE');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return {
    searchTerm,
    handleChangeSearchTerm,
    products,
    filteredItens,
    quantity,
    handleQuantity,
    value,
    handleValue,
    cost,
    handleCost,
    more,
    handleMore,
    handleBack,
    clientSelected,
    handleSelectClient,
    handleRemoveSelectClient,
    visibleClientSelected,
    productSelectedId,
    handleSelectProduct,
    handleProductSubmit,
    handleSubmit,
    productCalledSelected,
    currentItens,
    pages,
    handlePage,
    handleDeleteProductCalled,
    isSubmitting,
    isFormValid,
    clientIdParams,
    autoResize,
    selectProductModalVisible,
    isLoadingProcuctSelected,
    requestBeingFinished,
    handleConfirmRequest,
    handleCloseProductModal,
  };
};
