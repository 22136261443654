import { Link } from 'react-router-dom';
import {
  Container,
  ButtonContainer,
} from './styles';

import Button from '../../components/Button';

export default function NotFound() {
  return (
    <Container>
      <h1>404</h1>

      <h2>Ops... Esta pagina não existe!</h2>

      <ButtonContainer>

        <Link to="/">
          <Button danger>
            Voltar
          </Button>
        </Link>

      </ButtonContainer>
    </Container>
  );
};
