import editPen from '../../assets/images/icons/edit-pen.svg';
import ben from '../../assets/images/icons/ben.svg';

import {
  Container,
} from './styles';

import useTeam from './useTeam';

import Header from '../../components/Header';
import ItensList from '../../components/ItensList';

export default function Team() {
  const {
    searchTerm,
    handleChangeSearchTerm,
    isDeleteModalVisible,
    collaboratorBeingDeleted,
    handleDeleteCollaborator,
    handleCloseDeleteModal,
    handleConfirmDeleteCollaborator,
    isLoadingDelete,
  } = useTeam();

  return (
    <Container>
      <Header />

      <div className="main">
        <ItensList
          placeholder="Pesquise aqui"
          buttonLabel="Novo usuário"
          link="/novo-colaborador?team=true"
          routeApi="/all-collaborators"
          iconOne={editPen}
          iconTwo={ben}
          pagination
          searchTerm={searchTerm}
          handleChangeSearchTerm={handleChangeSearchTerm}
          linkToEdit="/colaborador"
          office
          danger
          visible={isDeleteModalVisible}
          title={`Tem certeza que deseja remover o colaborador ”${collaboratorBeingDeleted?.name}”?`}
          confirmLabel="Deletar"
          onCancel={handleCloseDeleteModal}
          onConfirm={handleConfirmDeleteCollaborator}
          openModal={handleDeleteCollaborator}
          modalChildren="Esta ação não poderá ser desfeita!"
          isLoading={isLoadingDelete}
          team
        />
      </div>
    </Container>
  );
};
