import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../../contexts/AuthProvider/useAuth';

export default function useSingIn() {
  const auth = useAuth();

  const navigation = useNavigate();

  const signInFormRef = useRef(null);

  async function handleSubmit(user) {
    try {
      await auth.signIn({
        email: user.email,
        password: user.password,
      });

      navigation('/dashboard', { replace: true });
    } catch (error) {
      console.log(error);
    };
  };

  return {
    signInFormRef,
    handleSubmit,
  };
};
