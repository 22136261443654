import { useRef } from 'react';
import { useAuth } from '../../contexts/AuthProvider/useAuth';

export default function useNewRecord() {
  const auth = useAuth();

  const clientFormRef = useRef(null);

  async function handleSubmit(client) {
    try {
      await auth.createClient({
        type: client.type,
        name: client.name,
        phone: client.phone,
        email: client.email,
        address: client.address,
        more: client.more,
      });

      clientFormRef.current.resetFields();
    } catch (error) {
      console.log(error);
    }
  };

  return {
    handleSubmit,
    clientFormRef,
  };
};
