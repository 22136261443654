import styled from 'styled-components';

export const Container = styled.div`
  max-width: 280px;
  width: 100%;
  word-wrap: break-word;

  h1 {
    overflow-wrap: break-word;

    font-size: 16px;

    color: ${({ theme }) => theme.colors.main.alert};
  }

  section {
    margin-top: 8px;

    p {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 1240px) {
    h1 {
      font-size: 16px;
    }

    section {
      p {
        font-size: 12px;
      }
    }
  }
`;
