import { useRef, useState } from 'react';

import { useAuth } from '../../contexts/AuthProvider/useAuth';

export default function useSingUp() {
  const auth = useAuth();

  const signUpFormRef = useRef(null);

  const [isMovalVisible, setIsMovalVisible] = useState(false);

  async function handleSubmit(user) {
    try {
      await auth.signUp({
        email: user.email,
        name: user.name,
        password: user.password,
        type: user.type,
      });

      setIsMovalVisible(true);
    } catch (error) {
      console.log(error);

      setIsMovalVisible(false);
    };
  };

  return {
    signUpFormRef,
    handleSubmit,
    isMovalVisible,
  };
};
