import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;

  margin-top: 16px;

  width: 100%;

  border-radius: 12px;

  background: ${({ theme }) => theme.colors.main.light};

  .main {
    display: flex;
    flex-direction: row;

    margin-left: 0;

    span {
      font-size: 24px;

      color: ${({ theme }) => theme.colors.main.alert};
    }

    #estoque {
      padding-left: 32px;
    }

    .first-section {
      display: inline-block;

      width: 200px;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      box-sizing: border-box;

      overflow-wrap: break-word;

      section {
        margin-top: 8px;

        #cost {
          color: ${({ theme }) => theme.colors.danger.main};
        }
      }
    }

    .second-section {
      height: 100%;

      section {
        margin-top: 8px;
      }
    }
  }

  #icons {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    margin-bottom: 40px;
    margin-right: 64px;

    section {
      img {
        margin-left: 8px;
        cursor: pointer;
      }

      #cost {
        color: ${({ theme }) => theme.colors.danger.dark};
      }
    }
  }

  @media screen and (max-width: 767px) {
    font-size: 10px;

    padding: 8px;

    .main {
      span {
        font-size: 14px;
      }
    }

    #icons {
      margin-bottom: 8px;
      margin-right: 8px;

      section {
        img {
          margin-left: 12px;
        }
      }
    }

    #estoque {
      margin-left: 0;

      width: 50px;
    }

    .first-section {
      display: inline-block;

      max-width: 80px;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      box-sizing: border-box;
    }
  }
`;
