import { useEffect, useState, useMemo } from 'react';

import { api } from '../../../../services/apiClient';

export default function useProductInfo(searchTerm) {
  const [itens, setItens] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [currentPage, setCurrentPage] = useState(0);

  const [hasError, setHasError] = useState(false);

  const pages = Math.ceil(itens.length / 3);
  const startIndex = currentPage * 3;
  const endIndex = startIndex + 3;
  const currentItens = itens.slice(startIndex, endIndex);

  const filteredItens = useMemo(() => itens.filter((searchItens) => (
    searchItens.name.toLowerCase().includes(searchTerm.toLowerCase())
  )), [currentItens, searchTerm]);

  const [isLoading, setIsLoading] = useState(false);

  async function loadItens() {
    try {
      setIsLoading(true);

      const response = await api.get('/all-products');

      setItens(response.data);

      setHasError(false);
    } catch {
      setHasError(true);
      setItens([]);
    } finally {
      setIsLoading(false);
    }
  };

  function handlePage(event) {
    setCurrentPage(Number(event.target.value));
  };

  function handleTryAgain() {
    loadItens();
  };

  useEffect(() => {
    loadItens();

    return () => {};
  }, []);

  return {
    isLoading,
    pages,
    handlePage,
    filteredItens,
    hasError,
    handleTryAgain,
    itens,
  };
};
