import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0 16%;

  width: 100%;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media screen and (max-width: 767px) {
    flex-direction: column;

    padding: 16px;

    img {
      margin: 0;
    }
  }
`;
