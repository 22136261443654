import React from 'react';
import { useSearchParams } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import Dashboard from '../pages/Dashboard';

function DashboardWithDefaultQueryParam() {
  const [searchParams] = useSearchParams();
  const dashParam = searchParams.get('dash');

  if (!dashParam) {
    // Se o query parameter "dash" não estiver definido na URL,
    // redirecionamos para a mesma URL com o query parameter "dash=true" adicionado.
    const url = new URL(window.location.href);
    url.searchParams.set('dash', 'true');
    window.location.href = url.toString();
  }

  return <PrivateRoute isPrivate element={<Dashboard />} />;
}

export { DashboardWithDefaultQueryParam };
