import styled, { keyframes, css } from 'styled-components';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

const scaleIn = keyframes`
  from { transform: scale(0); }
  to { transform: scale(1); }
`;

const scaleOut = keyframes`
  from { transform: scale(1); }
  to { transform: scale(0); }
`;

export const Overlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  backdrop-filter: blur(5px);

  background: rgba(0, 0, 0, 0.6);

  animation: ${fadeIn} 0.3s forwards;

  ${({ isLeaving }) => isLeaving && css`
    animation: ${fadeOut} 0.2 forwards;
  `}

  @media screen and (max-width: 767px) {
    padding: 16px;
  }
`;

export const Container = styled.div`
  padding: 24px;

  max-width: ${({ width }) => (width ? `${width}px` : '450px')};
  width: 100%;

  border-radius: 12px;

  box-shadow: rgba(0, 0, 0, 0.04);

  background: #ffffff;

  animation: ${scaleIn} 0.3s;

  ${({ isLeaving }) => isLeaving && css`
    animation: ${scaleOut} 0.2s forwards;
  `}

  > h1 {
    font-size: 22px;

    overflow-wrap: break-word;

    color: ${({ theme, danger }) => (
    danger ? theme.colors.danger.main : theme.colors.gray[900]
  )};

    color: ${({ theme, alert }) => (
    alert ? theme.colors.main.alert : theme.colors.gray[900]
  )};
  }

  #little-title {
    font-size: 20px;

    margin-bottom: 16px;
  }

  .modal-body {
    margin-top: 32px;

    display: flex;
    flex-direction: column;

    input {
      padding: 16px;

      border-radius: 12px;

    }

    #input-bottom {
      margin-top: 16px;
    }
  }

  @media screen and (max-width: 767px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    padding: 16px;

    > h1 {
      font-size: 16px;

      text-align: center;

      color: ${({ theme }) => theme.colors.main.alertBold};
    }

    div {
      font-size: 14px;
    }

    .modal-body {
      margin-top: 8px;

      div {
        margin-top: 4px;

        .table {
          padding: 8px;
        }
      }
    }
  }
`;

export const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  margin-top: 32px;

  button {
    border-radius: 12px;
  }

  .cancel-button {
    margin-right: 24px;

    border: none;

    font-size: 16px;

    color: ${({ theme }) => theme.colors.gray[200]};
    background: transparent;

    &[disabled] {
      cursor: not-allowed;
    }
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;

    margin-top: 16px;

    width: 100%;

    button {
      width: 100%;

      margin-bottom: 8px;
    }

    .cancel-button {
      margin-right: 0;

      padding: 16px;
      height: 52px;

      color: ${({ theme }) => theme.colors.main.light};
      background-color: ${({ theme }) => theme.colors.danger.main};
    }
  }
`;
