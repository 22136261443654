import PropTypes from 'prop-types';

import arrowLeft from '../../../../assets/images/icons/arrow-left.svg';
import arrowRight from '../../../../assets/images/icons/arrow-right.svg';

import {
  Container,
} from './styles';

import Button from '../../../Button';

export default function Pagination({ pages, currentPage, setCurrentPage }) {
  const MAX_VISIBLE_PAGES = 3; // definindo o número máximo de páginas visíveis na navegação

  function handlePage(value) {
    setCurrentPage(value);
  }

  const firstPage = Math.max(0, currentPage - Math.floor(MAX_VISIBLE_PAGES / 2));
  const lastPage = Math.min(pages - 1, firstPage + MAX_VISIBLE_PAGES - 1);

  const pagesToRender = Array.from(
    { length: lastPage - firstPage + 1 },
    (_, i) => i + firstPage,
  );

  return (
    <Container>
      <Button disabled={currentPage === 0} onClick={() => setCurrentPage(currentPage - 1)}>
        <img src={arrowLeft} alt="Previous page" width={8} />
      </Button>
      <section>
        {pagesToRender.map((page) => (
          <button
            key={page}
            type="button"
            value={page}
            onClick={() => handlePage(page)}
            className={currentPage === page ? 'active' : ''}
          >
            {page + 1}
          </button>
        ))}
      </section>
      <Button
        disabled={currentPage === pages - 1}
        onClick={() => setCurrentPage(currentPage + 1)}
      >
        <img src={arrowRight} alt="Next page" width={8} />
      </Button>
    </Container>
  );
}

Pagination.propTypes = {
  pages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
};
