import {
  Container,
} from './styles';

import Header from '../../components/Header';
import ProductForm from '../../components/ProductForm';

import useEditProduct from './useEditProduct';

export default function EditProduct() {
  const {
    handleSubmit,
    productFormRef,
  } = useEditProduct();

  return (
    <Container>
      <Header />

      <div className="main">
        <ProductForm ref={productFormRef} onSubmit={handleSubmit} />
      </div>

    </Container>
  );
};
