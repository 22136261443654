/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';

import {
  Container,
} from './styles';

export default function ProductsTable({ products }) {
  return (
    <Container>
      <table>
        <thead>
          <tr>
            <th scope="col">Item</th>
            <th scope="col">Quant</th>
            <th scope="col">Valor</th>
          </tr>
        </thead>

        <tbody>
          {products.map((item) => (
            <>
              <tr>
                <td>{item.name}</td>
                <td>{item.quantity} uni</td>
                <td>{new Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(
                  item?.value,
                )}
                </td>
              </tr>
            </>
          ))}
        </tbody>
      </table>

    </Container>
  );
}

ProductsTable.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
};
