import {
  forwardRef,
} from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import useSignUpForm from './useSignUpForm';

import {
  Form,
} from './styles';

import Input from '../Input';
import InfoModal from '../InfoModal';
import Button from '../Button';

const SignUpForm = forwardRef(({ buttonLabel, onSubmit, visibleModal }, ref) => {
  const {
    name,
    handleName,
    type,
    handleType,
    email,
    handleEmail,
    password,
    handlePassword,
    handleSubmit,
    isSubmitting,
    getErrorMessageByFieldName,
    // errors,
    isFormValid,
    icon,
    typeInput,
    handleToggle,
  } = useSignUpForm(onSubmit, ref);

  return (
    <Form onSubmit={handleSubmit}>
      <div className="inputs">
        <span>Nome de usuário</span>

        <Input
          error={getErrorMessageByFieldName('name')}
          placeholder="Nome de usuário *"
          name="name"
          value={name}
          onChange={(event) => handleName(event)}
          disabled={isSubmitting}
        />

        <p>{getErrorMessageByFieldName('name')}</p>
      </div>

      <div className="inputs">
        <span>CPF ou CNPJ</span>

        <Input
          error={getErrorMessageByFieldName('type')}
          placeholder="CPF ou CNPJ *"
          name="type"
          value={type}
          onChange={handleType}
          maxLength={18}
          disabled={isSubmitting}
        />

        <p>{getErrorMessageByFieldName('type')}</p>
      </div>

      <div className="inputs">
        <span>Email</span>

        <Input
          error={getErrorMessageByFieldName('email')}
          placeholder="Email *"
          type="email"
          name="email"
          value={email}
          onChange={handleEmail}
          disabled={isSubmitting}
        />

        <p>{getErrorMessageByFieldName('email')}</p>
      </div>

      <div className="inputs">
        <span>Senha</span>

        <div
          className="input-wrapper"
        >
          <div>
            <Input
              error={getErrorMessageByFieldName('password')}
              placeholder="Senha *"
              type={typeInput}
              name="password"
              value={password}
              onChange={handlePassword}
              disabled={isSubmitting}
              style={{
                boxShadow: 'none',
              }}
            />

            <span
              onClick={handleToggle}
            >
              <img
                src={icon}
                alt="Mostrar senha"
                width={20}
              />
            </span>
          </div>

          <p>{getErrorMessageByFieldName('password')}</p>
        </div>
      </div>

      <div>
        <Button
          type="submit"
          disabled={!isFormValid}
          isLoading={isSubmitting}
        >
          {buttonLabel}
        </Button>
      </div>

      <Link to="/">Já possui uma conta? Então clique aqui e faça login!</Link>

      <InfoModal visible={visibleModal}>
        <h1>Confira o <span>email de verificação</span> que foi enviado para seu email.</h1>

        <p>Olhe a caixa de entrada, spam e lixeira.</p>
      </InfoModal>
    </Form>
  );
});

SignUpForm.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  visibleModal: PropTypes.bool,
};

SignUpForm.defaultProps = {
  visibleModal: false,
};

export default SignUpForm;
