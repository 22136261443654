import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 14%;
  padding: 0 16% 0 16%;

  width: 100%;
`;
