/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable quote-props */
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import clock from '../../../../assets/images/icons/clock.svg';
import linkImg from '../../../../assets/images/icons/link.svg';

import {
  Container,
  InfoWrapper,
} from './styles';

import EmptyList from '../EmptyList';
import ErrorStatus from '../ErrorStatus';

import LoaderComponent from '../../../LoaderComponent';

import useInfoItems from './useInfoItems';

import useScreenResolutionRealTime from '../../../../hooks/useScreenResolutionRealTime';

export default function InfoItems({
  routeApi,
  iconOne,
  iconTwo,
  maxLength,
  searchTerm,
  linkToEdit,
  office,
  openModal,
  buttonLabel,
  request,
  team,
  page,
}) {
  const {
    filteredItensDefault,
    isLoading,
    hasError,
    handleTryAgain,
    filteredItensRemoveDuplicates,
    copiarTexto,
  } = useInfoItems(routeApi, maxLength, searchTerm);

  const { screenResolution } = useScreenResolutionRealTime();

  console.log(screenResolution);

  const filtered = filteredItensRemoveDuplicates;

  const hasItems = filtered.length > 0 || filteredItensDefault.length > 0;

  const isListEmpty = !hasError && (!isLoading && !hasItems);

  function linkEdit(item, link) {
    if (item?.office === 'CEO') {
      return `/minha-conta/${item.id}`;
    };

    if (request) {
      return `${link}?cliente=${item.id}&records=true`;
    }

    return `${linkToEdit}/${item?.client_id}`;
  };

  function linkEditTeam(item, routeApiLink) {
    if (item?.typeUser) {
      return `/minha-conta/${item?.user?.id}`;
    };

    if (routeApiLink === '/all-collaborators') {
      return `${linkToEdit}/${item?.id}?office=${item.office}&team=true`;
    };

    if (page) {
      return `${linkToEdit}/${item?.id}?${page}=true`;
    }

    return `${linkToEdit}/${item?.id}`;
  };

  const tag = {
    'CEO': '#2C73C2',
    'ADM': '#FFB400',
    'Colaborador': '#373737',
  };

  return (
    <>
      {hasError && <ErrorStatus onTryAgain={handleTryAgain} />}
      {isListEmpty && <EmptyList buttonLabel={buttonLabel} />}

      {isLoading ? (
        <LoaderComponent className="loader" isLoading={isLoading} />
      ) : request ? (
        <>
          {filtered.map((item) => (
            <Container key={item.id}>
              <InfoWrapper key={item.id} id="primary">
                {office ? (
                  <>
                    <section id="header">
                      <span id="name">{item?.name?.toUpperCase() || item?.user?.name?.toUpperCase()}</span>
                      <span
                        id="tag"
                        style={{
                          background: tag[item.office || item?.typeUser],
                        }}
                      >{item.office || (item?.typeUser === 'ADM-MASTER' && 'CEO')}
                      </span>
                    </section>
                  </>
                ) : (
                  <>
                    <span id="name">{item.name || item?.user?.name}</span>
                  </>
                )}

                <div className="middle">
                  <section id="informations">
                    <p>{item.type || item?.user?.type}</p>
                    <p>{item.phone}</p>
                    <p>{item.email || item?.user?.email}</p>
                    <p>{item.address}</p>
                  </section>

                  <section>
                    <Link to={linkEdit(item, linkToEdit)}>
                      <img src={iconOne} alt="" width={16} />
                    </Link>
                  </section>
                </div>
              </InfoWrapper>
            </Container>
          ))}
        </>
      ) : (
        <>
          {filteredItensDefault.map((item) => (
            <Container key={item.id}>
              <InfoWrapper key={item.id} id="primary">
                {office ? (
                  <>
                    <section id="header">
                      <span id="name">{item?.name?.toUpperCase() || item?.user?.name?.toUpperCase()}
                      </span>
                      <section>
                        <span
                          id="tag"
                          style={{
                            background: tag[item.office || item?.typeUser],
                          }}
                        >{item.office || (item?.typeUser === 'ADM-MASTER' && 'CEO')}
                        </span>

                        { item.office !== 'CEO' && (
                          <>
                            {item.office === 'ADM' ? (
                              screenResolution.width > 800 ? (
                                <>
                                  <p onClick={() => copiarTexto('https://scpmanager.com.br/adm')}>
                                    https://scpmanager.com.br/adm

                                    <img src={linkImg} alt="Link" width={13} />
                                  </p>
                                </>
                              ) : null
                            ) : (
                              screenResolution.width > 800 ? (
                                <>
                                  <p onClick={() => copiarTexto('https://scpmanager.com.br/colaborador')}>
                                    https://scpmanager.com.br/colaborador

                                    <img src={linkImg} alt="Link" width={13} />
                                  </p>
                                </>
                              ) : null
                            )}
                          </>
                        ) }
                      </section>
                    </section>
                  </>
                ) : (
                  <>
                    <span id="name">{item.name || item?.user?.name}</span>
                  </>
                )}

                <div className="middle">
                  <section id="informations">
                    <p>{item.type || item?.user?.type}</p>
                    <p>{item.phone}</p>
                    <p>{item.email || item?.user?.email}</p>
                    <p>{item.address}</p>
                  </section>

                  <section>
                    {item?.typeUser ? (
                      <></>
                    ) : (
                      <>
                        {team ? (
                          <>
                            { screenResolution.width < 800 && item.office !== 'CEO' && (
                            <>
                              {item.office === 'ADM' ? (
                                <img
                                  src={linkImg}
                                  alt="link"
                                  onClick={() => copiarTexto('https://scpmanager.com.br/adm')}
                                />
                              ) : (
                                <img
                                  src={linkImg}
                                  alt="link"
                                  onClick={() => copiarTexto('https://scpmanager.com.br/colaborador')}
                                />
                              )}
                            </>
                            )}
                          </>
                        ) : (
                          <Link to={`/histórico-de-pedidos?cliente=${item.id}&records=true`}>
                            <img src={clock} alt="Histórico de pedidos" width={16} />
                          </Link>
                        )}

                        { item.office !== 'CEO' && (
                        <Link to={linkEditTeam(item, routeApi)}>
                          <img src={iconOne} alt="Editar colaboradores" width={16} />
                        </Link>
                        )}

                        {item.office !== 'CEO' && (
                          <img src={iconTwo} alt="" width={16} onClick={() => openModal(item)} />
                        )}
                      </>
                    )}
                  </section>
                </div>
              </InfoWrapper>
            </Container>
          ))}
        </>
      )}
    </>
  );
};

InfoItems.propTypes = {
  routeApi: PropTypes.string.isRequired,
  iconOne: PropTypes.string,
  iconTwo: PropTypes.string,
  maxLength: PropTypes.number,
  searchTerm: PropTypes.string.isRequired,
  linkToEdit: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  office: PropTypes.bool,
  openModal: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  request: PropTypes.bool,
  team: PropTypes.bool,
  page: PropTypes.string,
};

InfoItems.defaultProps = {
  iconOne: null,
  iconTwo: null,
  maxLength: 3,
  linkToEdit: '',
  office: false,
  request: false,
  team: false,
  page: '',
};
