/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { api } from '../../services/apiClient';
import { getStripeJs } from '../../services/stripe-js';
import isEmailValid from '../../utils/isEmailValid';

import useErrors from '../../hooks/useErrors';

export default function useCheckOut() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { success, discount_id: discountId, plan } = Object.fromEntries([...searchParams]);

  const [email, setEmail] = useState('');

  const [isSubmiting, setIsSubmiting] = useState(false);

  const {
    setError,
    removeError,
    errors,
    getErrorMessageByFieldName,
  } = useErrors();

  const isValid = (email && errors.length === 0);

  function handleEmail(event) {
    setEmail(event.target.value);

    if (event.target.value && !isEmailValid(event.target.value)) {
      setError({ field: 'email', message: 'E-mail invalido' });
    } else {
      removeError('email');
    }
  };

  async function handleSubmit(event) {
    event.preventDefault();

    const response = await api.post(`/subscribe?plan=${plan}`, {
      email: String(email),
    });

    const { sessionId } = response.data;

    const stripe = await getStripeJs();

    await stripe.redirectToCheckout({ sessionId });
  }

  return {
    isSubmiting,
    handleSubmit,
    success,
    discountId,
    email,
    handleEmail,
    isValid,
    plan,
    getErrorMessageByFieldName,
  };
};
