import styled, { css, keyframes } from 'styled-components';

const fadeIn = keyframes`
from { opacity: 0; }
to { opacity: 1; }
`;

const fadeOut = keyframes`
from { opacity: 1; }
to { opacity: 0; }
`;

export const Overlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: 25%;

  animation: ${fadeIn} 0.3s;
  ${({ isLeaving }) => isLeaving && css`
    animation: ${fadeOut} 0.3s;
  `}

  @media screen and (max-width: 1240px) {
    margin-left: 0;
  }
`;
