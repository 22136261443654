/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import sevenDays from '../../assets/images/garantia.png';
import stripeLogo from '../../assets/images/stripe-logo.png';

import {
  Form,
  Wrapper,
  Payment,
} from './styles';

import Button from '../../components/Button';
import HeaderCheckOut from '../../components/HeaderCheckOut';

import Success from './sub-page/Success';
import Input from '../../components/Input';

import useCheckOut from './useCheckOut';

export default function CheckOut() {
  const {
    isSubmiting,
    success,
    handleSubmit,
    discountId,
    email,
    handleEmail,
    isValid,
    plan,
    getErrorMessageByFieldName,
  } = useCheckOut();

  return (
    <>
      <HeaderCheckOut />

      <Form onSubmit={handleSubmit}>

        { success ? (
          <Success />
        ) : (

          <div className="main">
            <Wrapper>
              <div className="body">
                <section id="title-section">
                  <h1 id="title">Conclua</h1>
                  <h1 id="title2">sua compra</h1>
                </section>

                <span id="sub-title">Assinatura <strong>SCP MANAGER</strong></span>

                <section>
                  <h1>
                    {plan === 'monthly' && (<>Valor mensal</>)}
                    {plan === 'yearly' && (<>Valor anual</>)}
                  </h1>
                  <h1 id="price">
                    <strong>
                      {plan === 'monthly' && (<>R$75,00 </>)}
                      {plan === 'yearly' && (<>R$500,00 </>)}
                    </strong>
                  </h1>
                </section>

                <p>Assine o SCP Manager hoje e tenha total controle do seu negócio. <span id="red">Cancele quando quiser.</span>
                  Deixe seu feedback!
                </p>

                {window.innerWidth > 1240 && (
                  <Button
                    type="submit"
                    isLoading={isSubmiting}
                    disabled={!isValid}
                  >
                    Finalizar compra
                  </Button>
                )}
              </div>
            </Wrapper>

            {window.innerWidth < 1240 && (
              <div className="actions">
                <div>
                  <Input
                    error={getErrorMessageByFieldName('email')}
                    placeholder="Digite seu melhor email"
                    value={email}
                    onChange={handleEmail}
                  />

                  <p>{getErrorMessageByFieldName('email')}</p>
                </div>

                <Button
                  type="submit"
                  isLoading={isSubmiting}
                  disabled={!isValid}
                >
                  Finalizar compra
                </Button>

              </div>

            )}

            {window.innerWidth > 1240 && (
              <Wrapper>
                <Payment>
                  <Input
                    placeholder="Digite seu melhor email"
                    value={email}
                    onChange={handleEmail}
                  />

                  <p>{getErrorMessageByFieldName('email')}</p>
                </Payment>
              </Wrapper>
            )}

            { discountId && (
              <Wrapper discountId={discountId}>
                <img
                  src={stripeLogo}
                  alt="Bandeiras Mercado Pago"
                  width={272}
                />
              </Wrapper>
            ) }
          </div>
        )}
      </Form>
    </>
  );
};
