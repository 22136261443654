import {
  Container,
} from './styles';

import useEditRequest from './useEditRequest';

import Header from '../../components/Header';
import RequestForm from '../../components/RequestForm';

export default function EditRequest() {
  const { requestFormRef, handleSubmit } = useEditRequest();

  return (
    <Container>
      <Header />

      <div className="main">
        <RequestForm ref={requestFormRef} onSubmit={handleSubmit} edit />
      </div>
    </Container>
  );
};
