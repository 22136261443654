/* eslint-disable max-len */
import ben from '../../assets/images/icons/ben.svg';
import editPen from '../../assets/images/icons/edit-pen.svg';

import {
  Container,
} from './styles';

import useRecords from './useRecords';

import Header from '../../components/Header';

import ItensList from '../../components/ItensList';

export default function Records() {
  const {
    searchTerm,
    handleChangeSearchTerm,
    isDeleteModalVisible,
    recordBeingDeleted,
    handleDeleteRecord,
    handleCloseDeleteModal,
    handleConfirmDeleteContact,
    isLoadingDelete,
  } = useRecords();

  return (
    <Container>
      <Header />

      <div className="main">
        <ItensList
          searchTerm={searchTerm}
          handleChangeSearchTerm={handleChangeSearchTerm}
          routeApi="/clients"
          iconOne={editPen}
          iconTwo={ben}
          pagination
          buttonLabel="Novo cadastro"
          link="/novo-cadastro?records=true"
          placeholder="Pesquise aqui"
          linkToEdit="/cliente"
          danger
          visible={isDeleteModalVisible}
          title={`Tem certeza que deseja remover o cadastro ”${recordBeingDeleted?.name}”?`}
          confirmLabel="Deletar"
          onCancel={handleCloseDeleteModal}
          onConfirm={handleConfirmDeleteContact}
          openModal={handleDeleteRecord}
          modalChildren="Esta ação não poderá ser desfeita!"
          isLoading={isLoadingDelete}
          page="records"
        />
      </div>
    </Container>
  );
};
