import {
  Container,
} from './styles';

import useNewProduct from './useNewProduct';

import Header from '../../components/Header';
import ProductForm from '../../components/ProductForm';

export default function NewProduct() {
  const { handleSubmit, productFormRef } = useNewProduct();

  return (
    <Container>
      <Header />

      <div className="main">
        <ProductForm
          onSubmit={handleSubmit}
          ref={productFormRef}
        />
      </div>

    </Container>
  );
};
