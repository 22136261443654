import styled from 'styled-components';

export const Container = styled.div`
  margin-left: 25%;
  padding: 64px;

  .main {
    display: flex;
    justify-content: center;
    align-items: center;

    .header {
      display: flex;
      flex-direction: column;

      padding: 32px;

      border-radius: 12px;

      background-color: ${({ theme }) => theme.colors.main.light};

      #top {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        section {
          display: flex;
          flex-direction: column;

          span {
            font-size: 14px;
          }
        }
      }

      #table {
        margin-top: 32px;
      }

      #total {
        display: flex;
        align-items: center;
        justify-content: end;

        margin-top: 16px;

        font-size: 20px;

        p {
          margin-right: 10px;

          color: ${({ theme }) => theme.colors.main.alert};
        }
      }

      #footer {
        margin-top: 32px;

        span {
          font-size: 32px;

          color: ${({ theme }) => theme.colors.main.alert};
        }

        p {
          margin-top: 8px;
        }
      }

      #bottom {
        display: flex;
        align-items: center;
        justify-content:  space-between;

        margin-top: 32px;

        width: 100%;

        p {

          a {
            text-decoration: none;

          }
        }

        img {
          cursor: pointer;
        }
      }
    }
  }

  @media screen and (max-width: 1240px) {
    padding: 16px;
    margin-left: 0;

    .main {
      .header {
        padding: 16px;

        #top {
          flex-direction: column-reverse;
          justify-content: start;

          width: 100%;

          section {
            margin-bottom: 8px;

            span {
              font-size: 12px;
            }
          }
        }

        #table {
          margin-top: 16px;
        }

        #total {
          margin-top: 8px;

          font-size: 16px;
        }

        #bottom {
          margin-top: 16px;

          p {
            font-size: 12px;
          }
        }
      }
    }
  }
`;
