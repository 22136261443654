import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import ben from '../../../../assets/images/icons/ben.svg';
import editPen from '../../../../assets/images/icons/edit-pen.svg';

import {
  Container,
} from './styles';

import useProductInfo from './useProductInfo';
import EmptyList from '../../../../components/ItensList/components/EmptyList';
import ErrorStatus from '../../../../components/ItensList/components/ErrorStatus';
import Loader from '../../../../components/Loader';

export default function ProductInfo({ searchTerm, openModal }) {
  const {
    filteredItens,
    isLoading,
    hasError,
    handleTryAgain,
  } = useProductInfo(searchTerm);

  const hasItens = filteredItens.length > 0;
  const isListEmpty = !hasError && (!isLoading && !hasItens);

  return (
    <>
      <Loader isLoading={isLoading} />

      {hasError && <ErrorStatus onTryAgain={handleTryAgain} />}
      {isListEmpty && <EmptyList buttonLabel="Novo produto" />}

      {filteredItens.map((item) => (
        <Container key={item.id}>
          <div className="main">
            <div className="first-section">
              <span>{item.name}</span>

              <section>
                <p>
                  {new Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(item.value)}
                </p>

                <p id="cost">
                  {new Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(item.cost)}
                </p>

              </section>

            </div>

            <div className="second-section" id="estoque">
              <span>Estoque</span>

              <section>
                <p>
                  Uni:
                  {' '}
                  {item.quantity}
                </p>
              </section>
            </div>
          </div>

          <div id="icons">
            <section>
              <Link to={`/produto/${item.id}?products=true`}>
                <img src={editPen} alt="" width={20} />
              </Link>
              <img src={ben} alt="" width={20} onClick={() => openModal(item)} />
            </section>
          </div>
        </Container>
      ))}
    </>
  );
};

ProductInfo.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  openModal: PropTypes.func.isRequired,
};
