import PropTypes from 'prop-types';

import { Overlay } from './styles';

import ReactPortal from '../ReactPortal';
import Spinner from '../Spinner';

import useAnimatedUnmounted from '../../hooks/useAnimatedUnmounted';

export default function LoaderComponent({ isLoading }) {
  const { shouldRender, animatedElementRef } = useAnimatedUnmounted(isLoading);

  if (!shouldRender) {
    return null;
  };

  return (
    <ReactPortal containerId="loader-component-root">
      <Overlay isLeaving={!isLoading} ref={animatedElementRef}>
        <Spinner />
      </Overlay>
    </ReactPortal>
  );
};

LoaderComponent.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};
