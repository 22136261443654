import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthProvider/useAuth';

// eslint-disable-next-line react/prop-types
function PrivateRoute({ element, isPrivate }) {
  const { tokenAccess: isLogged } = useAuth();

  if (isLogged && isPrivate) {
    return element;
  };

  if (!isLogged && isPrivate) {
    return <Navigate to="/" replace />;
  }
}

export default PrivateRoute;
