export default {
  colors: {
    background: '#F3F3F3',
    main: {
      lighter: '#2C73C2',
      blue: '#3290FF',
      light: '#FFFFFF',
      success: '#90B442',
      green: '#43c928',
      greenBlack: '#23a928',
      greenLight: '#6fed55',
      alert: '#FFB400',
      alertBold: '#FFB500',
      black: '#000000',
    },
    gray: {
      bold: '#2B2B2B',
      medium: '#5C5C5C',
      900: '#373737',
      800: '#313131',
      500: '#5A5A5A',
      100: '#E5E5E5',
      200: '#BCBCBC',
      300: '#3C3C3C',
    },
    danger: {
      light: '#F97171',
      main: '#FC5050',
      dark: '#F63131',
    },
  },
};
