/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'react-router-dom';
import businessmen from '../../../../assets/images/businessmen.png';

import {
  Container,
  Wrapper,
} from './styles';

import Button from '../../../../components/Button';

export default function Success() {
  return (
    <Container>
      <div className="main">
        <Wrapper>
          <div className="wrapper">
            <h1>Compra concluída</h1>

            <h3>A <strong>Systemcraft</strong> agradece sua compra!</h3>

            <p id="text">
              Agora, cadastre-se no <strong>SCP MANAGER</strong> no botão abaixo e
              tenha todo o controle e suas mãos.
            </p>

            <Link to="/cadastrar" id="button">
              <Button type="button">
                Realizar cadastro no SCP MANAGER
              </Button>
            </Link>

            <p>
              Ajude a Systemcraft a melhorar sua gestão empresarial,
              entre em contato conosco através do suporte e deixe seu feedback, sugestões
              para melhorarmos cada vez mais a sua experiência.
            </p>

            <strong id="strong-red">Cancele quando quiser!</strong>
          </div>

          {window.innerWidth > 1240 && (
            <img src={businessmen} alt="Empresários" />
          )}
        </Wrapper>
      </div>
    </Container>
  );
}
