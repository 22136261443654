import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  width: 100%;

  input {
    padding: 16px;

    width: 100%;

    border: none;
    border-radius: 12px;

    outline: none;
  }

  select {
    margin-left: 10px;

    border-radius: 12px;
    text-align: center;

    cursor: pointer;

    background: ${({ theme }) => theme.colors.gray[900]};
    color: ${({ theme }) => theme.colors.main.light};
  }

  @media screen and (max-width: 1240px) {
    input {
      height: 40px;
    }
  }
`;
