import styled from 'styled-components';

export const Form = styled.form`
  max-width: 408px;
  width: 100%;

  div {
    span {
      font-size: 20px;

      color: ${({ theme }) => theme.colors.main.alertBold};
    }

    p {
      margin-top: 8px;
      margin-bottom: 12px;
    }

    #error {
      margin-bottom: 16px;

      color: ${({ theme }) => theme.colors.danger.dark};
    }

    button {
      padding: 16px;
      margin-bottom: 8px;

      width: 100%;

      border-radius: 14px;
      border: none;

      font-size: 18px;

      color: ${({ theme }) => theme.colors.main.light};
      background: ${({ theme }) => theme.colors.main.success};
    }
  }
`;
