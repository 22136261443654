import PropTypes from 'prop-types';

import {
  Container,
} from './styles';

import InfoItems from './components/InfoItems';
import SearchForm from './components/SearchForm';

import Modal from '../Modal';

import ProductInfo from '../../pages/Products/components/ProductInfo';

export default function ItensList({
  routeApi,
  iconOne,
  iconTwo,
  pagination,
  maxLength,
  buttonLabel,
  link,
  twoButtons,
  placeholder,
  searchTerm,
  handleChangeSearchTerm,
  productsInfo,
  linkToEdit,
  customData,
  office,
  danger,
  visible,
  isLoading,
  title,
  modalChildren,
  cancelLabel,
  confirmLabel,
  onCancel,
  onConfirm,
  openModal,
  request,
  team,
  page,
  withoutbutton,
}) {
  return (
    <Container>
      <SearchForm
        buttonLabel={buttonLabel}
        link={link}
        twoButtons={twoButtons}
        placeholder={placeholder}
        value={searchTerm}
        onChange={handleChangeSearchTerm}
        request={request}
        withoutbutton={withoutbutton}
      />

      { productsInfo ? (
        <>
          <ProductInfo openModal={openModal} searchTerm={searchTerm} />
        </>
      ) : (
        <>
          <InfoItems
            searchTerm={searchTerm}
            routeApi={routeApi}
            iconOne={iconOne}
            iconTwo={iconTwo}
            pagination={pagination}
            maxLength={maxLength}
            linkToEdit={linkToEdit}
            customData={customData}
            office={office}
            openModal={openModal}
            buttonLabel={buttonLabel}
            request={request}
            team={team}
            page={page}
          />
        </>
      )}

      <Modal
        danger={danger}
        visible={visible}
        isLoading={isLoading}
        title={title}
        confirmLabel={confirmLabel}
        onCancel={onCancel}
        onConfirm={onConfirm}
        cancelLabel={cancelLabel}
      >
        <p>{modalChildren}</p>
      </Modal>
    </Container>
  );
};

ItensList.propTypes = {
  routeApi: PropTypes.string.isRequired,
  iconOne: PropTypes.string,
  iconTwo: PropTypes.string,
  pagination: PropTypes.bool,
  maxLength: PropTypes.number,
  buttonLabel: PropTypes.string,
  link: PropTypes.string,
  twoButtons: PropTypes.bool,
  placeholder: PropTypes.string.isRequired,
  searchTerm: PropTypes.string.isRequired,
  handleChangeSearchTerm: PropTypes.func.isRequired,
  productsInfo: PropTypes.bool,
  linkToEdit: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  customData: PropTypes.object,
  office: PropTypes.bool,
  modalChildren: PropTypes.string.isRequired,
  danger: PropTypes.bool,
  visible: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  title: PropTypes.string.isRequired,
  cancelLabel: PropTypes.string,
  confirmLabel: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  request: PropTypes.bool,
  team: PropTypes.bool,
  page: PropTypes.string,
  withoutbutton: PropTypes.bool,
};

ItensList.defaultProps = {
  iconOne: null,
  iconTwo: null,
  pagination: false,
  maxLength: 3,
  buttonLabel: '',
  link: '',
  twoButtons: false,
  productsInfo: false,
  linkToEdit: '',
  customData: undefined,
  office: false,
  danger: false,
  isLoading: false,
  cancelLabel: 'Cancelar',
  confirmLabel: 'Confirmar',
  request: false,
  team: false,
  page: '',
  withoutbutton: false,
};
