import { Link } from 'react-router-dom';

import logo from '../../assets/images/logo-systemcraft.png';
import whatsapp from '../../assets/images/icons/icon-whatsapp.svg';
import instagram from '../../assets/images/icons/icon-instagram.svg';

import {
  Container,
  ItemsArea,
} from './styles';

export default function HeaderCheckOut() {
  return (
    <Container>
      <ItemsArea>
        <section>
          <Link to="https://systemcraft.com.br">
            <img
              src={logo}
              alt="Logo"
              width={128}
            />
          </Link>
        </section>

        {window.innerWidth >= 1240 ? (
          <div id="menu">
            <section>
              <Link
                id="link"
                to="https://www.instagram.com/systemcraft_brasil/"
              >
                <img
                  id="insta"
                  src={instagram}
                  alt="Instagram Systemcraft"
                  width={16}
                />
                Instagram
              </Link>
              <Link
                id="link"
                to="https://api.whatsapp.com/send?phone=5517991337463&text=Oi,%20estou%20com%20uma%20duvida!"
              >
                <img
                  src={whatsapp}
                  alt="Whatsapp suporte Systemcraft"
                  width={32}
                />
                Suporte
              </Link>
            </section>

          </div>
        ) : (
          <div id="menu">
            <section>
              <Link
                id="link"
                to="https://www.instagram.com/systemcraft_brasil/"
              >
                <img
                  id="insta"
                  src={instagram}
                  alt="Instagram Systemcraft"
                  width={16}
                />

              </Link>
            </section>

            <section>
              <Link
                id="link"
                to="https://api.whatsapp.com/send?phone=5517991337463&text=Oi,%20estou%20com%20uma%20duvida!"
              >
                <img
                  src={whatsapp}
                  alt="Whatsapp suporte Systemcraft"
                  width={32}
                />
              </Link>
            </section>
          </div>
        )}
      </ItemsArea>
    </Container>
  );
};
