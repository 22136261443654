import { useState, useImperativeHandle } from 'react';
import { useNavigate } from 'react-router-dom';

import showPassword from '../../assets/images/icons/show-password.svg';
import hidePassword from '../../assets/images/icons/hide-password.svg';

import useErrors from '../../hooks/useErrors';

import isEmailValid from '../../utils/isEmailValid';

export default function useSignInForm(onSubmit, ref) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigation = useNavigate();

  const [type, setType] = useState('password');
  const [icon, setIcon] = useState(hidePassword);

  const {
    setError,
    removeError,
    getErrorMessageByFieldName,
    errors,
  } = useErrors();

  const passValid = password.length >= 6;

  const isFormValid = (email && passValid && errors.length === 0);

  useImperativeHandle(ref, () => ({
    setFieldsValues: (adm) => {
      setEmail(adm.email ?? '');
      setPassword(adm.password ?? '');
    },

    resetFields: () => {
      setEmail('');
      setPassword('');
    },
  }), []);

  function handleToggle() {
    if (type === 'password') {
      setIcon(showPassword);
      setType('text');
    } else {
      setIcon(hidePassword);
      setType('password');
    };
  };

  function handleEmail(event) {
    setEmail(event.target.value);

    if (event.target.value && !isEmailValid(event.target.value)) {
      setError({ field: 'email', message: 'E-mail invalido' });
    } else {
      removeError('email');
    };
  };

  function handlePassword(event) {
    setPassword(event.target.value);

    if (!event.target.value) {
      setError({ field: 'password', message: 'Esse campo é obrigatório' });
    } else {
      removeError('password');
    };
  };

  async function handleSubmit(event) {
    try {
      event.preventDefault();

      setIsSubmitting(true);

      await onSubmit({
        email,
        password,
        navigation,
      });

      setIsSubmitting(false);
    } catch (error) {
      console.log(error);
    };
  };

  return {
    email,
    handleEmail,
    password,
    handlePassword,
    isSubmitting,
    handleSubmit,
    getErrorMessageByFieldName,
    errors,
    isFormValid,
    handleToggle,
    type,
    icon,
  };
};
