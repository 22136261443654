import { useRef } from 'react';
import { useAuth } from '../../contexts/AuthProvider/useAuth';

export default function useNewRequest() {
  const auth = useAuth();

  const requestFormRef = useRef(null);

  async function handleSubmit(request) {
    try {
      await auth.createRequest({
        calledId: request.called_id,
        clientId: request.client_id,
        value: request.value,
        cost: request.cost,
        more: request.more,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return {
    handleSubmit,
    requestFormRef,
  };
};
