import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';
import { api } from '../../services/apiClient';

export default function useProducts() {
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState('');

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [productBeingDeleted, setProductBeingDeleted] = useState(null);

  function handleChangeSearchTerm(event) {
    setSearchTerm(event.target.value);
  };

  function handleDeleteProduct(product) {
    setProductBeingDeleted(product);

    setIsDeleteModalVisible(true);
  };

  function handleCloseDeleteModal() {
    setIsDeleteModalVisible(false);
  };

  // eslint-disable-next-line consistent-return
  async function handleConfirmDeleteProduct() {
    try {
      setIsLoadingDelete(true);

      await api.delete(`/delete-product/${productBeingDeleted.id}`);

      navigate(0);

      toast.success('Produto deletado com sucesso!');

      handleCloseDeleteModal();
    } catch {
      return null;
    } finally {
      setIsLoadingDelete(false);
    };
  }

  return {
    searchTerm,
    handleChangeSearchTerm,
    isDeleteModalVisible,
    productBeingDeleted,
    handleDeleteProduct,
    handleCloseDeleteModal,
    handleConfirmDeleteProduct,
    isLoadingDelete,
  };
};
