/* eslint-disable consistent-return */
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { toast } from 'react-toastify';
import { api } from '../../services/apiClient';

import { formatNumber } from '../../utils/isNumberRequestValid';

export default function useOrderHistory() {
  const [searchParams] = useSearchParams();
  const { cliente: id } = Object.fromEntries([...searchParams]);

  const [client, setClient] = useState({});
  const [requests, setRequests] = useState([]);

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [requestBeingDeleted, setRequestBeingDeleted] = useState(null);

  const [isFinishModalVisible, setIsFinishModalVisible] = useState(false);
  const [isLoadingFinish, setIsLoadingFinish] = useState(false);
  const [requestBeingFinished, setRequestBeingFinished] = useState(null);

  const [isSettingsModalVisible, setIsSettingsModalVisible] = useState(false);
  const [requestSettings, setRequestSettings] = useState(null);

  const [currentPage, setCurrentPage] = useState(0);

  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState('');

  const [isLoading, setIsLoading] = useState(true);

  const [statusPayament, setStatusPayament] = useState({});

  const pages = Math.ceil(requests.length / 5);
  const startIndex = currentPage * 5;
  const endIndex = startIndex + 5;
  const currentItens = requests.slice(startIndex, endIndex);

  let filteredRequests = useMemo(() => {
    if (!searchTerm) {
      return requests;
    }

    const searchTermNumber = parseInt(searchTerm.replace(/^#0*/, ''), 10);
    return requests.filter((searchItens) => {
      const requestNumber = parseInt(String(searchItens?.request_number).replace(/^#0*/, ''), 10);
      return requestNumber === searchTermNumber;
    });
  }, [currentItens, searchTerm]);

  filteredRequests.sort((a, b) => b.request_number - a.request_number);

  async function handleStatusPayament({ itemId, checked }) {
    try {
      setStatusPayament((prevStatus) => ({
        ...prevStatus,
        [itemId]: checked,
      }));
    } catch (error) {
      throw error;
    }
  };

  async function handlePayment({ item, checked }) {
    await api.put(`/request/${item.called_id}`, {
      status_payament: checked,
    });

    if (checked) {
      toast.success('Pagamento concluido com sucesso!');
    };
  }

  function handleChangeSearchTerm(event) {
    const input = event.target.value;
    if (input === '#000') {
      setSearchTerm('');
    } else {
      setSearchTerm(formatNumber(input));
    }
  };

  function handleKeyDown(event) {
    if (event.key === 'Backspace' && searchTerm === '#0000') {
      setSearchTerm('');
    }
  }

  function handleDeleteRequest(request) {
    setRequestBeingDeleted(request);

    setIsDeleteModalVisible(true);
  };

  function handleFinishRequest(request) {
    setRequestBeingFinished(request);

    setIsFinishModalVisible(true);
  };

  function handleCloseDeleteModal() {
    setIsDeleteModalVisible(false);
  };

  function handleCloseFinishModal() {
    setIsFinishModalVisible(false);
  };

  function handleSettings(request) {
    setIsSettingsModalVisible(true);

    console.log(request);

    setRequestSettings(request);
  };

  function handleCloseSettings() {
    setIsSettingsModalVisible(false);
  };

  function handlePage(event) {
    setCurrentPage(Number(event.target.value));
  };

  async function loadClient() {
    const response = await api.get(`/client/${id}`);

    setClient(response.data);
  };

  async function loadResquests() {
    const response = await api.get(`/requests/${id}`);

    const { data } = response;

    setRequests(data);
    setIsLoading(false);

    data.forEach(async (item) => {
      if (item.status_payament) {
        await handleStatusPayament({ itemId: item.id, item, checked: true });
      };
    });
  };

  async function handleConfirmFinishRequest() {
    try {
      setIsLoadingFinish(true);

      await api.put(`/request/finish/${requestBeingFinished.id}`);

      handleCloseFinishModal();

      toast.success('Pedido finalizado com sucesso!');

      loadResquests();
    } catch {
      toast.error('Erro ao finalizar o pedido!');

      return null;
    } finally {
      setIsLoadingFinish(false);
    };
  }

  function handleChangeFilter(event) {
    setFilter(event.target.value);
  };

  // eslint-disable-next-line consistent-return
  async function handleConfirmDeleteRequest() {
    try {
      setIsLoadingDelete(true);

      await api.delete(`/request/${requestBeingDeleted.id}`);

      handleCloseDeleteModal();

      toast.success('Pedido deletado com sucesso!');

      loadResquests();
    } catch {
      toast.error('Erro ao deletar o pedido!');

      return null;
    } finally {
      setIsLoadingDelete(false);
    };
  }

  useEffect(() => {
    loadClient();

    loadResquests();
  }, []);

  return {
    client,
    requests,
    currentItens,
    pages,
    handlePage,
    isDeleteModalVisible,
    requestBeingDeleted,
    handleDeleteRequest,
    handleCloseDeleteModal,
    handleConfirmDeleteRequest,
    isLoadingDelete,
    handleFinishRequest,
    isFinishModalVisible,
    isLoadingFinish,
    requestBeingFinished,
    handleConfirmFinishRequest,
    handleCloseFinishModal,
    searchTerm,
    handleChangeSearchTerm,
    filteredRequests,
    handleKeyDown,
    filter,
    handleChangeFilter,
    statusPayament,
    handleStatusPayament,
    handlePayment,
    isLoading,
    isSettingsModalVisible,
    handleSettings,
    handleCloseSettings,
    requestSettings,
  };
};
