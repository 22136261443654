import { useImperativeHandle, useState } from 'react';

import useErrors from '../../hooks/useErrors';
import isEmailValid from '../../utils/isEmailValid';

export default function useForgotPasswordForm(onSubmit, ref) {
  const [email, setEmail] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    setError,
    removeError,
    getErrorMessageByFieldName,
    errors,
  } = useErrors();

  const isFormValid = (email && errors.length === 0);

  useImperativeHandle(ref, () => ({
    setFieldsValues: (adm) => {
      setEmail(adm.email ?? '');
    },

    resetFields: () => {
      setEmail('');
    },
  }), []);

  function handleEmail(event) {
    setEmail(event.target.value);

    if (event.target.value && !isEmailValid(event.target.value)) {
      setError({ field: 'email', message: 'E-mail invalido' });
    } else {
      removeError('email');
    }
  };

  async function handleSubmit(event) {
    event.preventDefault();

    setIsSubmitting(true);

    await onSubmit({
      email,
    });

    setIsSubmitting(false);
  };

  return {
    email,
    handleEmail,
    isSubmitting,
    handleSubmit,
    isFormValid,
    getErrorMessageByFieldName,
  };
};
