import { useState, useEffect, useMemo } from 'react';

import { toast } from 'react-toastify';

import { api } from '../../../../services/apiClient';

export default function useInfoItems(routeApi, maxLength, searchTerm) {
  const [itens, setItens] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [currentPage, setCurrentPage] = useState(0);

  const [hasError, setHasError] = useState(false);

  const removeDuplicates = new Map();
  [...itens].forEach(
    // eslint-disable-next-line consistent-return
    (object) => {
      if (!removeDuplicates.has(object.name)) {
        removeDuplicates.set(object.name, object);
      };
    },
  );

  const getValuesFromMap = [];

  removeDuplicates.forEach((value) => {
    // Verifica se algum item referenciado tem status diferente de false
    const referencedItems = itens.filter((item) => item.id === value.id);
    const anyReferencedItemsHaveStatusFalse = referencedItems.some((item) => item.status === true);

    // Adiciona o objeto ao array apenas se algum item referenciado tiver status diferente de false
    if (anyReferencedItemsHaveStatusFalse) {
      getValuesFromMap.push(value);
    }
  });

  const pages = Math.ceil(getValuesFromMap.length / maxLength);
  const startIndex = currentPage * maxLength;
  const endIndex = startIndex + maxLength;
  const currentItens = getValuesFromMap.slice(startIndex, endIndex);

  const filteredItensDefault = useMemo(() => itens.filter((searchItens) => (
    searchItens?.name?.toLowerCase().includes(searchTerm?.toLowerCase())
    || searchItens?.user?.name?.toLowerCase()
    || searchItens?.email.toLowerCase().includes(searchTerm?.toLowerCase())
    || searchItens?.user?.email?.toLowerCase()
  )), [currentItens, searchTerm]);

  // eslint-disable-next-line max-len
  const filteredItensRemoveDuplicates = useMemo(() => getValuesFromMap.filter((searchItens) => (
    searchItens?.name?.toLowerCase().includes(searchTerm?.toLowerCase())
    || searchItens?.user?.name?.toLowerCase()
    || searchItens?.email.toLowerCase().includes(searchTerm?.toLowerCase())
    || searchItens?.user?.email?.toLowerCase()
  )), [getValuesFromMap, searchTerm]);

  const [isLoading, setIsLoading] = useState(false);

  const copiarTexto = (link) => {
    navigator.clipboard.writeText(`${link}`)
      .then(() => {
        toast.success('Link copiado para a área de transferência!');
      })
      .catch(() => {
        toast.error('Erro ao copiar o link.');
      });
  };

  async function loadItens() {
    try {
      setIsLoading(true);

      const response = await api.get(routeApi);

      setItens(response.data);

      setHasError(false);
    } catch {
      setHasError(true);
      setItens([]);
    } finally {
      setIsLoading(false);
    }
  };

  function handlePage(event) {
    setCurrentPage(Number(event.target.value));
  };

  function handleTryAgain() {
    loadItens();
  };

  useEffect(() => {
    loadItens();

    return () => {};
  }, []);

  return {
    isLoading,
    pages,
    handlePage,
    filteredItensDefault,
    filteredItensRemoveDuplicates,
    hasError,
    itens,
    getValuesFromMap,
    handleTryAgain,
    copiarTexto,
  };
};
