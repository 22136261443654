/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'react-router-dom';
import whatsappLogo from '../../assets/images/whatsapp-logo.svg';

import {
  Container,
} from './styles';

export default function Support() {
  return (
    <Container>
      <div>
        <Link
          to="#"
          onClick={() => window.open('https://contate.me/suporte-systemcraft', '_blank')}
        >
          <img src={whatsappLogo} alt="Suporte Whatsapp" width={64} />
        </Link>
      </div>
    </Container>
  );
};
