import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import {
  Form,
  Container,
  ButtonContainer,
} from './styles';

import useCollaboratorForm from './useCollaboratorForm';

import Button from '../Button';
import FormGroup from '../FormGroup';

const CollaboratorForm = forwardRef(({ edit, onSubmit }, ref) => {
  const {
    type,
    handleType,
    name,
    handleName,
    phone,
    handlePhone,
    email,
    handleEmail,
    address,
    handleAddress,
    password,
    handlePassword,
    isSubmitting,
    handleSubmit,
    handleAdmSelected,
    handleColaboratorSelected,
    handleBack,
    getErrorMessageByFieldName,
    isFormValid,
    office,
    showPassword,
    handleToggle,
    typeInput,
  } = useCollaboratorForm(edit, onSubmit, ref);

  return (
    <Form onSubmit={handleSubmit}>
      <ButtonContainer>
        <section>
          <button
            id="cpf"
            type="button"
            onClick={handleAdmSelected}
            style={{
              background: office === 'ADM' && '#FFB400',
            }}
          >
            ADM
          </button>

          <button
            id="cnpj"
            type="button"
            onClick={handleColaboratorSelected}
            style={{
              background: office === 'Colaborador' && '#FFB400',
            }}
          >
            Colaborador
          </button>
        </section>

        {window.innerWidth > 1240 && (
          <section>
            <Button
              danger
              onClick={handleBack}
            >
              Cancelar
            </Button>

            <Button
              type="submit"
              isLoading={isSubmitting}
              disabled={!isFormValid}
            >
              Confirmar
            </Button>
          </section>
        )}

      </ButtonContainer>

      <Container>
        <div>
          <div>
            <section id="left">
              <div>
                <FormGroup error={getErrorMessageByFieldName('type')}>
                  <span>CPF*</span>
                  <input
                    placeholder="Digite seu CPF"
                    type="text"
                    maxLength={14}
                    value={type}
                    onChange={handleType}
                  />
                </FormGroup>
              </div>
            </section>

            <section>
              <div>
                <FormGroup error={getErrorMessageByFieldName('name')}>
                  <span>Nome*</span>
                  <input
                    placeholder="Digite seu Nome"
                    type="text"
                    value={name}
                    onChange={handleName}
                  />
                </FormGroup>
              </div>
            </section>

          </div>

          <div>
            <section id="left">
              <div>
                <span>Telefone</span>
                <input
                  placeholder="Digite seu Telefone"
                  type="text"
                  maxLength={15}
                  value={phone}
                  onChange={handlePhone}
                />
              </div>

            </section>

            <section>
              <div>
                <span>E-mail</span>
                <input
                  placeholder="Digite seu E-mail"
                  type="text"
                  value={email}
                  onChange={handleEmail}
                />
              </div>
            </section>
          </div>
        </div>

        <div>
          <section id="adress-more">
            <span>Endereço</span>
            <input
              id="adress"
              placeholder="Digite seu Endereço"
              type="text"
              value={address}
              onChange={handleAddress}
            />
          </section>
          <section id="adress-more">
            <span>Senha</span>

            <div className="input-wrapper">
              <input
                id="adress"
                placeholder="Digite sua senha"
                type={typeInput}
                value={password}
                onChange={handlePassword}
              />

              <img
                src={showPassword ? '/show-password.svg' : '/hide-password.svg'}
                onClick={handleToggle}
                alt="Mostrar senha"
                width={20}
              />
            </div>
          </section>
        </div>
      </Container>

      {window.innerWidth < 1240 && (
        <ButtonContainer>
          <section id="mobile">
            <Button
              dangerMobile
              onClick={handleBack}
            >
              Cancelar
            </Button>
          </section>

          <section id="mobile">
            <Button
              type="submit"
              isLoading={isSubmitting}
              disabled={!isFormValid}
            >
              Confirmar
            </Button>
          </section>
        </ButtonContainer>
      )}
    </Form>
  );
});

export default CollaboratorForm;

CollaboratorForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  edit: PropTypes.bool,
};

CollaboratorForm.defaultProps = {
  edit: false,
};
