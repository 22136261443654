import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;

  section {
    display: flex;

    button {
      margin-left: 16px;

      width: 152px;

      border-radius: 12px;

      height: 52px;

      border: none;
      border-radius: 12px;

      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);

      font-size: 16px;
      font-weight: bold;

      color: #ffffff;
    }

    #cpf {
      margin: 0;

      background: ${({ theme }) => theme.colors.main.alert};
    }

    #cnpj {
      background: ${({ theme }) => theme.colors.gray[900]};
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;


  span {
    color: ${({ theme }) => theme.colors.main.alert};
  }

  div {
    display: flex;
    flex-direction: column;

    div {
      display: flex;
      flex-direction: row;

      section {
        display: flex;

        width: 100%;

        div {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          margin-bottom: 16px;
          padding: 8px 0 0 0;

          border-radius: 12px;

          background: ${({ theme }) => theme.colors.main.light};


          div {
            margin: 0;
            padding: 0;
          }

          input {
            border-radius: 12px;

            border: none;
            border: 2px solid #fff;

            outline: none;

            font-size: 16px;

            background: #fff;

            appearance: none;

            &::placeholder {
              color: ${({ theme }) => theme.colors.gray[200]};
            }
          }
        }
      }
    }

    #left {
      margin-right: 16px;
    }
  }

  div {
    display: flex;
    flex-direction: column;

    section {
      display: flex;
      flex-direction: column;

      span {
        margin-left: 18px;

        font-size: 24px;
      }

      input {
        height: 32px;

        margin-bottom: 8px;
        padding: 0 16px;


        border-radius: 12px;
        border: none;
        border: 2px solid #fff;

        outline: none;

        font-size: 16px;

        background: #fff;

        appearance: none;

        &::placeholder {
          color: ${({ theme }) => theme.colors.gray[200]};
        }
    }

    #adress {
      height: 64px;
    }

    #more {
      height: 128px;
    }
    }

    #adress-more {
      margin-bottom: 16px;
      padding: 16px 0 0 0;

      border-radius: 12px;

      background: ${({ theme }) => theme.colors.main.light};
    }
  }
`;
