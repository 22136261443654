import { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { api } from '../../services/apiClient';

import { useAuth } from '../../contexts/AuthProvider/useAuth';
import useSafeAsyncAction from '../../hooks/useSafeAsyncAction';

export default function useEditRequest() {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const { chamada: id } = Object.fromEntries([...searchParams]);

  const auth = useAuth();

  const [isLoading, setIsloading] = useState(true);

  const requestFormRef = useRef(null);

  const safeAsyncAction = useSafeAsyncAction();

  useEffect(() => {
    async function loadRequest() {
      try {
        const response = await api.get(`/request/${id}`);

        safeAsyncAction(() => {
          requestFormRef.current.setFieldsValues(response.data);

          setIsloading(false);
        });
      } catch {
        safeAsyncAction(() => {
          navigate(-1, { replace: true });
        });
      }
    }

    loadRequest();
  }, [id, navigate, safeAsyncAction]);

  async function handleSubmit(request) {
    try {
      await auth.updateRequest({
        id,
        value: request.value,
        cost: request.cost,
        more: request.more,
      });

      navigate(-1, { replace: true });
    } catch (error) {
      console.log(error);
    }
  };

  return {
    isLoading,
    requestFormRef,
    handleSubmit,
  };
};
