import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { toast } from 'react-toastify';

import { api } from '../../services/apiClient';

import useSafeAsyncAction from '../../hooks/useSafeAsyncAction';

export default function useEditProduct() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsloading] = useState(true);

  const productFormRef = useRef(null);

  const safeAsyncAction = useSafeAsyncAction();

  useEffect(() => {
    async function loadProduct() {
      try {
        const response = await api.get(`product/${id}`);

        safeAsyncAction(() => {
          productFormRef.current.setFieldsValues(response.data);

          setIsloading(false);
        });
      } catch {
        safeAsyncAction(() => {
          navigate('/produtos', { replace: true });
        });
      }
    };

    loadProduct();
  }, []);

  async function handleSubmit(product) {
    try {
      await api.put(`/products/${id}`, {
        name: product.name,
        value: product.value,
        cost: product.cost,
        quantity: product.quantity,
      });

      navigate('/produtos', { replace: true });

      toast.success('Produto editado com sucesso!');
    } catch {
      toast.error('Erro ao editar o produto!');
    }
  };

  return {
    handleSubmit,
    isLoading,
    productFormRef,
  };
};
