export function formatNumber(input) {
  const number = parseInt(input.replace(/\D/g, ''), 10);

  // eslint-disable-next-line no-restricted-globals
  if (isNaN(number)) {
    return '';
  }
  if (number > 9999) {
    return '';
  }
  return `#${number.toString().padStart(4, '0')}`;
}
