import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import {
  Form,
  ButtonContainer,
  Container,
} from './styles';

import useProductForm from './useProductForm';

import FormGroup from '../FormGroup';

import Button from '../Button';

const ProductForm = forwardRef(({ onSubmit }, ref) => {
  const {
    name,
    handleName,
    value,
    handleValue,
    cost,
    handleCost,
    quantity,
    handleQuantity,
    handleBack,
    getErrorMessageByFieldName,
    isFormValid,
    isSubmitting,
    handleSubmit,
  } = useProductForm(onSubmit, ref);

  return (
    <Form onSubmit={handleSubmit}>
      {window.innerWidth > 1240 && (
        <ButtonContainer>
          <Button
            danger
            onClick={handleBack}
          >
            Cancelar
          </Button>

          <Button
            type="submit"
            isLoading={isSubmitting}
            disabled={!isFormValid}
          >
            Confirmar
          </Button>
        </ButtonContainer>
      )}

      <Container>
        <div>
          <div>
            <section id="left">
              <div>
                <FormGroup error={getErrorMessageByFieldName('name')}>
                  <span>Nome</span>
                  <input
                    placeholder="Digite o nome do produto"
                    type="text"
                    value={name}
                    onChange={handleName}
                  />
                </FormGroup>

              </div>
            </section>

            <section>
              <div>
                <FormGroup>
                  <span>Quantidade</span>
                  <input
                    placeholder="Digite a quantidade"
                    type="number"
                    value={quantity}
                    onChange={handleQuantity}
                  />
                </FormGroup>
              </div>
            </section>
          </div>

          <div>
            <section id="left">
              <div>
                <span>Valor</span>
                <input
                  placeholder="Digite o valor"
                  type="number"
                  value={value}
                  onChange={handleValue}
                />
              </div>

            </section>

            <section>
              <div>
                <span>Custo</span>
                <input
                  placeholder="Digite o custo"
                  type="number"
                  value={cost}
                  onChange={handleCost}
                />
              </div>
            </section>
          </div>
        </div>
      </Container>

      {window.innerWidth < 1240 && (
        <ButtonContainer>
          <section id="mobile">
            <Button
              dangerMobile
              onClick={handleBack}
            >
              Cancelar
            </Button>
          </section>

          <section id="mobile">
            <Button
              type="submit"
              isLoading={isSubmitting}
              disabled={!isFormValid}
            >
              Confirmar
            </Button>
          </section>
        </ButtonContainer>
      )}
    </Form>
  );
});

export default ProductForm;

ProductForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};
