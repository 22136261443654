import styled from 'styled-components';

export const Container = styled.div`
  padding: 64px;
  margin-left: 25%;

  #search-item {
    margin-bottom: 16px;
  }

  .main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    #top {
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      margin-bottom: 16px;
      padding: 16px;

      width: 100%;

      border-radius: 12px;

      background: ${({ theme }) => theme.colors.main.light};

      div {
        h1 {
          font-size: 16px;
        }

        p {
          font-size: 12px;
        }
      }
    }

    #header-table {
      width: 100%;
    }
  }

  @media screen and (max-width: 1240px) {
    padding: 16px;
    margin-left: 0;

    .main {
      width: 100%;
    }
  }
`;
