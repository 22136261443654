import {
  Container,
} from './styles';

import Header from '../../components/Header';
import CollaboratorForm from '../../components/CollaboratorForm';

import useEditCollaborator from './useEditCollaborator';

export default function EditCollaborator() {
  const {
    handleSubmit,
    collaboratorFormRef,
  } = useEditCollaborator();

  return (
    <Container>
      <Header />

      <div className="main">
        <CollaboratorForm edit onSubmit={handleSubmit} ref={collaboratorFormRef} />
      </div>
    </Container>
  );
};
