import { PDFDownloadLink } from '@react-pdf/renderer';

import {
  Container,
} from './styles';

import useShowRequest from './useShowRequest';

import handleDate from '../../utils/handleDate';

import Header from '../../components/Header';
import ClientInformations from '../../components/ClientInformations';

import ProductsTable from './ProductsTable';
import OrderPDF from '../../services/PDF/Order';

export default function ShowRequest() {
  const {
    client,
    request,
    products,
    total,
  } = useShowRequest();

  // eslint-disable-next-line consistent-return
  function requestNumber(number) {
    if (number < 10) {
      return `#000${number}`;
    }

    if (number >= 10 && number < 100) {
      return `#00${number}`;
    }

    if (number >= 100 < 1000) {
      return `#0${number}`;
    }

    if (number >= 1000 < 10000) {
      return `#0${number}`;
    };
  };

  return (
    <>
      <Header />

      <Container>
        <div className="main">
          <div className="header">
            <section id="top">
              <ClientInformations
                name={client?.name}
                type={client?.type}
                phone={client?.phone}
                email={client?.email}
                address={client?.address}
              />

              <section>
                <span>Pedido {requestNumber(request?.request_number)}</span>
                <span>Realizado em {handleDate(request?.request_created_at)}</span>
              </section>
            </section>

            <section id="table">
              <ProductsTable products={products} total={total} />
            </section>

            <span id="total">
              <p>Total:</p> {new Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(
                total,
              )}
            </span>

            {request.more && (
              <section id="footer">
                <span>Mais informações</span>

                <p>{request.request_more}</p>
              </section>

            )}
            <section id="bottom">
              <p>Systemcraft - 2023. Saiba mais em <a href="https://systemcraft.com.br/">www.systemcraft.com.br</a></p>

              <PDFDownloadLink
                document={(
                  <OrderPDF
                    client={client}
                    requestNum={requestNumber(request?.request_number)}
                    requestDate={handleDate(request?.created_at)}
                    products={products}
                    total={total}
                  />
                )}
                fileName={
                  `pedido${requestNumber(request?.request_number)}-${handleDate(request?.created_at)}`
                }
              >
                {({ loading }) => (
                  loading ? (
                    <>
                      <p>Carregando...</p>
                    </>
                  ) : (
                    <>
                      <p>
                        <img src="/printer.svg" alt="Imprimir pedido" width={18} />
                      </p>
                    </>
                  )
                )}
              </PDFDownloadLink>
            </section>
          </div>
        </div>
      </Container>
    </>
  );
};
