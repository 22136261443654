/* eslint-disable import/no-absolute-path */
/* eslint-disable import/no-unresolved */
import { useImperativeHandle, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import useErrors from '../../hooks/useErrors';

import isTypeValid from '../../utils/isTypeValid';
import { formatPhone } from '../../utils/isPhoneValid';

export default function useCollaboratorForm(edit, onSubmit, ref) {
  const [searchParams] = useSearchParams();
  const { office: officeType } = Object.fromEntries([...searchParams]);

  const navigate = useNavigate();

  const [typeInput, setTypeInput] = useState('password');
  const [showPassword, setShowPassword] = useState(false);

  const [type, setType] = useState('');
  const [name, setName] = useState('');
  const [office, setOffice] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [password, setPassword] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    setError,
    removeError,
    getErrorMessageByFieldName,
    errors,
  } = useErrors();

  const passVerification = password.length >= 6;

  const isFormValid = edit ? (true && errors.length === 0 || password === '')
    : (type && name && office !== '' && passVerification && errors.length === 0);

  useImperativeHandle(ref, () => ({
    setFieldsValues: (colaborador) => {
      setType(isTypeValid(colaborador.type ?? ''));
      setName(colaborador.name ?? '');
      setPhone(formatPhone(colaborador.phone ?? ''));
      setEmail(colaborador.email ?? '');
      setAddress(colaborador.address ?? '');
      setPassword('');
      setOffice(officeType);
    },

    resetFields: () => {
      setType('');
      setName('');
      setPhone('');
      setEmail('');
      setAddress('');
      setPassword('');
    },
  }), []);

  function handleToggle() {
    setShowPassword(!showPassword);
    setTypeInput(showPassword ? 'password' : 'text');
  }

  function handleType(event) {
    setType(isTypeValid(event.target.value));

    if (!event.target.value) {
      setError({ field: 'type', message: 'CPF é obrigatório' });
    } else {
      removeError('type');
    };
  };

  function handleName(event) {
    setName(event.target.value);

    if (!event.target.value) {
      setError({ field: 'name', message: 'Nome é obrigatório' });
    } else {
      removeError('name');
    };
  };

  function handlePhone(event) {
    setPhone(formatPhone(event.target.value));
  };

  function handleEmail(event) {
    setEmail(event.target.value);
  };

  function handleAddress(event) {
    setAddress(event.target.value);
  };

  function handlePassword(event) {
    setPassword(event.target.value);

    if (event.target.value.length < 6) {
      setError({ field: 'password', message: 'Sua senha precisa ter 6 caractéres ou mais!' });
    } else {
      removeError('password');
    };
  };

  function handleAdmSelected() {
    setOffice('ADM');
  };

  function handleColaboratorSelected() {
    setOffice('Colaborador');
  };

  function handleBack() {
    navigate(-1, { replace: true });
  };

  async function handleSubmit(event) {
    event.preventDefault();

    setIsSubmitting(true);

    await onSubmit({
      type,
      name,
      office,
      phone,
      email,
      address,
      password,
    });

    navigate('/equipe', { replace: false });

    setIsSubmitting(false);
  };

  return {
    type,
    handleType,
    name,
    handleName,
    phone,
    handlePhone,
    email,
    handleEmail,
    address,
    handleAddress,
    password,
    handlePassword,
    isSubmitting,
    handleSubmit,
    handleAdmSelected,
    handleColaboratorSelected,
    handleBack,
    getErrorMessageByFieldName,
    errors,
    isFormValid,
    office,
    showPassword,
    handleToggle,
    typeInput,
  };
};
