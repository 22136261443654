import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  position: fixed;
  bottom: 5%;
  right: 3%;
  z-index: 1;

  /* margin-right: 5%; */

  img {
    cursor: pointer;

    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);

    transition: ease-in 0.3s;

    border-radius: 50%;

    &:hover {
      transform: scale(1.2);
    }
  }

  @media screen and (max-width: 1080px) {
    bottom: 1px;
  }

  @media screen and (max-width: 767px) {
    img {
      width: 32px;

      margin-bottom: 8px;
    }
  }
`;
