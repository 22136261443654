import styled from 'styled-components';

export const Container = styled.div`
  .main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    margin-left: 25%;
    padding: 64px;
  }
`;
