import {
  Container,
} from './styles';

import SignInForm from '../../components/SignInForm';
import Info from '../../components/Info';

import useSignInCollaborator from '../SignInCollaborator/useSignInCollaborator';

export default function SignInAdm() {
  const {
    handleSubmit,
    signInCollaboratorRef,
  } = useSignInCollaborator();

  return (
    <Container>
      <Info />

      <SignInForm noUsername buttonLabel="Entrar" onSubmit={handleSubmit} collaborator ref={signInCollaboratorRef} />
    </Container>
  );
};
