import {
  Container,
} from './styles';

import useOrderHistory from './useOrderHistory';

import Header from '../../components/Header';

import ClientInformations from '../../components/ClientInformations';
import SearchForm from './components/SearchForm';
import RequestTable from './components/RequestTable';

import Modal from '../../components/Modal';
import HeaderTable from './components/HeaderTable';
import EmptyList from '../../components/ItensList/components/EmptyList';
import LoaderComponent from '../../components/LoaderComponent';

export default function OrderHistory() {
  const {
    client,
    pages,
    handlePage,
    handleCloseDeleteModal,
    handleConfirmDeleteRequest,
    handleDeleteRequest,
    isDeleteModalVisible,
    isLoadingDelete,
    handleFinishRequest,
    handleConfirmFinishRequest,
    isFinishModalVisible,
    isLoadingFinish,
    handleCloseFinishModal,
    handleChangeSearchTerm,
    searchTerm,
    handleKeyDown,
    filteredRequests,
    handleStatusPayament,
    statusPayament,
    handlePayment,
    isLoading,
    isSettingsModalVisible,
    handleSettings,
    handleCloseSettings,
    requestSettings,
  } = useOrderHistory();

  return (
    <>
      <Header />

      <Container>
        <div id="search-item">
          <SearchForm
            onChange={handleChangeSearchTerm}
            value={searchTerm}
            onKeyDown={handleKeyDown}
          />
        </div>

        <div className="main">
          <section id="top">
            <ClientInformations
              name={client?.name}
              type={client?.type}
              phone={client?.phone}
              email={client?.email}
              address={client?.address}
            />
          </section>

          <section id="header-table">
            <HeaderTable />
          </section>

          {isLoading
            ? (
              <LoaderComponent isLoading={isLoading} />
            )
            : filteredRequests.length >= 1
              ? (
                <section id="table">
                  <RequestTable
                    info={filteredRequests}
                    requests={filteredRequests}
                    pages={pages}
                    handlePage={handlePage}
                    openModalDelete={handleDeleteRequest}
                    onFinishRequest={handleFinishRequest}
                    payamentStatus={statusPayament}
                    handlePayamentStatus={handleStatusPayament}
                    handlePayment={handlePayment}
                    openModalSettings={handleSettings}
                    isSettingsModalVisible={isSettingsModalVisible}
                    handleCloseSettings={handleCloseSettings}
                    requestSettings={requestSettings}
                  />
                </section>
              )
              : (
                <EmptyList>
                  <p>Esse cliente ainda não possui nenhum pedido!</p>
                </EmptyList>
              )}
        </div>

        <Modal
          danger
          visible={isDeleteModalVisible}
          onCancel={handleCloseDeleteModal}
          onConfirm={handleConfirmDeleteRequest}
          title="Tem certeza que deseja remover esse pedido?"
          isLoading={isLoadingDelete}
          confirmLabel="Deletar"
        >
          <p>Esta ação não poderá ser desfeita!</p>
        </Modal>

        <Modal
          visible={isFinishModalVisible}
          onCancel={handleCloseFinishModal}
          onConfirm={handleConfirmFinishRequest}
          title="Tem certeza que deseja finalizar esse pedido?"
          isLoading={isLoadingFinish}
          confirmLabel="Finalizar"
        >
          <p>Esta ação não poderá ser desfeita!</p>
        </Modal>
      </Container>
    </>
  );
}
