/* eslint-disable react/no-unknown-property */
import buttonLeft from '../../assets/images/icons/button-left.svg';
import buttonRight from '../../assets/images/icons/button-right.svg';
import arrowBottom from '../../assets/images/icons/arrow-bottom.svg';

import {
  Container,
  Main,
} from './styles';

import useDashboard from './useDashboard';

import Header from '../../components/Header';
import Chart from '../../components/Chart';
import Modal from '../../components/Modal';

export default function Dashboard() {
  const {
    getValuesFromMap: requests,
    data,
    typeUser,
    images,
    currentSlide,
    totalReceive,
    totalExpansive,
    backMonth,
    nextMonth,
    selectedMonth,
    allCostByMonth,
    allReceiveBYMonth,
    registersMonth,
    selectedYear,
    componentPDF,
    generatePDF,
    handleAddRevenueRequest,
    handleCloseAddRevenueModal,
    handleCofirmAddRevenueRequest,
    isLoadingAddRevenue,
    isAddRevenueModalVisible,
    handleAddCostRequest,
    handleCloseAddCostModal,
    handleCofirmAddCostRequest,
    isAddCostModalVisible,
    isLoadingAddCost,
    origin,
    handleChangeOrigin,
    values,
    handleChangeValue,
    combinedArray,
    handleConfirmRemoveItemRequest,
    handleRemoveItemRequest,
    isLoadingRemoveItem,
    isRemoveItemVisible,
    handleCloseRemoveItemModal,
  } = useDashboard();

  const isColaborator = typeUser?.office === 'Colaborador';

  return (
    <>
      <Header />
      <Container isColaborator={isColaborator} ref={componentPDF}>

        <Main isColaborator={isColaborator} className="main">

          {(!isColaborator && (typeUser?.office === 'ADM-MASTER' || typeUser?.office === 'ADM')) && (
            <>
              <div id="header">
                <section id="graffic">
                  <Chart data={data} year={selectedYear} />
                </section>

                <section id="data">
                  <div>
                    <span>Lucro Líquido</span>
                    <p>
                      {new Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(totalReceive - totalExpansive)}
                    </p>
                  </div>

                  <div>
                    <span>Despesas</span>
                    <p>
                      {new Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(totalExpansive)}
                    </p>
                  </div>

                  <div>
                    <span>Receita</span>
                    <p>
                      {new Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(totalReceive)}
                    </p>
                  </div>

                </section>
              </div>

              <div id="buttons-add-cost-and-revenue">
                <section>
                  <button
                    type="button"
                    onClick={handleAddRevenueRequest}
                  >Add Receita
                  </button>
                </section>

                <section id="right">
                  <button
                    id="right-button"
                    type="button"
                    onClick={handleAddCostRequest}
                  >Add Despesa
                  </button>
                </section>
              </div>

              <div id="middle">
                <section id="right">
                  <h1>Lucro Líquido</h1>
                  <p id="invoicing">
                    {new Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(allReceiveBYMonth - allCostByMonth)}
                  </p>
                </section>

                <section>
                  <h1>Despesas</h1>
                  <p id="expenses">
                    {new Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(allCostByMonth)}
                  </p>
                </section>

                <section>
                  <h1>Receita</h1>
                  <p id="receive">
                    {new Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(allReceiveBYMonth)}
                  </p>
                </section>
              </div>
            </>
          )}

          {isColaborator && (
            <>
              <div id="slides">
                <img src={images[currentSlide]} alt="banners" />
              </div>

              <div id="buttons-add-cost-and-revenue">
                <section>
                  <button
                    type="button"
                    onClick={handleAddRevenueRequest}
                  >Add Receita
                  </button>
                </section>

                <section id="right">
                  <button
                    id="right-button"
                    type="button"
                    onClick={handleAddCostRequest}
                  >Add Despesa
                  </button>
                </section>
              </div>
            </>
          )}

          <div id="footer">
            <section id="block">
              <h1>Cadastros</h1>
              <p>{registersMonth.length}</p>
            </section>

            <section id="block">
              <h1>Clientes ativos</h1>
              <p>{requests.length}</p>
            </section>

            <section id="month-report">
              <div id="date">
                <div className="months">
                  <img onClick={backMonth} src={buttonLeft} alt="" width={24} />

                  <h1>{selectedMonth?.name}</h1>

                  <img onClick={nextMonth} src={buttonRight} alt="" width={24} />
                </div>
              </div>

              <div id="report" onClick={generatePDF}>
                <div id="report-div">
                  <img src={arrowBottom} alt="Download relatório do mês" />

                  <h1>Baixar relatório</h1>
                </div>
              </div>
            </section>
          </div>

          <div id="report-area">
            <h1>Relatório de despesas e entradas adicionais</h1>

            {combinedArray.length < 1 ? (
              <span>Você não possui despesas ou entradas adicionais neste mês.</span>
            ) : (
              <>
                {combinedArray.map((item) => (
                  <span
                    id="itens"
                    style={{
                      color: item.type === 'Despesas' ? '#ff0000' : '#43c928',
                    }}
                    onClick={() => handleRemoveItemRequest(item)}
                  >
                    {item.description} - {new Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(item.value)}
                  </span>
                ))}
              </>
            )}
          </div>

          <Modal
            alert
            visible={isAddRevenueModalVisible}
            onCancel={handleCloseAddRevenueModal}
            onConfirm={handleCofirmAddRevenueRequest}
            title="Qual valor e origem da receita?"
            isLoading={isLoadingAddRevenue}
            confirmLabel="Confirmar"
          >
            <input
              placeholder="Digite aqui o valor"
              type="number"
              value={values}
              onChange={handleChangeValue}
              min={0}
            />

            <input
              id="input-bottom"
              type="text"
              placeholder="Digite aqui a origem"
              value={origin}
              onChange={handleChangeOrigin}
            />
          </Modal>

          <Modal
            alert
            visible={isAddCostModalVisible}
            onCancel={handleCloseAddCostModal}
            onConfirm={handleCofirmAddCostRequest}
            title="Qual valor e origem da despesa?"
            isLoading={isLoadingAddCost}
            confirmLabel="Confirmar"
          >
            <input
              type="number"
              placeholder="Digite aqui o valor"
              value={values}
              onChange={handleChangeValue}
              min={0}
            />

            <input
              id="input-bottom"
              type="text"
              placeholder="Digite aqui a origem"
              value={origin}
              onChange={handleChangeOrigin}
            />
          </Modal>

          <Modal
            danger
            title="Tem certeza que deseja remover este item?"
            visible={isRemoveItemVisible}
            onCancel={handleCloseRemoveItemModal}
            onConfirm={handleConfirmRemoveItemRequest}
            isLoading={isLoadingRemoveItem}
          >
            <p>Esta ação não poderá ser desfeita!</p>
          </Modal>
        </Main>
      </Container>
    </>
  );
};
