import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  section {
    display: flex;
    align-items: center;
    justify-content: start;

    max-width: 60%;

    p {
      margin-left: 1.2rem;
      margin-top: 10px;

      font-size: 18px;
      font-weight: 700;

      span {
        color: ${({ theme }) => theme.colors.main.alert};
      }
    }
  }

  @media screen and (max-width: 767px) {
    section {
      max-width: 100%;

      img {
        width: 300px;
      }

      p {
        margin-bottom: 16px;
      }
    }
  }
`;
