import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  @font-face {
    font-family: 'Gotham Medium';
    src: url('/fonts/Gotham-Medium.otf') format('truetype');
  }

  @font-face {
    font-family: 'Gotham Light';
    src: url('/fonts/Gotham-Light.otf') format('truetype');
  }

  * {
    margin: 0;
    padding: 0;

    box-sizing: border-box;

    font-family: 'Gotham Medium', 'Gotham Light', sans-serif;
  }

  body {
    font-size: 16px;

    color: ${({ theme }) => theme.colors.main.black};
    background: ${({ theme }) => theme.colors.background};
  }

  button {
    cursor: pointer;
  }

  a {
    text-decoration: none;
  }

  p {
    font-family: 'Gotham Light';
    font-weight: 500;
  }

  td {
    font-family: 'Gotham Light';
  }

  select {
    font-family: 'Gotham Light';
  }

  input {
    font-family: 'Gotham Light';
  }

  #light {
    input {
      font-family: 'Gotham Light';
    }
  }
`;
