import {
  Container,
} from './styles';

import useNewRecord from './useNewRecord';

import Header from '../../components/Header';
import NewRecordForm from '../../components/RecordForm';

export default function NewRecord() {
  const { handleSubmit, clientFormRef } = useNewRecord();

  return (
    <Container>
      <Header />

      <div className="main">
        <NewRecordForm
          onSubmit={handleSubmit}
          ref={clientFormRef}
        />
      </div>

    </Container>
  );
};
