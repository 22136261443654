import {
  useState,
  useImperativeHandle,
} from 'react';
import { useNavigate } from 'react-router-dom';

import useErrors from '../../hooks/useErrors';

import showPassword from '../../assets/images/icons/show-password.svg';
import hidePassword from '../../assets/images/icons/hide-password.svg';

import isEmailValid from '../../utils/isEmailValid';
import isTypeValid from '../../utils/isTypeValid';

export default function useSignUpForm(onSubmit, ref) {
  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [typeInput, setTypeInput] = useState('password');
  const [icon, setIcon] = useState(hidePassword);

  const {
    setError,
    removeError,
    getErrorMessageByFieldName,
    errors,
  } = useErrors();

  const passValid = password.length >= 6;

  const isFormValid = (name && email && passValid && type && errors.length === 0);

  useImperativeHandle(ref, () => ({
    setFieldsValues: (adm) => {
      setName(adm.name ?? '');
      setType(adm.type ?? '');
      setEmail(adm.email ?? '');
      setPassword(adm.password ?? '');
    },

    resetFields: () => {
      setName('');
      setType('');
      setEmail('');
      setPassword('');
    },
  }), []);

  function handleToggle() {
    if (typeInput === 'password') {
      setIcon(showPassword);
      setTypeInput('text');
    } else {
      setIcon(hidePassword);
      setTypeInput('password');
    };
  };

  function handleName(event) {
    setName(event.target.value);

    if (!event.target.value) {
      setError({ field: 'name', message: 'Esse campo é obrigatório' });
    } else {
      removeError('name');
    };
  };

  function handleType(event) {
    setType(isTypeValid(event.target.value));

    if (!event.target.value) {
      setError({ field: 'type', message: 'Esse campo é obrigatório' });
    } else {
      removeError('type');
    };
  };

  function handleEmail(event) {
    setEmail(event.target.value);

    if (event.target.value && !isEmailValid(event.target.value)) {
      setError({ field: 'email', message: 'E-mail invalido' });
    } else {
      removeError('email');
    }
  };

  function handlePassword(event) {
    setPassword(event.target.value);

    if (!event.target.value) {
      setError({ field: 'password', message: 'Esse campo é obrigatório' });
    } else {
      removeError('password');
    };
  };

  async function handleSubmit(event) {
    try {
      event.preventDefault();

      setIsSubmitting(true);

      await onSubmit({
        name,
        type,
        email,
        password,
      });

      setIsSubmitting(false);
    } catch (error) {
      console.log(error);
    }
  };

  return {
    name,
    handleName,
    type,
    handleType,
    email,
    handleEmail,
    password,
    handlePassword,
    isSubmitting,
    handleSubmit,
    getErrorMessageByFieldName,
    errors,
    isFormValid,
    handleToggle,
    typeInput,
    icon,
  };
};
