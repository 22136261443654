import {
  Container,
} from './styles';

import Info from '../../components/Info';

import ConfirmAccountForm from '../../components/ConfirmAccountForm';

import { useConfirmAccount } from './useConfirmAccount';

export default function ConfirmAccount() {
  const {
    handleSubmit,
  } = useConfirmAccount();

  return (
    <Container>
      <Info width={401} />

      <ConfirmAccountForm
        buttonLabel="Confirmar"
        onSubmit={handleSubmit}
      />
    </Container>
  );
}
