import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { api } from '../../services/apiClient';

export default function useShowRequest() {
  const [searchParams] = useSearchParams();
  const { chamada: id, cliente: clientIdParams } = Object.fromEntries([...searchParams]);

  const [client, setClient] = useState({});
  const [request, setRequest] = useState({});

  const [products, setProducts] = useState([]);
  const [total, setTotal] = useState(0);

  async function loadClient() {
    const response = await api.get(`/client/${clientIdParams}`);

    setClient(response.data);
  }

  async function loadRequest() {
    const response = await api.get(`/request/${id}`);

    setRequest(response.data);
  }

  async function loadProducts() {
    const response = await api.get(`/product-called/${id}`);

    const totalValueProducts = response.data?.map((item) => {
      const totalValue = item.quantity * Number(item.value);

      if (item.quantity === 0) {
        return Number(item.value);
      };

      return totalValue;
    });

    let sumValue = 0;
    for (let i = 0; i < totalValueProducts.length; i++) {
      sumValue += totalValueProducts[i];
    }

    setProducts(response.data);
    setTotal(sumValue);
  }

  useEffect(() => {
    loadClient();

    loadRequest();

    loadProducts();
  }, []);

  return {
    client,
    request,
    products,
    total,
  };
};
