import PropTypes from 'prop-types';

import {
  Form,
} from './styles';

import useConfirmAccountForm from './useConfirmAccountForm';

import Button from '../Button';

export default function ConfirmAccountForm({ buttonLabel, onSubmit }) {
  const {
    handleSubmit,
    isSubmitting,
    isFormValid,
  } = useConfirmAccountForm(onSubmit);

  return (
    <Form onSubmit={handleSubmit}>
      <div>
        <span>Clique no botão a baixo para confirmar sua conta.</span>

        <p>Ao clicar no botão a baixo você terá acesso a sua conta.</p>

        {!isFormValid && (
          <p id="error">Você não tem autorização para confirmar sua conta.</p>
        )}
      </div>

      <div>
        <Button
          type="submit"
          isLoading={isSubmitting}
          disabled={!isFormValid}
        >
          {buttonLabel}
        </Button>
      </div>
    </Form>
  );
};

ConfirmAccountForm.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
