/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import {
  Page, Text, Image, Document, View,
} from '@react-pdf/renderer';

import styles from './styles';

export default function OrderPDF({
  client,
  requestNum,
  requestDate,
  products,
  total,
}) {
  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.header}>
          <Image
            style={{
              width: '180px',
              height: 'auto',
              marginLeft: 0,
            }}
            src="/logo.png"
          />

          <View style={styles.orderInfo}>
            <Text style={styles.orderNumber}>Pedido {requestNum}</Text>
            <Text style={styles.date}>Realizado em {requestDate}</Text>
          </View>
        </View>

        <View style={styles.clientInformations}>
          <Text style={styles.h1}>{client?.name}</Text>

          <Text style={styles.dataClient}>{client?.type}</Text>
          <Text style={styles.dataClient}>{client?.phone}</Text>
          <Text style={styles.dataClient}>{client?.email}</Text>
          <Text style={styles.dataClient}>{client?.address}</Text>
        </View>

        <View style={styles.headerTable}>
          <Text>Item</Text>
          <Text>Quantidade</Text>
          <Text>Valor</Text>
        </View>

        {products.map((item) => (
          <View style={styles.productInformations}>
            <Text>{item?.name}</Text>
            <Text>{item?.quantity}</Text>
            <Text>{new Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(item?.value)}</Text>
          </View>
        ))}

        <View style={styles.totalOrderValue}>
          <Text style={styles.titleTotalOrderValue}>Total do pedido</Text>
          <Text style={styles.totalOrderValuePrice}>{new Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(
            total,
          )}
          </Text>

          <Text style={styles.footer}>Aproveite o máximo dos nossos relatórios para
            melhorar ainda mais a gestão do seu negócio.
          </Text>
          <Text style={styles.footer}>
            Ajude a melhorar a sua experiência com um feedback sobre o
            sistema em nosso suporte!
          </Text>
          <Text style={styles.footer}>www.systemcraft.com.br</Text>

          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
          />
        </View>
      </Page>
    </Document>
  );
}
