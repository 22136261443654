import { Navigate, Route, Routes as Router } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';

import CheckOut from '../pages/CheckOut';

import SignUp from '../pages/SignUp';
import SignIn from '../pages/SignIn';

import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import ConfirmAccount from '../pages/ConfirmAccount';

import Products from '../pages/Products';
import NewProduct from '../pages/NewProduct';
import EditProduct from '../pages/EditProduct';

import Records from '../pages/Records';
import NewRecord from '../pages/NewRecord';
import EditRecord from '../pages/EditRecord';

import Requests from '../pages/Requests';
import NewRequest from '../pages/NewRequest';
import EditRequest from '../pages/EditRequest';
import OrderHistory from '../pages/OrderHistory';
import ShowRequest from '../pages/ShowRequest';

import MyAccount from '../pages/MyAccount';
import EditMyAccount from '../pages/EditMyAccount';
import Team from '../pages/Team';
import NewCollaborator from '../pages/NewCollaborator';
import EditCollaborator from '../pages/EditCollaborator';

import NotFound from '../pages/NotFound';

import SignInCollaborator from '../pages/SignInCollaborator';
import SignInAdm from '../pages/SignInAdm';

import { useAuth } from '../contexts/AuthProvider/useAuth';

import { DashboardWithDefaultQueryParam } from './useDashboardWithDefaultQueryParams';

export default function Routes() {
  const { tokenAccess } = useAuth();

  return (
    <Router>
      <Route
        path="/cadastrar"
        element={tokenAccess ? <Navigate to="/dashboard" replace /> : <SignUp />}
      />
      <Route path="/" element={tokenAccess ? <Navigate to="/dashboard" replace /> : <SignIn />} />

      <Route path="/esqueceu-a-senha" element={<ForgotPassword />} />
      <Route path="/resetar-senha" element={<ResetPassword />} />
      <Route path="/confirmar-conta" element={<ConfirmAccount />} />

      <Route path="/colaborador" element={<SignInCollaborator />} />
      <Route path="/adm" element={<SignInAdm />} />

      <Route path="/assinatura" element={<CheckOut />} />

      <Route
        path="/dashboard"
        element={<DashboardWithDefaultQueryParam />}
      />

      <Route
        path="/produtos"
        element={(
          <PrivateRoute isPrivate element={<Products />} />
        )}
      />

      <Route
        path="/produto/:id"
        element={(
          <PrivateRoute isPrivate element={<EditProduct />} />
        )}
      />

      <Route
        path="/novo-produto"
        element={(
          <PrivateRoute isPrivate element={<NewProduct />} />
        )}
      />

      <Route
        path="/cadastros"
        element={(
          <PrivateRoute isPrivate element={<Records />} />
        )}
      />

      <Route
        path="/novo-cadastro"
        element={(
          <PrivateRoute isPrivate element={<NewRecord />} />
        )}
      />

      <Route
        path="/cliente/:id"
        element={(
          <PrivateRoute isPrivate element={<EditRecord />} />
        )}
      />

      <Route
        path="/clientes-ativos"
        element={(
          <PrivateRoute isPrivate element={<Requests />} />
        )}
      />

      <Route
        path="/novo-pedido"
        element={(
          <PrivateRoute isPrivate element={<NewRequest />} />
        )}
      />

      <Route
        path="/pedido/:id"
        element={(
          <PrivateRoute isPrivate element={<EditRequest />} />
        )}
      />

      <Route
        path="/observar-pedido/:id"
        element={(
          <PrivateRoute isPrivate element={<ShowRequest />} />
        )}
      />

      <Route
        path="/histórico-de-pedidos"
        element={(
          <PrivateRoute isPrivate element={<OrderHistory />} />
        )}
      />

      <Route
        path="/minha-conta"
        element={(
          <PrivateRoute isPrivate element={<MyAccount />} />
        )}
      />

      <Route
        path="/minha-conta/:id"
        element={(
          <PrivateRoute isPrivate element={<EditMyAccount />} />
        )}
      />

      <Route
        path="/equipe"
        element={(
          <PrivateRoute isPrivate element={<Team />} />
        )}
      />

      <Route
        path="/novo-colaborador"
        element={(
          <PrivateRoute isPrivate element={<NewCollaborator />} />
        )}
      />

      <Route
        path="/colaborador/:id"
        element={(
          <PrivateRoute isPrivate element={<EditCollaborator />} />
        )}
      />

      <Route path="*" element={<NotFound />} />

    </Router>
  );
}
