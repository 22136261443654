import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { api } from '../../services/apiClient';

import useSafeAsyncAction from '../../hooks/useSafeAsyncAction';
import toast from '../../utils/toast';

export default function useEditCollaborator() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsloading] = useState(true);

  const collaboratorFormRef = useRef(null);

  const safeAsyncAction = useSafeAsyncAction();

  useEffect(() => {
    async function loadCollaborator() {
      try {
        const response = await api.get(`/collaborator/${id}`);

        safeAsyncAction(() => {
          collaboratorFormRef.current.setFieldsValues(response.data);

          setIsloading(false);
        });
      } catch {
        safeAsyncAction(() => {
          navigate('/equipe', { replace: true });
        });
      }
    };

    loadCollaborator();
  }, [id, navigate, safeAsyncAction]);

  async function handleSubmit(collaborator) {
    try {
      await api.put(`/collaborator/${id}`, {
        name: collaborator.name,
        office: collaborator.office,
        type: collaborator.type,
        phone: collaborator.phone,
        email: collaborator.email,
        address: collaborator.address,
        password: collaborator.password,
      });

      navigate('/equipe', { replace: true });

      toast({
        type: 'success',
        text: 'Colaborator editado com sucesso!',
      });
    } catch {
      toast({
        type: 'danger',
        text: 'Ocorreu um erro ao editar o colaborador!',
      });
    }
  };

  return {
    handleSubmit,
    isLoading,
    collaboratorFormRef,
  };
};
