import { useCallback, useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { api } from '../../services/apiClient';

export default function useHeader() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const {
    dash,
    records,
    requests,
    products,
    myaccount,
    team,
    newrequest,
  } = Object.fromEntries([...searchParams]);

  const [historicModal, setHistoricModal] = useState(false);
  const [out, setOut] = useState(false);

  const [userType, setUserType] = useState({});

  const [menu, setMenu] = useState(false);

  function handleResize() {
    if (window.innerWidth > 1240 && !menu) {
      setMenu(true);
    };

    if (window.innerWidth < 1240 && menu) {
      setMenu(false);
    };
  };

  function toggleMenu() {
    setMenu(!menu);
  };

  const createCalled = useCallback(async () => {
    const { data } = await api.post('/create-called');

    navigate(`/novo-pedido?chamada=${data.id}&newrequest=true`);
  }, [navigate]);

  function loadStorageUser() {
    const userStorage = localStorage.getItem('@Auth:user');

    const user = JSON.parse(userStorage);

    setUserType(user);
  }

  function handleHistoricModal() {
    setHistoricModal(!historicModal);
  };

  function handleOutModal() {
    setOut(!out);
  }

  function handleCloseOutModal() {
    setOut(false);
  };

  useEffect(() => {
    loadStorageUser();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [menu]);

  const storageUser = localStorage.getItem('@Auth:user');
  const user = JSON.parse(storageUser);

  return {
    handleHistoricModal,
    historicModal,
    userType,
    dash,
    records,
    requests,
    products,
    myaccount,
    team,
    user,
    handleOutModal,
    handleCloseOutModal,
    out,
    createCalled,
    newrequest,
    menu,
    toggleMenu,
  };
}
