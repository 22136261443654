/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import slideOne from '../../assets/images/slide-one.png';
import slideTwo from '../../assets/images/slide-two.png';
import slideThree from '../../assets/images/slide-three.png';
import slideFour from '../../assets/images/slide-four.png';
import slideFive from '../../assets/images/slide-five.png';

import { api } from '../../services/apiClient';

// eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member
import allRequests from './allRequests';

export default function useDashboard() {
  const [records, setRecords] = useState([]);
  const [requests, setRequests] = useState([]);

  const [typeUser, setTypeUser] = useState({});

  const [values, setValues] = useState(0);
  const [origin, setOrigin] = useState('');

  const images = [
    slideOne,
    slideTwo,
    slideThree,
    slideFour,
    slideFive,
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  const [isAddRevenueModalVisible, setIsAddRevenueModalVisible] = useState(false);
  const [isLoadingAddRevenue, setIsLoadingAddRevenue] = useState(false);
  const [requestBeingAddedRevenue, setRequestBeingAddedRevenue] = useState(null);

  const [isAddCostModalVisible, setIsAddCostModalVisible] = useState(false);
  const [isLoadingAddCost, setIsLoadingAddCost] = useState(false);
  const [requestBeingAddedCost, setRequestBeingAddedCost] = useState(null);

  const [isRemoveItemVisible, setIsRemoveItemVisible] = useState(false);
  const [isLoadingRemoveItem, setIsLoadingRemoveItem] = useState(false);
  const [requestBeingRemoveItem, setRequestBeingRemoveItem] = useState(null);

  const {
    data,
    totalReceive,
    totalExpansive,
    nextMonth,
    backMonth,
    indexValue,
    selectedMonth,
    registersMonth,
    selectedYear,
    componentPDF,
    generatePDF,
    combinedArray,
    loadCosts,
    loadRevenues,
    allCostByMonth,
    allReceiveBYMonth,
  } = allRequests();

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((currentSlide + 1) % images.length);
    }, 15000);
    return () => clearInterval(interval);
  }, [currentSlide, images]);

  const removeDuplicates = new Map();
  [...requests].forEach(
    // eslint-disable-next-line consistent-return
    (object) => {
      if (!removeDuplicates.has(object.name)) {
        removeDuplicates.set(object.name, object);
      };
    },
  );

  const getValuesFromMap = [];

  removeDuplicates.forEach((value) => {
    // Verifica se algum item referenciado tem status diferente de false
    const referencedItems = requests.filter((item) => item.id === value.id);
    const anyReferencedItemsHaveStatusFalse = referencedItems.some((item) => item.status === true);

    // Adiciona o objeto ao array apenas se algum item referenciado tiver status diferente de false
    if (anyReferencedItemsHaveStatusFalse) {
      getValuesFromMap.push(value);
    }
  });

  const year = new Date().getFullYear();
  const month = new Date().getMonth() + 1;
  const dateMonth = month < 10 ? `0${month}` : month;

  function handleAddRevenueRequest(request) {
    setRequestBeingAddedRevenue(request);

    setIsAddRevenueModalVisible(true);
  };

  function handleAddCostRequest(request) {
    setRequestBeingAddedCost(request);

    setIsAddCostModalVisible(true);
  };

  function handleRemoveItemRequest(request) {
    setRequestBeingRemoveItem(request);

    setIsRemoveItemVisible(true);
  };

  function handleCloseAddRevenueModal() {
    setIsAddRevenueModalVisible(false);

    setOrigin('');
    setValues(0);
  };

  function handleCloseAddCostModal() {
    setIsAddCostModalVisible(false);

    setOrigin('');
    setValues(0);
  };

  function handleCloseRemoveItemModal() {
    setIsRemoveItemVisible(false);
  }

  function handleChangeValue(event) {
    setValues(event.target.value);
  };

  function handleChangeOrigin(event) {
    setOrigin(event.target.value);
  };

  async function loadRecords() {
    const response = await api.get(`/search/clients?date=${year}-${dateMonth}`);

    setRecords(response.data);
  };

  async function loadRequests() {
    const response = await api.get('/requests');

    setRequests(response.data);
  };

  async function loadUserData() {
    const storageUser = localStorage.getItem('@Auth:user');

    const user = JSON.parse(storageUser);

    if (!user.typeUser) {
      const response = await api.get('/collaborator/detail');

      setTypeUser(response.data);
    } else {
      const response = await api.get('/me');

      setTypeUser(response.data);
    }
  };

  async function handleCofirmAddRevenueRequest() {
    try {
      if (origin === '') {
        toast.error('Por favor digite a origem de sua receita!');

        return;
      };

      setIsLoadingAddRevenue(true);

      await api.post('/add-revenue', {
        value: values,
        description: origin,
      });

      setValues(0);
      setOrigin('');

      toast.success('Receita adicionada com sucesso!');

      loadRevenues();

      setIsLoadingAddRevenue(false);
      setIsAddRevenueModalVisible(false);
    } catch {
      toast.error('Erro ao adicionar receita!');
    }
  };

  async function handleCofirmAddCostRequest() {
    try {
      if (origin === '') {
        toast.error('Por favor digite a origem de sua despesa!');
        return;
      };
      setIsLoadingAddCost(true);

      await api.post('/add-cost', {
        value: values,
        description: origin,
      });

      setValues(0);
      setOrigin('');

      toast.success('Despesa adicionada com sucesso!');

      loadCosts();

      setIsLoadingAddCost(false);
      setIsAddCostModalVisible(false);
    } catch {
      toast.error('Erro ao adicionar despesa!');
    }
  };

  async function handleConfirmRemoveItemRequest() {
    try {
      if (requestBeingRemoveItem.type === 'Despesas') {
        setIsLoadingRemoveItem(true);

        await api.delete(`/cost/${requestBeingRemoveItem.id}`);
      };

      if (requestBeingRemoveItem.type === 'Receita') {
        setIsLoadingRemoveItem(true);

        await api.delete(`/revenue/${requestBeingRemoveItem.id}`);
      };

      loadCosts();
      loadRevenues();

      toast.success('Item deletado com sucesso!');

      setIsLoadingRemoveItem(false);
      setIsRemoveItemVisible(false);
    } catch {
      toast.error('Erro ao deletar item!');
    }
  };

  useEffect(() => {
    loadRecords();

    loadRequests();

    loadUserData();
  }, []);

  return {
    records,
    getValuesFromMap,
    data,
    typeUser,
    images,
    currentSlide,
    totalReceive,
    totalExpansive,
    nextMonth,
    backMonth,
    indexValue,
    selectedMonth,
    allReceiveBYMonth,
    allCostByMonth,
    registersMonth,
    selectedYear,
    componentPDF,
    generatePDF,
    handleAddRevenueRequest,
    handleCloseAddRevenueModal,
    isAddRevenueModalVisible,
    isLoadingAddRevenue,
    handleCofirmAddRevenueRequest,
    handleAddCostRequest,
    handleCloseAddCostModal,
    isAddCostModalVisible,
    isLoadingAddCost,
    handleCofirmAddCostRequest,
    values,
    handleChangeValue,
    origin,
    handleChangeOrigin,
    combinedArray,
    isRemoveItemVisible,
    isLoadingRemoveItem,
    handleConfirmRemoveItemRequest,
    handleRemoveItemRequest,
    handleCloseRemoveItemModal,
  };
};
