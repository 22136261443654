/* eslint-disable no-unused-vars */
import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useReactToPrint } from 'react-to-print';

import { api } from '../../services/apiClient';

export default function allRequests() {
  const componentPDF = useRef();

  const [january, setJanuary] = useState([]);
  const [february, setFebruary] = useState([]);
  const [march, setMarch] = useState([]);
  const [april, setApril] = useState([]);
  const [may, setMay] = useState([]);
  const [june, setJune] = useState([]);
  const [july, setJuly] = useState([]);
  const [august, setAugust] = useState([]);
  const [september, setSeptember] = useState([]);
  const [october, setOctober] = useState([]);
  const [november, setNovember] = useState([]);
  const [december, setDecember] = useState([]);

  const [januaryCosts, setJanuaryCosts] = useState([]);
  const [februaryCosts, setFebruaryCosts] = useState([]);
  const [marchCosts, setMarchCosts] = useState([]);
  const [aprilCosts, setAprilCosts] = useState([]);
  const [mayCosts, setMayCosts] = useState([]);
  const [juneCosts, setJuneCosts] = useState([]);
  const [julyCosts, setJulyCosts] = useState([]);
  const [augustCosts, setAugustCosts] = useState([]);
  const [septemberCosts, setSeptemberCosts] = useState([]);
  const [octoberCosts, setOctoberCosts] = useState([]);
  const [novemberCosts, setNovemberCosts] = useState([]);
  const [decemberCosts, setDecemberCosts] = useState([]);

  const [januaryRevenues, setJanuaryRevenues] = useState([]);
  const [februaryRevenues, setFebruaryRevenues] = useState([]);
  const [marchRevenues, setMarchRevenues] = useState([]);
  const [aprilRevenues, setAprilRevenues] = useState([]);
  const [mayRevenues, setMayRevenues] = useState([]);
  const [juneRevenues, setJuneRevenues] = useState([]);
  const [julyRevenues, setJulyRevenues] = useState([]);
  const [augustRevenues, setAugustRevenues] = useState([]);
  const [septemberRevenues, setSeptemberRevenues] = useState([]);
  const [octoberRevenues, setOctoberRevenues] = useState([]);
  const [novemberRevenues, setNovemberRevenues] = useState([]);
  const [decemberRevenues, setDecemberRevenues] = useState([]);

  const [receive, setReceive] = useState(0);
  const [cost, setCost] = useState(0);
  const [registersMonth, setRegistersMonth] = useState([]);

  const [indexValue, setIndexValue] = useState(new Date().getMonth());
  const [selectedMonth, setSelectedMonth] = useState({});
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const [revenues, setRevenues] = useState([]);
  const [costs, setCosts] = useState([]);

  let rev = [];

  rev = [...revenues];
  rev = revenues.map((item) => ({ ...item, type: 'Receita' }));

  let cos = [];
  cos = [...costs];
  cos = costs.map((item) => ({ ...item, type: 'Despesas' }));

  let combinedArray = rev.concat(cos);

  combinedArray.sort((a, b) => a.created_at.localeCompare(b.created_at)).reverse();

  const costJanuary = january.reduce((acc, curr) => acc + Number(curr.cost), 0);
  const receiveJanuary = january.reduce((acc, curr) => acc + Number(curr.value), 0);
  const costFebruary = february.reduce((acc, curr) => acc + Number(curr.cost), 0);
  const receiveFebruary = february.reduce((acc, curr) => acc + Number(curr.value), 0);
  const costMarch = march.reduce((acc, curr) => acc + Number(curr.cost), 0);
  const receiveMarch = march.reduce((acc, curr) => acc + Number(curr.value), 0);
  const costApril = april.reduce((acc, curr) => acc + Number(curr.cost), 0);
  const receiveApril = april.reduce((acc, curr) => acc + Number(curr.value), 0);
  const costMay = may.reduce((acc, curr) => acc + Number(curr.cost), 0);
  const receiveMay = may.reduce((acc, curr) => acc + Number(curr.value), 0);
  const costJune = june.reduce((acc, curr) => acc + Number(curr.cost), 0);
  const receiveJune = june.reduce((acc, curr) => acc + Number(curr.value), 0);
  const costJuly = july.reduce((acc, curr) => acc + Number(curr.cost), 0);
  const receiveJuly = july.reduce((acc, curr) => acc + Number(curr.value), 0);
  const costAugust = august.reduce((acc, curr) => acc + Number(curr.cost), 0);
  const receiveAugust = august.reduce((acc, curr) => acc + Number(curr.value), 0);
  const costSeptember = september.reduce((acc, curr) => acc + Number(curr.cost), 0);
  const receiveSeptember = september.reduce((acc, curr) => acc + Number(curr.value), 0);
  const costOctober = october.reduce((acc, curr) => acc + Number(curr.cost), 0);
  const receiveOctober = october.reduce((acc, curr) => acc + Number(curr.value), 0);
  const costNovember = november.reduce((acc, curr) => acc + Number(curr.cost), 0);
  const receiveNovember = november.reduce((acc, curr) => acc + Number(curr.value), 0);
  const costDecember = december.reduce((acc, curr) => acc + Number(curr.cost), 0);
  const receiveDecember = december.reduce((acc, curr) => acc + Number(curr.value), 0);

  const totalCost = costs.reduce((accumulator, { value = 0 }) => accumulator + value, 0);
  const totalRevenue = revenues.reduce((accumulator, { value = 0 }) => accumulator + value, 0);
  const AllCostJanuary = januaryCosts.reduce((acc, curr) => acc + Number(curr.value), 0);

  const AllCostFebruary = februaryCosts.reduce((acc, curr) => acc + Number(curr.value), 0);

  const AllCostMarch = marchCosts.reduce((acc, curr) => acc + Number(curr.value), 0);

  const AllCostApril = aprilCosts.reduce((acc, curr) => acc + Number(curr.value), 0);

  const AllCostMay = mayCosts.reduce((acc, curr) => acc + Number(curr.value), 0);

  const AllCostJune = juneCosts.reduce((acc, curr) => acc + Number(curr.value), 0);

  const AllCostJuly = julyCosts.reduce((acc, curr) => acc + Number(curr.value), 0);

  const AllCostAugust = augustCosts.reduce((acc, curr) => acc + Number(curr.value), 0);

  const AllCostSeptember = septemberCosts.reduce((acc, curr) => acc + Number(curr.value), 0);

  const AllCostOctober = octoberCosts.reduce((acc, curr) => acc + Number(curr.value), 0);

  const AllCostNovember = novemberCosts.reduce((acc, curr) => acc + Number(curr.value), 0);

  const AllCostDecember = decemberCosts.reduce((acc, curr) => acc + Number(curr.value), 0);

  const totalCosts = AllCostJanuary + AllCostFebruary + AllCostMarch + AllCostApril
  + AllCostMay + AllCostJune + AllCostJuly + AllCostAugust + AllCostSeptember
  + AllCostNovember + AllCostDecember;

  const AllRevenueJanuary = januaryRevenues.reduce((acc, curr) => acc + Number(curr.value), 0);

  const AllRevenueFebruary = februaryRevenues.reduce((acc, curr) => acc + Number(curr.value), 0);

  const AllRevenueMarch = marchRevenues.reduce((acc, curr) => acc + Number(curr.value), 0);

  const AllRevenueApril = aprilRevenues.reduce((acc, curr) => acc + Number(curr.value), 0);

  const AllRevenueMay = mayRevenues.reduce((acc, curr) => acc + Number(curr.value), 0);

  const AllRevenueJune = juneRevenues.reduce((acc, curr) => acc + Number(curr.value), 0);

  const AllRevenueJuly = julyRevenues.reduce((acc, curr) => acc + Number(curr.value), 0);

  const AllRevenueAugust = augustRevenues.reduce((acc, curr) => acc + Number(curr.value), 0);

  const AllRevenueSeptember = septemberRevenues.reduce((acc, curr) => acc + Number(curr.value), 0);

  const AllRevenueOctober = octoberRevenues.reduce((acc, curr) => acc + Number(curr.value), 0);

  const AllRevenueNovember = novemberRevenues.reduce((acc, curr) => acc + Number(curr.value), 0);

  const AllRevenueDecember = decemberRevenues.reduce((acc, curr) => acc + Number(curr.value), 0);

  const totalRevenues = AllRevenueJanuary + AllRevenueFebruary
  + AllRevenueMarch + AllRevenueApril + AllRevenueMay + AllRevenueJune
  + AllRevenueJuly + AllRevenueAugust + AllRevenueSeptember
  + AllRevenueOctober + AllRevenueNovember + AllRevenueDecember;

  const totalReceive = receiveJanuary + receiveFebruary + receiveMarch
  + receiveApril + receiveMay + receiveJune + receiveJuly + receiveAugust
  + receiveSeptember + receiveOctober + receiveNovember + receiveDecember + totalRevenues;

  const totalExpansive = costJanuary + costFebruary + costMarch + costApril + costMay + costJune
  + costJuly + costAugust + costSeptember + costOctober + costNovember + costDecember + totalCosts;

  const months = [
    { name: 'Janeiro', number: '01' },
    { name: 'Fevereiro', number: '02' },
    { name: 'Março', number: '03' },
    { name: 'Abril', number: '04' },
    { name: 'Maio', number: '05' },
    { name: 'Junho', number: '06' },
    { name: 'Julho', number: '07' },
    { name: 'Agosto', number: '08' },
    { name: 'Setembro', number: '09' },
    { name: 'Outubro', number: '10' },
    { name: 'Novembro', number: '11' },
    { name: 'Dezembro', number: '12' },
  ];

  const allCostByMonth = cost + totalCost;
  const allReceiveBYMonth = receive + totalRevenue;

  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: `relatório-mês-${indexValue + 1}-${selectedYear}`,
  });

  function changeMonth(month) {
    setSelectedMonth(months[month]);
  }

  function nextMonth() {
    const newIndex = indexValue === 11 ? 0 : indexValue + 1;
    setIndexValue(newIndex);
    changeMonth(newIndex);

    if (newIndex === 0) {
      setSelectedYear(selectedYear + 1);
    }
  }

  function backMonth() {
    const newIndex = indexValue === 0 ? 11 : indexValue - 1;
    setIndexValue(newIndex);
    changeMonth(newIndex);

    if (newIndex === 11) {
      setSelectedYear(selectedYear - 1);
    }
  }

  async function handleAllCosts() {
    const m = [
      '01', '02', '03', '04', '05', '06',
      '07', '08', '09', '10', '11', '12',
    ];

    const requests = m.map(async (month) => {
      const response = await api.get(`/search/costs?date=${selectedYear}-${month}`);
      return { month, data: response.data };
    });

    const allResponses = await Promise.all(requests);

    allResponses.forEach(({ month, data }) => {
      switch (month) {
        case '01':
          setJanuaryCosts(data);
          break;
        case '02':
          setFebruaryCosts(data);
          break;
        case '03':
          setMarchCosts(data);
          break;
        case '04':
          setAprilCosts(data);
          break;
        case '05':
          setMayCosts(data);
          break;
        case '06':
          setJuneCosts(data);
          break;
        case '07':
          setJulyCosts(data);
          break;
        case '08':
          setAugustCosts(data);
          break;
        case '09':
          setSeptemberCosts(data);
          break;
        case '10':
          setOctoberCosts(data);
          break;
        case '11':
          setNovemberCosts(data);
          break;
        case '12':
          setDecemberCosts(data);
          break;
        default:
          break;
      }
    });
  }

  async function handleAllRevenues() {
    const m = [
      '01', '02', '03', '04', '05', '06',
      '07', '08', '09', '10', '11', '12',
    ];

    const requests = m.map(async (month) => {
      const response = await api.get(`/search/revenues?date=${selectedYear}-${month}`);
      return { month, data: response.data };
    });

    const allResponses = await Promise.all(requests);

    allResponses.forEach(({ month, data }) => {
      switch (month) {
        case '01':
          setJanuaryRevenues(data);
          break;
        case '02':
          setFebruaryRevenues(data);
          break;
        case '03':
          setMarchRevenues(data);
          break;
        case '04':
          setAprilRevenues(data);
          break;
        case '05':
          setMayRevenues(data);
          break;
        case '06':
          setJuneRevenues(data);
          break;
        case '07':
          setJulyRevenues(data);
          break;
        case '08':
          setAugustRevenues(data);
          break;
        case '09':
          setSeptemberRevenues(data);
          break;
        case '10':
          setOctoberRevenues(data);
          break;
        case '11':
          setNovemberRevenues(data);
          break;
        case '12':
          setDecemberRevenues(data);
          break;
        default:
          break;
      }
    });
  }

  async function handleAllRequests() {
    const m = [
      '01', '02', '03', '04', '05', '06',
      '07', '08', '09', '10', '11', '12',
    ];

    const requests = m.map(async (month) => {
      const response = await api.get(`/search/requests?date=${selectedYear}-${month}`);
      return { month, data: response.data };
    });

    const allResponses = await Promise.all(requests);

    allResponses.forEach(({ month, data }) => {
      switch (month) {
        case '01':
          setJanuary(data);
          break;
        case '02':
          setFebruary(data);
          break;
        case '03':
          setMarch(data);
          break;
        case '04':
          setApril(data);
          break;
        case '05':
          setMay(data);
          break;
        case '06':
          setJune(data);
          break;
        case '07':
          setJuly(data);
          break;
        case '08':
          setAugust(data);
          break;
        case '09':
          setSeptember(data);
          break;
        case '10':
          setOctober(data);
          break;
        case '11':
          setNovember(data);
          break;
        case '12':
          setDecember(data);
          break;
        default:
          break;
      }
    });
  }

  const data = {
    january: {
      receive: receiveJanuary + AllRevenueJanuary,
      cost: costJanuary + AllCostJanuary,
    },
    february: {
      receive: receiveFebruary + AllRevenueFebruary,
      cost: costFebruary + AllCostFebruary,
    },
    march: {
      receive: receiveMarch + AllRevenueMarch,
      cost: costMarch + AllCostMarch,
    },
    april: {
      receive: receiveApril + AllRevenueApril,
      cost: costApril + AllCostApril,
    },
    may: {
      receive: receiveMay + AllRevenueMay,
      cost: costMay + AllCostMay,
    },
    june: {
      receive: receiveJune + AllRevenueJune,
      cost: costJune + AllCostJune,
    },
    july: {
      receive: receiveJuly + AllRevenueJuly,
      cost: costJuly + AllCostJuly,
    },
    august: {
      receive: receiveAugust + AllRevenueAugust,
      cost: costAugust + AllCostAugust,
    },
    september: {
      receive: receiveSeptember + AllRevenueSeptember,
      cost: costSeptember + AllCostSeptember,
    },
    october: {
      receive: receiveOctober + AllRevenueOctober,
      cost: costOctober + AllCostOctober,
    },
    november: {
      receive: receiveNovember + AllRevenueNovember,
      cost: costNovember + AllCostNovember,
    },
    december: {
      receive: receiveDecember + AllRevenueDecember,
      cost: costDecember + AllCostDecember,
    },
  };

  useEffect(() => {
    const currentDate = new Date();
    const currentMonthData = months[currentDate.getMonth()];
    setSelectedMonth(currentMonthData);
  }, []);

  async function loadData() {
    if (!selectedMonth.number) {
      return;
    }

    const response = await api.get(`/search/requests?date=${selectedYear}-${selectedMonth.number}`);

    const responseRegisters = await api.get(`/search/clients?date=${selectedYear}-${selectedMonth.number}`);

    const costMonth = response.data.reduce((acc, curr) => acc + Number(curr.cost), 0);
    const receiveMonth = response.data.reduce((acc, curr) => acc + Number(curr.value), 0);

    setCost(costMonth);
    setReceive(receiveMonth);
    setRegistersMonth(responseRegisters.data);
  }

  async function loadRevenues() {
    const response = await api.get(`/search/revenues?date=${selectedYear}-${selectedMonth.number}`);

    setRevenues(response.data);
  };

  async function loadCosts() {
    const response = await api.get(`/search/costs?date=${selectedYear}-${selectedMonth.number}`);

    setCosts(response.data);
  };

  useEffect(() => {
    handleAllCosts();

    handleAllRevenues();

    handleAllRequests();
  }, [selectedYear]);

  useEffect(() => {
    loadData();
    loadRevenues();
    loadCosts();
  }, [selectedMonth]);

  return {
    handleAllRequests,
    data,
    totalReceive,
    totalExpansive,
    nextMonth,
    backMonth,
    indexValue,
    selectedMonth,
    receive,
    cost,
    registersMonth,
    selectedYear,
    generatePDF,
    componentPDF,
    combinedArray,
    loadCosts,
    loadRevenues,
    allCostByMonth,
    allReceiveBYMonth,
  };
};
