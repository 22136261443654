import PropTypes from 'prop-types';

import sad from '../../../../assets/images/sad.svg';

import {
  Container,
} from './styles';

import Button from '../../../Button';

export default function ErrorStatus({ onTryAgain }) {
  return (
    <Container>
      <img
        src={sad}
        alt="Triste"
      />

      <div className="details">
        <strong>Ocorreu um erro ao obter os seus dados.</strong>

        <Button type="button" onClick={onTryAgain}>
          Tentar novamente
        </Button>
      </div>
    </Container>
  );
};

ErrorStatus.propTypes = {
  onTryAgain: PropTypes.func.isRequired,
};
