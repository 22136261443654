import {
  Container,
} from './styles';

import useNewCollaborator from './useNewCollaborator';

import Header from '../../components/Header';
import CollaboratorForm from '../../components/CollaboratorForm';

export default function NewCollaborator() {
  const { handleSubmit } = useNewCollaborator();

  return (
    <Container>
      <Header />

      <div className="main">
        <CollaboratorForm onSubmit={handleSubmit} />
      </div>
    </Container>
  );
};
