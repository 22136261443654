import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import {
  Container,
} from './styles';

import defaultTheme from '../../assets/styles/themes/default';
import GlobalStyles from '../../assets/styles/global';

import { AuthProvider } from '../../contexts/AuthProvider';

import Routes from '../../routes';
import Support from '../Support';

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <ThemeProvider theme={defaultTheme}>
          <GlobalStyles />
          <ToastContainer
            autoClose={5000}
          />

          <Container>
            <Routes />

            <Support />
          </Container>
        </ThemeProvider>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
