import PropTypes from 'prop-types';

import {
  Container,
} from './styles';

import emptyBox from '../../../../assets/images/empty-box.svg';

export default function EmptyList({ buttonLabel, children }) {
  return (
    <Container>
      <img
        src={emptyBox}
        alt="Empty box"
      />

      {children ? (
        <>{children}</>
      ) : (
        <>
          <p>
            Você ainda não tem nenhum item cadastrado!
            Clique no botão
            {' '}
            <strong>
              ”
              {buttonLabel}
              ”
            </strong>
            {' '}
            à cima
            para cadastrar o seu primeiro!
          </p>
        </>
      )}
    </Container>
  );
};

EmptyList.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  children: PropTypes.node,
};

EmptyList.defaultProps = {
  children: undefined,
};
