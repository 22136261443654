import logo from '../../assets/images/logo.png';

import {
  Container,
} from './styles';

export default function Info() {
  return (
    <Container>
      <section>
        <img
          src={logo}
          alt="Logo SCP - Manager"
          width={401}
        />
      </section>

      <section>
        <p>
          Coloque
          {' '}
          <span>tudo sobre o seu controle</span>
          {' '}
          de forma fácil e rápida com a gente!
        </p>
      </section>
    </Container>
  );
};
