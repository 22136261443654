import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10%;

  img {
    margin-bottom: 16px;
  }

  p {
    margin-top: 8px;
    text-align: center;
    color: ${({ theme }) => theme.colors.gray[200]};
    strong {
      color: ${({ theme }) => theme.colors.main.alert};
    }
  }
`;
