import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { api } from '../../services/apiClient';

import toast from '../../utils/toast';

export default function useTeam() {
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState('');

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [collaboratorBeingDeleted, setCollaboratorBeingDeleted] = useState(null);

  function handleChangeSearchTerm(event) {
    setSearchTerm(event.target.value);
  };

  function handleDeleteCollaborator(collaborator) {
    setCollaboratorBeingDeleted(collaborator);

    setIsDeleteModalVisible(true);
  };

  function handleCloseDeleteModal() {
    setIsDeleteModalVisible(false);
  };

  // eslint-disable-next-line consistent-return
  async function handleConfirmDeleteCollaborator() {
    try {
      setIsLoadingDelete(true);

      await api.delete(`/adm/delete-collaborator/${collaboratorBeingDeleted.id}`);

      navigate(0);

      handleCloseDeleteModal();

      toast({
        type: 'success',
        text: 'Colaborador deletado com sucesso!',
      });
    } catch {
      toast({
        type: 'danger',
        text: 'Ocorreu um erro ao deletar o colaborador!',
      });

      return null;
    } finally {
      setIsLoadingDelete(false);
    }
  };

  return {
    searchTerm,
    handleChangeSearchTerm,
    isDeleteModalVisible,
    collaboratorBeingDeleted,
    handleDeleteCollaborator,
    handleCloseDeleteModal,
    handleConfirmDeleteCollaborator,
    isLoadingDelete,
  };
};
