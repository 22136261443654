/* eslint-disable no-mixed-operators */
import { useImperativeHandle, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import isTypeValid from '../../utils/isTypeValid';

import useErrors from '../../hooks/useErrors';

// eslint-disable-next-line default-param-last
export default function useMyAccountForm(edit, onSubmit, ref) {
  const navigate = useNavigate();
  const { id } = useParams();

  const [type, setType] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);

  const storageUser = localStorage.getItem('@Auth:user');

  const user = JSON.parse(storageUser);

  if (!user.user?.name) {
    user.name = `${name}`;
  } else {
    user.user.name = `${name}`;
  }

  localStorage.setItem('@Auth:user', JSON.stringify(user));

  const {
    setError,
    removeError,
    getErrorMessageByFieldName,
    errors,
  } = useErrors();

  const passVerification = password.length >= 6;

  const isFormValid = edit ? (true && errors.length === 0 || password === '') : (type && email && passVerification && name && errors.length === 0);

  useImperativeHandle(ref, () => ({
    setFieldsValues: (admMaster) => {
      setType(isTypeValid((admMaster.user?.type || admMaster?.type) ?? ''));
      setName((admMaster.user?.name || admMaster?.name) ?? '');
      setEmail((admMaster.user?.email || admMaster?.email) ?? '');
      setPassword('');
    },

    resetFields: () => {
      setType('');
      setName('');
      setEmail('');
    },
  }), []);

  function handleType(event) {
    setType(isTypeValid(event.target.value));

    if (!event.target.value) {
      setError({ field: 'type', message: 'CPF é obrigatório' });
    } else {
      removeError('type');
    };
  };

  function handleName(event) {
    setName(event.target.value);

    if (!event.target.value) {
      setError({ field: 'name', message: 'Nome é obrigatório' });
    } else {
      removeError('name');
    };
  };

  function handlePassword(event) {
    setPassword(event.target.value);

    if (event.target.value.length < 6) {
      setError({ field: 'password', message: 'Sua senha precisa ter 6 caractéres ou mais!' });
    } else {
      removeError('password');
    };
  };

  function handleEmail(event) {
    setEmail(event.target.value);
  };

  function handleBack() {
    navigate(-1, { replace: true });
  };

  async function handleSubmit(event) {
    event.preventDefault();

    setIsSubmitting(true);

    await onSubmit({
      id,
      type,
      name,
      email,
      password,
    });

    setIsSubmitting(false);
  };

  return {
    type,
    handleType,
    name,
    handleName,
    email,
    handleEmail,
    password,
    handlePassword,
    handleSubmit,
    isSubmitting,
    getErrorMessageByFieldName,
    isFormValid,
    handleBack,
  };
};
