import { useRef } from 'react';
import { useAuth } from '../../contexts/AuthProvider/useAuth';

export default function useNewProduct() {
  const auth = useAuth();

  const productFormRef = useRef(null);

  async function handleSubmit(product) {
    try {
      await auth.createProduct({
        name: product.name,
        value: product.value,
        cost: product.cost,
        quantity: product.quantity,
      });

      productFormRef.current.resetFields();
    } catch (error) {
      console.log(error);
    }
  };

  return {
    handleSubmit,
    productFormRef,
  };
};
