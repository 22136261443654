import styled from 'styled-components';

export const Container = styled.div`
  table{
    width: 100%;
    margin-bottom : .5em;
    border-collapse: collapse;
    table-layout: fixed;
    text-align: center;

    thead {
      color: ${({ theme }) => theme.colors.main.light};
      background: ${({ theme }) => theme.colors.main.alert};
    }

    td, th {
      padding: .7em;
      margin: 0;
      text-align: center;
    }

    tbody {
      tr {
        td {
          font-size: 14px;

          #payament-status {
            cursor: pointer;
          }

          a {
            margin-left: 6px;

            cursor: pointer;
          }

          span {
            margin-left: 6px;

            cursor: pointer;
          }

          img {
            transition: 0.2s ease-in;

            &:hover {
              scale: 1.09;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1240px) {
    table {
      thead {
        font-size: 12px;
      }

      tbody {
        tr {
          td {
            font-size: 12px;

            background-color: #fff;
          }
        }
      }
    }
  }
`;
