import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  .main {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  margin-top: 10%;

  max-width: 1216px;
  width: 100%;

  img {
    display: flex;
    align-items: end;
    justify-content: end;

    width: 47%;
    margin-top: -44px;
    margin-left: 64px;
  }

  .wrapper {
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;

    /* margin-left: -64px; */

    max-width: 576px;
    width: 100%;

    h1 {
      margin-bottom: 16px;

      font-size: 48px;
      font-weight: 400;

      color: ${({ theme }) => theme.colors.main.greenBlack};
    }

    h3 {
      margin-bottom: 16px;

      font-size: 26px;
      font-weight: 400;
    }

    #text {
      margin-bottom: 16px;

      font-size: 24px;
      font-weight: 200;

      strong {
        color: #000;
      }
    }

    #button {
      width: 100%;

      button {
        padding: 16px;
        margin-bottom: 16px;

        width: 85%;
        height: 72px;

        border-radius: 12px;
        border: 0;

        font-size: 16px;

        color: ${({ theme }) => theme.colors.main.light};
        background: ${({ theme }) => theme.colors.main.greenBlack};
      }
    }

    p {
      margin-bottom: 16px;

      font-size: 14px;
    }

    #strong-red {
      font-size: 14px;
      font-weight: 100;

      text-decoration: none;

      color: ${({ theme }) => theme.colors.danger.light};
    }
  }

  @media screen and (max-width: 1240px) {
    padding: 16px;
    margin-top: 15%;

    flex-direction: column;

    .wrapper {
      h1 {
        font-size: 32px;
      }

      h3 {
        font-size: 20px;
      }

      #text {
        font-size: 20px;
      }

      #button {
        button {
          width: 100%;
          height: auto;

          font-size: 14px;
        }
      }
    }
  }


  @media screen and (min-width: 2560px) {
    img {
      display: flex;
      align-items: end;
      justify-content: end;

      width: 54.88%;
      margin-top: -44px;
      margin-left: 64px;
    }
  }

  @media screen and (max-width: 1080px) {
    img {
      display: flex;
      align-items: end;
      justify-content: end;

      width: 47%;
      margin-top: -44px;
      margin-left: 64px;
    }
  }
`;
