import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 16px;
  padding: 16px;

  border-radius: 12px;

  background: ${({ theme }) => theme.colors.main.light};

  h1 {
    font-size: 16px;

    color: ${({ theme }) => theme.colors.main.alert};
  }
`;
