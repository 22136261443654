import { useState, useEffect } from 'react';

function useScreenResolutionRealTime() {
  const [screenResolution, setScreenResolution] = useState({ width: 0, height: 0 });

  useEffect(() => {
    function updateScreenResolution() {
      const { width, height } = window.screen;
      setScreenResolution({ width, height });
    }

    // Chama a função para atualizar a resolução da tela quando o componente montar
    updateScreenResolution();

    // Adiciona um event listener para atualizar a resolução da tela em tempo real
    window.addEventListener('resize', updateScreenResolution);

    // Remove o event listener quando o componente desmontar para evitar vazamentos de memória
    return () => {
      window.removeEventListener('resize', updateScreenResolution);
    };
  }, []);

  return {
    screenResolution,
  };
}

export default useScreenResolutionRealTime;
