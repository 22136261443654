import { useRef, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { api } from '../../services/apiClient';

import { useAuth } from '../../contexts/AuthProvider/useAuth';
import useSafeAsyncAction from '../../hooks/useSafeAsyncAction';

export default function useEditRecord() {
  const { id } = useParams();
  const auth = useAuth();
  const navigate = useNavigate();

  const [isLoading, setIsloading] = useState(true);

  const clientFormRef = useRef(null);

  const safeAsyncAction = useSafeAsyncAction();

  useEffect(() => {
    async function loadClient() {
      try {
        const response = await api.get(`client/${id}`);

        safeAsyncAction(() => {
          clientFormRef.current.setFieldsValues(response.data);

          setIsloading(false);
        });
      } catch {
        safeAsyncAction(() => {
          navigate('/cadastros', { replace: true });
        });
      }
    };

    loadClient();
  }, [id, navigate, safeAsyncAction]);

  async function handleSubmit(client) {
    try {
      await auth.updateClient({
        id: client.id,
        type: client.type,
        name: client.name,
        phone: client.phone,
        email: client.email,
        address: client.address,
        more: client.more,
      });

      navigate('/cadastros', { replace: true });
    } catch (error) {
      console.log(error);
    }
  };

  return {
    handleSubmit,
    isLoading,
    clientFormRef,
  };
};
