import { useRef } from 'react';

import { useAuth } from '../../contexts/AuthProvider/useAuth';

export default function useSignInCollaborator() {
  const auth = useAuth();

  const signInCollaboratorRef = useRef(null);

  // eslint-disable-next-line consistent-return
  async function handleSubmit(collaborator) {
    try {
      await auth.signInCollaborator({
        email: collaborator.email,
        password: collaborator.password,
        navigation: collaborator.navigation,
      });
    } catch {
      return null;
    }
  };

  return {
    handleSubmit,
    signInCollaboratorRef,
  };
};
