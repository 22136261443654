/* eslint-disable consistent-return */
import PropTypes from 'prop-types';
import { useEffect, useState, createContext } from 'react';

import { toast } from 'react-toastify';

import {
  Authorization,
  LoginRequest,
  RegisterRequest,
  setUserLocalStorage,
  ForgotPassword,
  ResetPassword,
  ConfirmAccount,
  CreateProduct,
  CreateClient,
  CreateCollaborator,
  UpdateClient,
  LoginCollaborator,
  CreateRequest,
  UpdateRequest,
} from './util';

export const AuthContext = createContext({});

export function AuthProvider({ children }) {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const [loadingAuth, setLoadingAuth] = useState(false);

  const tokenAccess = localStorage.getItem('@Auth:token');

  useEffect(() => {
    function loadStorage() {
      const storageUser = localStorage.getItem('@Auth:user');
      const storageToken = localStorage.getItem('@Auth:token');

      if (storageUser && storageToken) {
        setUser(JSON.parse(storageUser));
      }

      setLoading(false);
    }

    loadStorage();
  }, []);

  async function signIn({ email, password }) {
    try {
      setLoadingAuth(true);

      const response = await LoginRequest(email, password);

      if (response?.user?.status === false) {
        toast.error('Confirme seu email antes!');
        return null;
      };

      if (!response?.user?.id) {
        toast.error('Este usuário não existe. Cadastre-se ou verifique seu acesso com suporte!');
        return null;
      };

      const { token } = response;

      setUser(response);
      Authorization(token);
      setUserLocalStorage({
        token: response?.user?.token,
        user: response,
      });

      toast.success('Bem-vindo!');

      setLoadingAuth(false);
    } catch (error) {
      toast.error('Ops! Parece que houve um erro. Verifique se o seu e-mail e senha estão corretos e tente novamente!');

      throw error;
    };
  };

  async function signInCollaborator({ email, password, navigation }) {
    try {
      setLoadingAuth(true);

      const response = await LoginCollaborator(email, password, navigation);

      const { token } = response;

      setUser(response);
      Authorization(token);
      setUserLocalStorage({
        token: response?.token,
        user: response,
        email,
      });

      toast.success('Bem-vindo');

      setLoadingAuth(false);
    } catch {
      toast.error('Erro ao fazer login!');
      return null;
    }
  };

  async function signUp({
    name, type, email, password,
  }) {
    try {
      setLoadingAuth(true);

      await RegisterRequest(name, type, email, password);

      setLoadingAuth(false);
    } catch (error) {
      toast.error('Erro ao cadastrar conta!');

      throw error;
    }
  };

  async function signOut() {
    localStorage.removeItem('@Auth:user');
    localStorage.removeItem('@Auth:email');
    localStorage.removeItem('@Auth:token');

    window.location.reload();
  }

  async function sendEmail({ email }) {
    setLoadingAuth(true);

    const { token } = await ForgotPassword(email);

    toast.success('Email enviado!');

    setLoadingAuth(false);

    return {
      token,
    };
  };

  async function resetPassword({
    email, token, password, securityToken,
  }) {
    try {
      setLoadingAuth(true);

      await ResetPassword({
        token,
        email,
        password,
        securityToken,
      });

      toast.success('Senha alterada com sucesso!');

      setLoadingAuth(false);
    } catch (error) {
      toast.error('O token que você digitou está incorreto!');

      throw error;
    }
  };

  async function confirmAccount({ token }) {
    setLoadingAuth(true);

    await ConfirmAccount(token);

    toast.success('Conta confirmada com sucesso!');

    setLoadingAuth(false);
  };

  async function createProduct({
    name,
    value,
    cost,
    quantity,
  }) {
    setLoading(true);

    await CreateProduct({
      name,
      value,
      cost,
      quantity,
    });

    toast.success('Produto criado com sucesso!');

    setLoading(false);
  };

  async function createClient({
    type,
    name,
    phone,
    email,
    address,
    more,
  }) {
    setLoading(true);

    await CreateClient({
      type,
      name,
      phone,
      email,
      address,
      more,
    });

    toast.success('Cliente criado com sucesso!');

    setLoading(false);
  };

  async function updateClient({
    id,
    type,
    name,
    phone,
    email,
    address,
    more,
  }) {
    setLoading(true);

    await UpdateClient({
      id,
      type,
      name,
      phone,
      email,
      address,
      more,
    });

    toast.success('Cliente editado com sucesso!');

    setLoading(false);
  };

  async function createCollaborator({
    type,
    name,
    office,
    phone,
    email,
    address,
    password,
  }) {
    setLoading(true);

    await CreateCollaborator({
      type,
      name,
      phone,
      office,
      email,
      address,
      password,
    });

    toast.success('Colaborador criado com sucesso!');

    setLoading(false);
  };

  async function createRequest({
    calledId,
    clientId,
    value,
    cost,
    more,
  }) {
    setLoading(true);

    await CreateRequest({
      calledId,
      clientId,
      value,
      cost,
      more,
    });

    toast.success('Pedido criado com sucesso!');

    setLoading(false);
  }

  async function updateRequest({
    id,
    value,
    cost,
    more,
  }) {
    setLoading(true);

    await UpdateRequest({
      id,
      value,
      cost,
      more,
    });

    toast.success('Pedido editado com sucesso!');

    setLoading(false);
  }

  return (
    <AuthContext.Provider
      value={{
        ...user,
        signIn,
        signInCollaborator,
        signUp,
        signOut,
        sendEmail,
        resetPassword,
        confirmAccount,
        createProduct,
        createClient,
        updateClient,
        createCollaborator,
        createRequest,
        updateRequest,
        loading,
        loadingAuth,
        tokenAccess,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
