import { useEffect, useState } from 'react';

import { api } from '../../services/apiClient';

export default function useHistoricModal() {
  const [historic, setHistoric] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  const ITEMS_PER_PAGE = 9;

  historic.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

  const pages = Math.ceil(historic.length / ITEMS_PER_PAGE);
  const startIndex = currentPage * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentItems = historic.slice(startIndex, endIndex);

  function handlePage(event) {
    setCurrentPage(Number(event.target.value));
  };

  async function loadHistoric() {
    try {
      const response = await api.get('/historic');

      setHistoric(response.data);
    } catch {
      setHistoric([]);
    }
  };

  useEffect(() => {
    loadHistoric();
  }, []);

  return {
    currentItems,
    pages,
    handlePage,
    currentPage,
    setCurrentPage,
  };
};
