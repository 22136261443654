import styled, { css, keyframes } from 'styled-components';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

const scaleIn = keyframes`
  from { transform: scale(0); }
  to { transform: scale(1); }
`;

const scaleOut = keyframes`
  from { transform: scale(1); }
  to { transform: scale(0); }
`;

export const Overlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  backdrop-filter: blur(5px);

  background: rgba(0, 0, 0, 0.6);

  animation: ${fadeIn} 0.3s forwards;

  ${({ isLeaving }) => isLeaving && css`
    animation: ${fadeOut} 0.2 forwards;
  `}

  @media screen and (max-width: 767px) {
    padding: 40px;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;

  padding: 112px 64px;

  max-width: 650px;
  width: 100%;

  border-radius: 12px;

  background-color: #ffffff;

  animation: ${scaleIn} 0.3s;

  ${({ isLeaving }) => isLeaving && css`
    animation: ${scaleOut} 0.2s forwards;
  `}

  h1 {
    margin-bottom: 16px;

    color: ${({ theme }) => theme.colors.main.alertBold};

    span {
      color: ${({ theme }) => theme.colors.main.black}
    }
  }

  p {
    font-size: 20px;
    font-weight: bold;

    color: ${({ theme }) => theme.colors.main.alertBold}
  }
`;
