import { useRef } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { useAuth } from '../../contexts/AuthProvider/useAuth';

export function useResetPassword() {
  const auth = useAuth();

  const navigate = useNavigate();

  const forgotPasswordRef = useRef(null);

  const [searchParams] = useSearchParams();
  const { email } = Object.fromEntries([...searchParams]);

  async function handleSubmit(user) {
    try {
      await auth.resetPassword({
        email,
        token: user.token,
        password: user.password,
        securityToken: user.securityToken,
      });

      return navigate('/', { replace: true });
    } catch (error) {
      console.log(error);
    }
  };

  return {
    handleSubmit,
    forgotPasswordRef,
  };
};
