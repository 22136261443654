import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;

  padding: 27px 43px;
  margin-top: 16px;

  width: 100%;

  border-radius: 12px;

  background: ${({ theme }) => theme.colors.main.light};

  &:last-child {
    margin-bottom: 32px;
  }

  @media screen and (max-width: 767px) {
    padding: 16px;

    font-size: 10px;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;

  overflow-wrap: break-word;

  span {
    font-size: 20px;

    color: ${({ theme }) => theme.colors.main.alert};
  }

  section {
    img {
      margin-left: 8px;

      cursor: pointer;

      transition: 0.2s ease-in;

      &:hover {
        scale: 1.09;
      }
    }
  }

  #header {
    display: flex;
    justify-content: space-between;

    width: 100%;

    #name {
      font-size: 20px;
    }

    section {
      display: flex;
      flex-direction: column;
      align-items: end;
      justify-content: center;

      #tag {
        padding: 6px 14px;

        border-radius: 12px;

        font-size: 14px;

        color: ${({ theme }) => theme.colors.main.light};
        background-color: ${({ theme }) => theme.colors.main.alert};
      }

      p {
        display: flex;
        align-items: center;

        margin-top: 8px;

        cursor: pointer;

        font-size: 14px;

        transition: ease-in 0.2s;

        &:hover {
          scale: 1.1;
        }
      }
    }
  }

  .middle {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    #informations {
      margin-top: 8px;

      p {
        font-size: 14px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    span {
      font-size: 14px;
    }

    section {
      margin-top: 4px;

      img {
        margin-left: 10px;

        width: 20px;
      }
    }

    #name {
      display: inline-block;

      max-width: 124px;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      box-sizing: border-box;
    }

    #header {
      display: flex;
      align-items: start;

      #name {
        display: inline-block;

        max-width: 44%;

        font-size: 12px;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        box-sizing: border-box;
      }

      #tag {
        display: flex;
        align-items: center;
        justify-content: center;

        height: 17px;

        font-size: 10px;

        border-radius: 5px;
      }
    }

    .middle {
      #informations {
        display: inline-block;

        max-width: 50%;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        box-sizing: border-box;
      }
    }
  }
`;
