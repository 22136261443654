import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export default function useConfirmAccountForm(onSubmit) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { token } = Object.fromEntries([...searchParams]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const isFormValid = token;

  async function handleSubmit(event) {
    event.preventDefault();

    setIsSubmitting(true);

    await onSubmit({
      token,
    });

    setIsSubmitting(false);

    navigate('/', { replace: true });
  };

  return {
    handleSubmit,
    isSubmitting,
    isFormValid,
  };
};
