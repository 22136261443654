import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;


  margin-top: 16%;
  padding: 0 16% 0 16%;

  h1 {
    padding-right: 16px;
  }

  @media screen and (max-width: 767px) {
    padding: 0;
    margin-top: 70%;

    justify-content: center;
    align-items: center;

    h1 {
      padding: 0;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;

  margin-top: 24px;

  button {
    width: 100%;

    a {
      color: #ffffff;
    }
  }

  @media screen and (max-width: 767px) {
    button {
      background: ${({ theme }) => theme.colors.danger.dark};
    }
  }
`;
