/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { IoIosOptions } from 'react-icons/io';

import magnifyingGlass from '../../../../assets/images/icons/magnifying-glass.svg';
import ben from '../../../../assets/images/icons/ben.svg';
import editPen from '../../../../assets/images/icons/edit-pen.svg';
import iconCheck from '../../../../assets/images/icons/icon-check.svg';

import {
  Container,
} from './styles';

import handleDate from '../../../../utils/handleDate';
import Modal from '../../../../components/Modal';

export default function RequestTable({
  requests,
  openModalDelete,
  onFinishRequest,
  payamentStatus,
  handlePayamentStatus,
  handlePayment,
  openModalSettings,
  isSettingsModalVisible,
  handleCloseSettings,
  requestSettings,
}) {
  // eslint-disable-next-line consistent-return
  function requestNumber(number) {
    if (number < 10) {
      return `#000${number}`;
    }

    if (number >= 10 && number < 100) {
      return `#00${number}`;
    }

    if (number >= 100 < 1000) {
      return `#0${number}`;
    }

    if (number >= 1000 < 10000) {
      return `#0${number}`;
    };
  };

  return (
    <Container>
      <table>
        <thead>
          <tr>
            <th scope="col">Pedidos</th>
            <th scope="col">Realizado em</th>
            {window.innerWidth > 1240 && (
              <th scope="col">Status</th>
            )}
            <th scope="col">Valor</th>
            {window.innerWidth > 1240 ? (
              <>
                <th scope="col">Pagamento</th>
                <th scope="col" />
              </>
            ) : (
              <th scope="col">Opções</th>
            )}
          </tr>
        </thead>

        <tbody>
          {requests.map((item) => (
            <>
              <tr key={item.id}>
                <td>{requestNumber(item?.request_number)}</td>
                <td>{handleDate(item?.created_at)}</td>
                {window.innerWidth > 1240 && (
                  <td>
                    {item?.status ? 'Em produção' : 'Concluido'}
                  </td>
                )}
                <td>
                  {new Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(
                    item?.value,
                  )}
                </td>
                {window.innerWidth > 1240 ? (
                  <>
                    <td>
                      <input
                        id="payament-status"
                        type="checkbox"
                        checked={payamentStatus[item.id] || false}
                        onClick={() => handlePayment({ item, checked: !payamentStatus[item.id] })}
                        onChange={
                      (e) => handlePayamentStatus({
                        itemId: item.id,
                        item,
                        checked: e.target.checked,
                      })
                    }
                      />
                    </td>
                    <td>
                      {item?.status && (
                        <Link
                          to={`/pedido/${item?.id}?chamada=${item?.called_id}&cliente=${item?.client_id}`}
                        >
                          <img src={editPen} alt="Editar pedido" width={18} />
                        </Link>
                      )}

                      <Link to={`/observar-pedido/${item?.id}?chamada=${item?.called_id}&cliente=${item?.client_id}`}>
                        <img src={magnifyingGlass} alt="Observar pedido" width={18} />
                      </Link>

                      {item?.status && (
                        <span>
                          <img
                            onClick={() => onFinishRequest(item)}
                            src={iconCheck}
                            alt="Finalizar pedido"
                            width={18}
                          />
                        </span>
                      )}

                      {item?.status && (
                        <span>
                          <img
                            onClick={() => openModalDelete(item)}
                            src={ben}
                            alt="Deletar pedido"
                            width={18}
                          />
                        </span>
                      )}
                    </td>
                  </>
                ) : (
                  <td>
                    <IoIosOptions
                      size={18}
                      color="gray"
                      onClick={() => openModalSettings(item)}
                    />
                  </td>
                )}
              </tr>
            </>
          ))}
        </tbody>

      </table>

      { window.innerWidth < 1240 && (
        <Modal
          visible={isSettingsModalVisible}
          title="Opções"
          onCancel={handleCloseSettings}
          footer={false}
        >
          <div>
            {requestSettings?.status && (
            <Link
              to={`/pedido/${requestSettings?.id}?chamada=${requestSettings?.called_id}&cliente=${requestSettings?.client_id}`}
            >
              <img src={editPen} alt="Editar pedido" width={18} />
            </Link>
            )}

            <Link
              to={`/observar-pedido/${requestSettings?.id}?chamada=${requestSettings?.called_id}&cliente=${requestSettings?.client_id}`}
              style={{
                marginLeft: '8px',
              }}
            >
              <img src={magnifyingGlass} alt="Observar pedido" width={18} />
            </Link>

            {requestSettings?.status && (
              <span>
                <img
                  onClick={() => onFinishRequest(requestSettings)}
                  src={iconCheck}
                  alt="Finalizar pedido"
                  width={18}
                  style={{
                    marginLeft: '8px',
                  }}
                />
              </span>
            )}

            {requestSettings?.status && (
              <span>
                <img
                  onClick={() => openModalDelete(requestSettings)}
                  src={ben}
                  alt="Deletar pedido"
                  width={18}
                  style={{
                    marginLeft: '8px',
                  }}
                />
              </span>
            )}
          </div>
        </Modal>
      ) }

    </Container>
  );
}
RequestTable.propTypes = {
  requests: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    request_number: PropTypes.number.isRequired,
    created_at: PropTypes.string.isRequired,
    status: PropTypes.bool.isRequired,
  })).isRequired,

  openModalDelete: PropTypes.func.isRequired,
  onFinishRequest: PropTypes.func.isRequired,
  openModalSettings: PropTypes.func.isRequired,
  handlePayamentStatus: PropTypes.func.isRequired,
  handlePayment: PropTypes.func.isRequired,
};
