import {
  Container,
} from './styles';

import Header from '../../components/Header';
import UserData from './components/UserData';

export default function MyAccount() {
  return (
    <Container>
      <Header />

      <div className="main">
        <UserData />
      </div>

    </Container>
  );
};
