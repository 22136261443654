import styled from 'styled-components';

export const Container = styled.div`
  & + & {
    margin-top: 16px;
  }

  small {
    display: block;

    margin-top: 8px;

    font-size: 12px;

    color: ${({ theme }) => theme.colors.danger.main};
  }

  .form-item {
    position: relative;

    span {
      font-size: 24px;

      color: ${({ theme }) => theme.colors.main.alert};
    }

    .input-wrapper-code {
      display: flex;

      margin-left: -10px;

      input {
        width: 20%;

        margin-left: 10px;
        padding-left: -200px;

        font-size: 20px;
      }

      input[type=number]::-webkit-inner-spin-button,
      input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

    }

    .input-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;


      border-radius: 4px;
      background: #ffff;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);

      input {
        width: 100%;
        height: 40px;

        border: none;
      }

      img {
        margin-right: 16px;

        cursor: pointer;
      }
    }

    p {
      margin-top: 16px;
      margin-bottom: 16px;
    }

    .loader {
      position: absolute;

      top: 18px;
      right: 16px;
    }
  }

  @media screen and (max-width: 767px) {
    .form-item {
      span {
        font-size: 18px;
      }
    }
  }
`;
