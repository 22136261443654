import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import {
  Form,
} from './styles';

import useResetPasswordForm from './useResetPasswordForm';

import Input from '../Input';
import Button from '../Button';

const ResetPasswordForm = forwardRef(({ buttonLabel, onSubmit }, ref) => {
  const {
    password,
    handlePassword,
    confirmPassword,
    inputOne,
    handleInputOne,
    inputTwo,
    handleInputTwo,
    inputThree,
    handleInputThree,
    inputFour,
    handleInputFour,
    inputFive,
    handleInputFive,
    inputSix,
    handleInputSix,
    handleConfirmPassword,
    handleSubmit,
    isSubmitting,
    getErrorMessageByFieldName,
    // errors,
    isFormValid,
    handleToggle,
    type,
    icon,
    query,
  } = useResetPasswordForm(onSubmit, ref);

  return (
    <Form onSubmit={handleSubmit}>
      {query.get('pass') ? (
        <div />
      ) : (
        <div>
          <span>Código de segurança</span>

          <div className="input-wrapper-code">
            <Input
              type="number"
              value={inputOne}
              onChange={handleInputOne}
              maxLength={1}
              className="input1"
            />
            <Input
              type="number"
              value={inputTwo}
              onChange={handleInputTwo}
              maxLength={1}
              className="code"
            />
            <Input
              type="number"
              value={inputThree}
              onChange={handleInputThree}
              maxLength={1}
              className="code"
            />
            <Input
              type="number"
              value={inputFour}
              onChange={handleInputFour}
              maxLength={1}
              className="code"
            />
            <Input
              type="number"
              value={inputFive}
              onChange={handleInputFive}
              maxLength={1}
              className="code"
            />
            <Input
              type="number"
              value={inputSix}
              onChange={handleInputSix}
              maxLength={1}
              className="code"
            />
          </div>
        </div>
      )}

      <div className="inputs password">
        <span>Senha</span>

        <div className="input-wrapper">
          <div>
            <Input
              error={getErrorMessageByFieldName('password')}
              placeholder="Senha"
              type={type}
              value={password}
              onChange={handlePassword}
              disabled={isSubmitting}
            />

            <img
              onClick={handleToggle}
              src={icon}
              alt="Mostrar senha"
              width={20}
            />
          </div>

          <p id="error">{getErrorMessageByFieldName('password')}</p>
        </div>
      </div>

      <div className="inputs password">
        <span>Confirmar senha</span>

        <div className="input-wrapper">
          <div>
            <Input
              error={getErrorMessageByFieldName('confirm-password')}
              placeholder="Confirmar senha"
              type={type}
              value={confirmPassword}
              onChange={handleConfirmPassword}
              disabled={isSubmitting}
            />

            <img
              onClick={handleToggle}
              src={icon}
              alt="Mostrar senha"
              width={20}
            />
          </div>

          <p id="error">{getErrorMessageByFieldName('confirm-password')}</p>

        </div>
      </div>

      <div>
        <Button
          type="submit"
          disabled={!isFormValid}
          isLoading={isSubmitting}
        >
          {buttonLabel}
        </Button>
      </div>
    </Form>
  );
});

export default ResetPasswordForm;

ResetPasswordForm.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
